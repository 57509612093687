import {set, get, del} from 'idb-keyval';

export const clearStorage = async (key) => {
    await del(key);
};

export const setStorage = async (key, object) => {
    await set(key, object);
};

export const getStorage = async (key) => {
    return new Promise((resolve) => {
        get(key).then((response) => resolve(response));
    });
};
