import React from 'react';
import Button from "../Button/Button";
import classes from './GalleryNavButton.module.scss';
import Padded from "../../hoc/Padded/Padded";

const GalleryNavButton = ({type, action}) => {
    return (
        <div className={classes['nav-button']}>
            <Button action={action} type={'primary'}>
                <Padded top={'min(2rem, 32px)'} bottom={'min(2rem, 36px)'} left={'min(2.2rem, 40px)'}
                        right={'min(2.2rem, 36px)'}>
                    <img style={{width: '1.35rem', transform: `rotate(${type === 'prev' && '180deg'})`}}
                         src="/assets/simple-arrow.svg" alt=""/>
                </Padded>
            </Button>
        </div>
    );
};

export default GalleryNavButton;
