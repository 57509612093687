import React from 'react';
import {useSelector} from "react-redux";
import HotspotGame from "../../components/HotspotGame/HotspotGame";
import {isSizedScreen} from "../../utils/functions/screen";
import {LARGE_SCREENS, SCREENSIZES} from "../../utils/constants";
import SliderGame from "../../components/SliderGame/SliderGame";

const Game = () => {
    const screen = useSelector(store => store.screen.selectedScreen);

    if (isSizedScreen(screen, LARGE_SCREENS)) {
        return <HotspotGame/>
    }

    if (screen === SCREENSIZES.MEDIUM4) {
        return <SliderGame/>
    }
};

export default Game;
