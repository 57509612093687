import React from 'react';
import {DEFAULT_PADDING} from "../../utils/constants";

const Padded = (props) => {
    const defaultPad = DEFAULT_PADDING;
    const topPad = props.top ?? defaultPad;
    const bottomPad = props.bottom ?? defaultPad;
    const leftPad = props.left ?? defaultPad;
    const rightPad = props.right ?? defaultPad;
    const blockHeight = props.fullHeight ? '100%' : 'auto'

    return (
        <div style={{padding: [topPad, rightPad, bottomPad, leftPad].join(' '), height: blockHeight}}>
            {props.children}
        </div>
    );
};

export default Padded;
