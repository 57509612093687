import React from 'react';

const Valign = ({align, children}) => {
    return (
        <div style={{display: 'grid', height: '100%', alignContent: align}}>
            {children}
        </div>
    );
};

export default Valign;
