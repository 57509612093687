import React, {useEffect, useRef, useState} from 'react';
import classes from './Video.module.scss';

function formatTime(timeInSeconds) {
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

    return {
        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2),
    };
}

const Video = ({file, isActive}) => {
    const videoRef = useRef();
    const [isPlaying, updateIsPlaying] = useState(false);
    const [videoDuration, setVideoDuration] = useState(0);
    const [videoTimeElapsed, setVideoTimeElapsed] = useState(0);

    useEffect(() => {
        if (!isActive) {
            updateIsPlaying(false)
        }
    }, [isActive])

    if (!isActive && videoRef && videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
    }

    const toggleVideoPlay = () => {
        if (isActive && videoRef && videoRef.current) {
            if (!videoRef.current.paused && videoRef.current.currentTime > 0 && !videoRef.current.ended) {
                videoRef.current.pause();
                updateIsPlaying(false);
            } else {
                videoRef.current.play();
                updateIsPlaying(true);
            }
        }
    }

    const onSeekChange = (e) => {
        setVideoTimeElapsed(e.target.value);
        videoRef.current.currentTime = e.target.value;
    }

    return (
        <div>
            <div className={classes["video-border"]}>
                <div className={classes["video-container"]} id="video-container">

                    <video onLoadedMetadata={() => setVideoDuration(Math.round(videoRef.current.duration))}
                           onClick={toggleVideoPlay}
                           onTimeUpdate={() => setVideoTimeElapsed(Math.round(videoRef.current.currentTime))}
                           onEnded={() => updateIsPlaying(false)}
                           className={classes["video"]}
                           id="video"
                           preload="metadata"
                           ref={videoRef}
                           src={file.devicePath}/>

                    {!isPlaying && (
                        <div onClick={toggleVideoPlay} className={classes["playback-animation"]}
                             id="playback-animation">
                            <img src={'/assets/play.svg'} alt=""/>
                        </div>
                    )}


                    <div className={classes["video-controls"]} id="video-controls">

                        <button onClick={toggleVideoPlay} id="play">
                            <img src={isPlaying ? '/assets/pause.svg' : '/assets/play.svg'} alt=""/>
                        </button>

                        <div className={classes["video-progress"]}>
                            <progress className={classes['progress']} id="progress-bar" value={videoTimeElapsed} min="0"
                                      max={videoDuration}/>
                            <input className={classes["seek"]} id="seek" value={videoTimeElapsed} min="0"
                                   onChange={onSeekChange}
                                   max={videoDuration}
                                   type="range"
                                   step="1"/>
                        </div>

                        <div className={classes["time"]}>
                            <time
                                id="time-elapsed">{formatTime(videoTimeElapsed).minutes}:{formatTime(videoTimeElapsed).seconds}</time>
                            <span> / </span>
                            <time
                                id="duration">{formatTime(videoDuration).minutes}:{formatTime(videoDuration).seconds}</time>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Video;
