export const personSubMenus = [
    {
        personIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        to: '/gallery',
        iconPath: '/assets/icon-pencils.svg',
        title: 'galéria'
    },
    {
        personIds: [6, 7, 9],
        to: '/video',
        iconPath: '/assets/icon-camera.svg',
        title: 'videó'
    },
    {
        personIds: [7],
        to: '/game',
        iconPath: '/assets/icon-touch.svg',
        title: 'játék'
    },
]
