export const defaultContent = {
    "MFH_1951_49_3.mp4": {
        "title": "",
        "description": "",
        "personId": 6,
        "type": "video"
    },
    "MFH_1958_9_2.mp4": {
        "title": "",
        "description": "",
        "personId": 6,
        "type": "video"
    },
    "MFH_1956_23_3.mp4": {
        "title": "",
        "description": "",
        "personId": 7,
        "type": "video"
    },
    "MFH_1961_04_7.mp4": {
        "title": "",
        "description": "",
        "personId": 7,
        "type": "video"
    },
    "MFH_1986_50.mp4": {
        "title": "",
        "description": "",
        "personId": 9,
        "type": "video"
    },
    "01_spannraft_bankban_1884.jpg": {
        "title": "",
        "description": "Erkel Ferenc: Bánk bán – Spannraft Ágoston díszletterve a Tisza-parti jelenethez,1884",
        "personId": 1,
        "type": "image"
    },
    "02_Maleczkyne_Bank_ban.jpg": {
        "title": "",
        "description": "Erkel Ferenc Bánk bán – Maleczkyné Ellinger Jozefa, az első operaházi Melinda, 1884",
        "personId": 1,
        "type": "image"
    },
    "03_spannraft_aprofeta_1885.jpg": {
        "title": "",
        "description": "Giacomo Meyerbeer: A próféta – Spannraft Ágoston díszletterve az I. felvonáshoz, 1885",
        "personId": 1,
        "type": "image"
    },
    "04_Broulik_DSC_2921bs.jpg": {
        "title": "",
        "description": "Giacomo Meyerbeer: A próféta – František Broulik, az első operaházi Leydeni János, 1885",
        "personId": 1,
        "type": "image"
    },
    "05_spannraft-tannhauser_1885_002.jpg": {
        "title": "",
        "description": "Richard Wagner: Tannhäuser – Spannraft Ágoston díszletterve a Vénusz-barlang jelenethez, 1885",
        "personId": 1,
        "type": "image"
    },
    "06_spannraft_tannhauser_1885_001.jpg": {
        "title": "",
        "description": "Richard Wagner: Tannhäuser – Spannraft Ágoston díszletterve az I. felvonás 2. képéhez, 1885",
        "personId": 1,
        "type": "image"
    },
    "07_spannraft-tannhauser_1885_003.jpg": {
        "title": "",
        "description": "Richard Wagner: Tannhäuser – Spannraft Ágoston díszletterve a II. felvonáshoz, 1885",
        "personId": 1,
        "type": "image"
    },
    "08_tannh_jelmez002.jpg": {
        "title": "",
        "description": "Richard Wagner: Tannhäuser – A címszereplő jelmezterve, 1885",
        "personId": 1,
        "type": "image"
    },
    "09_tannh_jelmez003.jpg": {
        "title": "",
        "description": "Richard Wagner: Tannhäuser – Erzsébet jelmezterve, 1885",
        "personId": 1,
        "type": "image"
    },
    "10_tannh_jelmez004.jpg": {
        "title": "",
        "description": "Richard Wagner: Tannhäuser – Hermann őrgróf jelmezterve, 1885",
        "personId": 1,
        "type": "image"
    },
    "11_spannraft_aida_002.jpg": {
        "title": "",
        "description": "Giuseppe Verdi: Aida – Spannraft Ágoston díszletterve az I. felvonáshoz, 1885",
        "personId": 1,
        "type": "image"
    },
    "12_spannraft_aida_001.jpg": {
        "title": "",
        "description": "Giuseppe Verdi: Aida – Spannraft Ágoston díszletterve a Nílus-parti jelenethez, 1885",
        "personId": 1,
        "type": "image"
    },
    "13_kellek_aida_004.jpg": {
        "title": "",
        "description": "Giuseppe Verdi: Aida – Olasz minta alapján készült kellékterv, 1885",
        "personId": 1,
        "type": "image"
    },
    "14_aida04.jpg": {
        "title": "",
        "description": "Giuseppe Verdi: Aida – Amneris fejdíszének terve, 1885",
        "personId": 1,
        "type": "image"
    },
    "15_spannraft_rigoletto1885.jpg": {
        "title": "",
        "description": "Giuseppe Verdi: Rigoletto – Spannraft Ágoston díszletterve az 1. képhez, 1885",
        "personId": 1,
        "type": "image"
    },
    "16_spannraft_sackingenitrombitas_1886.jpg": {
        "title": "",
        "description": "Viktor Nessler: A säckingeni trombitás – Spannraft Ágoston díszletterve az Előjátékhoz, 1886",
        "personId": 1,
        "type": "image"
    },
    "17_A_sackingeni_trombitas_1886_Marie1_001.jpg": {
        "title": "",
        "description": "Viktor Nessler: A säckingeni trombitás – Csepreghy Ferencné Rákosi Ida jelmezterve Marie szerepéhez, 1886",
        "personId": 1,
        "type": "image"
    },
    "18_A_sackingeni_trombitas_1886_Damian_001.jpg": {
        "title": "",
        "description": "Viktor Nessler: A säckingeni trombitás – Csepreghy Ferencné Rákosi Ida jelmezterve Damian szerepéhez, 1886",
        "personId": 1,
        "type": "image"
    },
    "19_SpannraftAbencerage_002.jpg": {
        "title": "",
        "description": "Sárosi Ferenc: Abencerage – Spannraft Ágoston díszletterve, 1887",
        "personId": 1,
        "type": "image"
    },
    "20_Abencerage1887_004.jpg": {
        "title": "",
        "description": "Sárosi Ferenc: Abencerage – Vári Bernát jelmezterve, 1887",
        "personId": 1,
        "type": "image"
    },
    "21_spannraft_abolygohollandi_1888.jpg": {
        "title": "",
        "description": "Richard Wagner: A bolygó hollandi – Spannraft Ágoston díszletterve a III. felvonáshoz, 1888",
        "personId": 1,
        "type": "image"
    },
    "22_Bolygo_hollandi1888_Ahollandi-Bandiss_Oszkar_figurinje002.jpg": {
        "title": "",
        "description": "Richard Wagner: A bolygó hollandi – Bandiss Oszkár jelmezterve A hollandi szerepéhez, 1888",
        "personId": 1,
        "type": "image"
    },
    "23_spannraft_rajnakincse1889_oszk.JPG": {
        "title": "",
        "description": "Richard Wagner: A Rajna kincse – Spannraft Ágoston díszletterve a 3. képhez, 1889 – Forrás: OSZK Színháztörténeti Tár",
        "personId": 1,
        "type": "image"
    },
    "24_Rajnakincse_Sello_02_.jpg": {
        "title": "",
        "description": "Richard Wagner: A Rajna kincse – Molnár Árpád sellő jelmezterve, 1889",
        "personId": 1,
        "type": "image"
    },
    "25_Awalker_1889_Spannraftterv_001.jpg": {
        "title": "",
        "description": "Richard Wagner: A walkür – Spannraft Ágoston díszletterve az I. felvonáshoz, 1889",
        "personId": 1,
        "type": "image"
    },
    "26_Awalker_Wotan-jelmezterv-1887.jpg": {
        "title": "",
        "description": "Richard Wagner: A walkür – Bandiss Oszkár Wotan-figurinje, 1887",
        "personId": 1,
        "type": "image"
    },
    "27_Walker_Ortlinde.jpg": {
        "title": "",
        "description": "Richard Wagner: A walkür – Bandiss Oszkár Ortlinde-figurinje, 1887",
        "personId": 1,
        "type": "image"
    },
    "28_spannraft_toldi_1893.jpg": {
        "title": "",
        "description": "Mihalovich Ödön: Toldi – Spannraft Ágoston díszletterve az I. felvonáshoz, 1893",
        "personId": 1,
        "type": "image"
    },
    "29_Toldiszerelme_001.jpg": {
        "title": "",
        "description": "Mihalovich Ödön: Toldi – Dörre Tivadar metszete az I. felvonásról, 1893",
        "personId": 1,
        "type": "image"
    },
    "30_spannraft_andrechenier_1893_oszkKF239.jpg": {
        "title": "",
        "description": "Umberto Giordano: André Chénier – Spannraft Ágoston díszletterve a II. felvonáshoz, 1893 – Forrás: OSZK Színháztörténeti Tár",
        "personId": 1,
        "type": "image"
    },
    '01_BertsMimi_Trisztan_Brangene_01.jpg': {
        title: '',
        description: 'Richard Wagner: Trisztán és Izolda – Brangäne: Bert Mimi – jelmez: Kéméndy Jenő, 1903',
        personId: 2,
        type: 'image'
    },
    '02_Glatz_1902.jpg': {
        title: '',
        description: 'Goldmark Károly: Götz von Berlichingen – Jelenet a IV. felvonásból – díszlet-jelmez: Kéméndy Jenő, 1902',
        personId: 2,
        type: 'image'
    },
    '03_Glatz1_1902.jpg': {
        title: '',
        description: 'Goldmark Károly: Götz von Berlichingen – Az opera zárójelenete – díszlet-jelmez: Kéméndy Jenő, 1902',
        personId: 2,
        type: 'image'
    },
    '04_SzoyerI_Gabor_Csavargo_es_a_kiralylany_1903.jpg': {
        title: '',
        description: 'Poldini Ede: A csavargó és a királyleány –Szoyer Ilonka (A királyleány) és Gábor József (A herceg) – jelmez: Kéméndy Jenő, 1903',
        personId: 2,
        type: 'image'
    },
    '05_KrammerTerez_Tosca_1903.jpg': {
        title: '',
        description: 'Giacomo Puccini: Tosca – Tosca: Krammer Teréz – jelmez: Kéméndy Jenő, 1903',
        personId: 2,
        type: 'image'
    },
    '06_Gemma_DalnokiV_MarkusE_1904.jpg': {
        title: '',
        description: 'gróf Zichy Géza: Gemma – Dalnoki Viktor (Sturmfels Edgar gróf:) és Márkus Emília (Gemma) – jelmez: Kéméndy Jenő, 1904',
        personId: 2,
        type: 'image'
    },
    '07_AnthesGyorgy_Samson_1904.jpg': {
        title: '',
        description: 'Camille Saint-Saëns: Sámson és Delila – Sámson: Georg Anthes – jelmez: Kéméndy Jenő, 1904',
        personId: 2,
        type: 'image'
    },
    '08_Szamosi_Samson_1904.jpg': {
        title: '',
        description: 'Camille Saint-Saëns: Sámson és Delila – Delila: Szamosi Elza – jelmez: Kéméndy Jenő, 1904',
        personId: 2,
        type: 'image'
    },
    '09_Flattna_Nemo1905.jpg': {
        title: '',
        description: 'gróf Zichy Géza: Nemo – Hesseni Amália Sarolta: Flattné Gizella – jelmez: Kéméndy Jenő, 1905',
        personId: 2,
        type: 'image'
    },
    '10_Gabor_Jozsef_pillango.jpg': {
        title: '',
        description: 'Giacomo Puccini: Pillangókisasszony – Pinkerton: Gábor József – jelmez: Kéméndy Jenő, 1906',
        personId: 2,
        type: 'image'
    },
    '11_Pillango_1906_Varady_Margit_Szuzuki.jpg': {
        title: '',
        description: 'Giacomo Puccini: Pillangókisasszony – Váradi Margit (Suzuki) Kéméndy Jenőnek dedikált szerepfotója – jelmez: Kéméndy Jenő, 1906',
        personId: 2,
        type: 'image'
    },
    '12_kemendy_elektra1910_001.jpg': {
        title: '',
        description: 'Richard Strauss: Elektra – Az opera zárójelenete – díszlet-jelmez: Kéméndy Jenő, 1910',
        personId: 2,
        type: 'image'
    },
    '13_KrammerTerez_Elektra1910.jpg': {
        title: '',
        description: 'Richard Strauss: Elektra – Elektra: Krammer Teréz – jelmez: Kéméndy Jenő, 1910',
        personId: 2,
        type: 'image'
    },
    '14_Paolo_es_Francesca_1912_Szekelyhidy_ferenc_MedekAnna_003.jpg': {
        title: '',
        description: 'ifj. Ábrányi Emil: Paolo és Francesca – Székelyhidy Ferenc (Paolo) és Medek Anna (Francesca) – jelmez: Kéméndy Jenő, 1912',
        personId: 2,
        type: 'image'
    },
    '15_Paolo_es_Francesca_1912_MedekAnna_Szekelyhidy_Ferenc_003.jpg': {
        title: '',
        description: 'ifj. Ábrányi Emil: Paolo és Francesca – Az opera zárójelenete – jelmez: Kéméndy Jenő, 1912',
        personId: 2,
        type: 'image'
    },
    '16_Nyugat_lanya_1912_01.jpg': {
        title: '',
        description: 'Giacomo Puccini: A Nyugat lánya – Környei Béla (Ramerrez) és Szamosi Elza (Minnie) – jelmez: Kéméndy Jenő, 1912',
        personId: 2,
        type: 'image'
    },
    '17_Nyugat_lanya_1912_03.jpg': {
        title: '',
        description: 'Giacomo Puccini: A Nyugat lánya – Szamosi Elza (Minnie), Környei Béla (Ramerrez:) és Domenicio Viglione Borghese (Jack Rance) – jelmez: Kéméndy Jenő, 1912',
        personId: 2,
        type: 'image'
    },
    '18_Rodosto_Sandor_Erzsi_1912_001.jpg': {
        title: '',
        description: 'gróf Zichy Géza: Rodostó – Sándor Erzsi (Sieniawska Elisabetha hercegnő), Rózsa S. Lajos (II. Rákóczi Ferenc) Balta Károly (Longueval) – jelmez: Kéméndy Jenő, 1912',
        personId: 2,
        type: 'image'
    },
    '19_Kiraly_es_a_kiralylany_SandorErzsi_Szemere_Arpad_1912.jpg': {
        title: '',
        description: 'Engelbert Humperdinck: Királyfi és a királylány – Sándor Erzsi (A pásztorlány) és Szemere Árpád (A lantos) – jelmez: Kéméndy Jenő, 1912',
        personId: 2,
        type: 'image'
    },
    '20_Salome_1912_01.jpg': {
        title: '',
        description: 'Richard Strauss – Salome – Dömötör Ilona (Salome) és Gábor József (Heródes) – díszlet-jelmez: Kéméndy Jenő és Bánffy Miklós, 1912',
        personId: 2,
        type: 'image'
    },
    '21_Domotor_Ilona_Gabor_Jozsef_Boccaccio_1913.jpg': {
        title: '',
        description: 'Franz von Suppé: Boccaccio – Dömötör Ilona (Giovanni Boccaccio) és Gábor József (Pietro) – jelmez: Kéméndy Jenő, 1913',
        personId: 2,
        type: 'image'
    },
    '22_Galafor_es_Pierrette_1913.jpg': {
        title: '',
        description: 'Dohnányi Ernő: Pierrette fátyola – Pierrette: Elza Galafrés – jelmez: Kéméndy Jenő, 1913',
        personId: 2,
        type: 'image'
    },
    '23_Venczell_Balazs_saiba_kiralyneja_1916.jpg': {
        title: '',
        description: 'Goldmark Károly: Sába királynője – Főpap: Venczell Béla – jelmez: Kéméndy Jenő, 1916',
        personId: 2,
        type: 'image'
    },
    '24_Aholtszemek1921_001.jpg': {
        title: '',
        description: 'Eugen d’Albert: A holt szemek – Medek Anna (Myrtokle) és Szemere Árpád, (Arcesius) – díszlet-jelmez: Kéméndy Jenő, 1921',
        personId: 2,
        type: 'image'
    },
    '25_Walter_Rozsi_Thais1924_001.jpg': {
        title: '',
        description: 'Jules Massenet: Thaïs – Thaïs: Walter Rózsi – jelmez: Kéméndy Jenő, 1924',
        personId: 2,
        type: 'image'
    },
    '26_Carmen1929_1fv_002.jpg': {
        title: '',
        description: 'Georges Bizet: Carmen – Jelenet az I. felvonásból – díszlet-jelmez: Kéméndy Jenő, 1929',
        personId: 2,
        type: 'image'
    },
    '27_Carmen1929_2fv_003.jpg': {
        title: '',
        description: 'Georges Bizet: Carmen – Jelenet az II. felvonásból – díszlet-jelmez: Kéméndy Jenő, 1929',
        personId: 2,
        type: 'image'
    },
    '28_Carmen1929_3fv_004.jpg': {
        title: '',
        description: 'Georges Bizet: Carmen – Jelenet az III. felvonásból – díszlet-jelmez: Kéméndy Jenő, 1929',
        personId: 2,
        type: 'image'
    },
    '29_Carmen1929_4fv_006.jpg': {
        title: '',
        description: 'Georges Bizet: Carmen – Jelenet az IV. felvonásból – díszlet-jelmez: Kéméndy Jenő, 1929',
        personId: 2,
        type: 'image'
    },
    '30_Siegfried_Kemendy_J_sarkanya_001.jpg': {
        title: '',
        description: 'Emlékezés Kéméndy Jenőre az Operaház 1925-ös kiadványában',
        personId: 2,
        type: 'image'
    },
    '01.Varazs_MagyarIparmuveszet_1913__pages170-170_page-0001.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: A varázsfuvola – Bánffy Miklós figurinjei – Magyar Iparművészet, 1913',
        personId: 3,
        type: 'image'
    },
    '02.varazsfuvola_venczellbelajelmeze.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: A varázsfuvola – Sarastro: Venczell Béla – jelmez: Bánffy Miklós, 1912',
        personId: 3,
        type: 'image'
    },
    '03.Szoktetes_MagyarIparmuveszet_1913__pages169-169_page-0001.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Szöktetés a szerájból – Bánffy Miklós díszlettervei – Magyar Iparművészet, 1913',
        personId: 3,
        type: 'image'
    },
    '04.Szoktetes_Hevesi001.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Szöktetés a szerájból – Jelenet a II. felvonásból – díszlet-jelmez: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '05.Szoktetes_Hevesi002.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Szöktetés a szerájból – Jelenet a II. felvonásból – díszlet-jelmez: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '06.szoktetes001.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Szöktetés a szerájból – Csoportkép az 50. előadás után – jelmez: Bánffy Miklós, 1929',
        personId: 3,
        type: 'image'
    },
    '07.Oberon1_MagyarIparmuveszet_1914__pages13-13_page-0001.jpg': {
        title: '',
        description: 'Carl Maria von Weber: Oberon – Bánffy Miklós figurinjei – Magyar Iparművészet, 1914',
        personId: 3,
        type: 'image'
    },
    '08.Oberon2_MagyarIparmuveszet_1914__pages33-33_page-0001.jpg': {
        title: '',
        description: 'Carl Maria von Weber: Oberon – Bánffy Miklós díszletterve – Magyar Iparművészet, 1914',
        personId: 3,
        type: 'image'
    },
    '09.infansno_1918_01.jpg': {
        title: '',
        description: 'Radnai Miklós – Otto Zöbisch: Az infánsnő születésnapja – Vécsey Elvira (Az infánsnő) és Keresztes Mariska (Gróf Terra Nueva) – jelmez: Bánffy Miklós, 1918',
        personId: 3,
        type: 'image'
    },
    '10.infansno_1918_02.jpg': {
        title: '',
        description: 'Radnai Miklós – Otto Zöbisch: Az infánsnő születésnapja – Csoportkép – jelmez: Bánffy Miklós, 1918',
        personId: 3,
        type: 'image'
    },
    '11_kekszakallu_szinhazielet_cikk_01.JPG': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára - A Színházi Élet képes beszámolója az opera ősbemutatójáról – díszlet-jelmez: Bánffy Miklós, 1918',
        personId: 3,
        type: 'image'
    },
    '12_kekszakallu_szinhazielet_cikk_02.JPG': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára - A Színházi Élet képes beszámolója az opera ősbemutatójáról – díszlet-jelmez: Bánffy Miklós, 1918',
        personId: 3,
        type: 'image'
    },
    '13_kekszakallu_szinhazielet_cikk_03.JPG': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára - A Színházi Élet képes beszámolója az opera ősbemutatójáról – díszlet-jelmez: Bánffy Miklós, 1918',
        personId: 3,
        type: 'image'
    },
    '14.donjuan_1917_02.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Don Giovanni – Jelenet az I. felvonásból – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '15.donjuan_1917_01.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Don Giovanni – Az I. felvonás fináléja – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '16.Hoffmannmesei1931_07.jpg': {
        title: '',
        description: 'Jacques Offenbach: Hoffmann meséi – Jelenet az I. felvonásból – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '17.Hoffmannmesei1931_03.jpg': {
        title: '',
        description: 'Jacques Offenbach: Hoffmann meséi – Jelenet az II. felvonásból – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '18.Hoffmannmesei1931_01.jpg': {
        title: '',
        description: 'Jacques Offenbach: Hoffmann meséi – Jelenet az III. felvonásból – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '19..trubadur_003.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet az I. felvonás 2. képéből – díszlet: Bánffy Miklós, 1932',
        personId: 3,
        type: 'image'
    },
    '20.trubadur_008.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet az II. felvonás 1. képéből – díszlet: Bánffy Miklós, 1932',
        personId: 3,
        type: 'image'
    },
    '21.Trubadur_Hevesi_Banffy002.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet az II. felvonás 2. képéből – díszlet: Bánffy Miklós, 1932',
        personId: 3,
        type: 'image'
    },
    '22.trubadur_007.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet az III. felvonás 1. képéből – díszlet: Bánffy Miklós, 1932',
        personId: 3,
        type: 'image'
    },
    '23.Trubadur_1912.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet az IV. felvonás 1. képéből – díszlet: Bánffy Miklós, 1932',
        personId: 3,
        type: 'image'
    },
    '24trubadur_005.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet az IV. felvonás 2. képéből – díszlet: Bánffy Miklós, 1932',
        personId: 3,
        type: 'image'
    },
    '25.Aida_01.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Aida – Jelenet az I. felvonás 1. képéből – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '26.Aida_02.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Aida – Jelenet az I. felvonás 1. képéből – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '27.Aida_03.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Aida – Jelenet az I. felvonás 2. képéből – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '28.aida_04.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Aida – Jelenet az II. felvonás 1. képéből – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '29.aida_07.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Aida – Jelenet az II. felvonás 2. képéből – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '30.aida_03.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Aida – Jelenet az IV. felvonás 2. képéből – díszlet: Bánffy Miklós, 1931',
        personId: 3,
        type: 'image'
    },
    '01.Farsangilakodalom1931_005.jpg': {
        title: '',
        description: 'Poldini Ede: Farsangi lakodalom – Jelenet az I. felvonásból – díszlet-jelmez-rendezés: Márkus László, 1931',
        personId: 4,
        type: 'image'
    },
    '02_farsangilakodalom_markus_01.jpg': {
        title: '',
        description: 'Poldini Ede: Farsangi lakodalom – Jelenet az II. felvonásból – díszlet-jelmez-rendezés: Márkus László, 1931',
        personId: 4,
        type: 'image'
    },
    '03_farsangilakodalom_markus_02.jpg': {
        title: '',
        description: 'Poldini Ede: Farsangi lakodalom – Jelenet az III. felvonásból – díszlet-jelmez-rendezés: Márkus László, 1931',
        personId: 4,
        type: 'image'
    },
    '04_Farsangilakodalom_NmethyElla_SebeAnna004.jpg': {
        title: '',
        description: 'Poldini Ede: Farsangi lakodalom – Némethy Ella (Bükkyné), Sebeők Sára (A nemzetes asszony), Székelyhidy Ferenc (Kámán diák), Medek Anna (Zsuzsika) és Szemere Árpád (Péter, nemzetes úr) – díszlet-jelmez: Márkus László, 1924',
        personId: 4,
        type: 'image'
    },
    '05_Farsangi_bemutat.jpg': {
        title: '',
        description: 'Poldini Ede: Farsangi lakodalom – Márkus László és az ősbemutató szereplői, 1924',
        personId: 4,
        type: 'image'
    },
    '06_parsifal_total_01.jpg': {
        title: '',
        description: 'Richard Wagner: Parsifal – Jelenet az I. felvonás 1. képéből – díszlet-jelmez-rendezés: Márkus László, 1932',
        personId: 4,
        type: 'image'
    },
    '07_parsifal_total_02.jpg': {
        title: '',
        description: 'Richard Wagner: Parsifal – Jelenet az I. felvonás 2. képéből – díszlet-jelmez-rendezés: Márkus László, 1932',
        personId: 4,
        type: 'image'
    },
    '08_KA5678_4.jpg': {
        title: '',
        description: 'Richard Wagner: Parsifal – Jelenet az II. felvonás 2. képéből – díszlet-jelmez-rendezés: Márkus László, 1932',
        personId: 4,
        type: 'image'
    },
    '09_parsifal_total_03.jpg': {
        title: '',
        description: 'Richard Wagner: Parsifal – Jelenet az III. felvonás 1. képéből – díszlet-jelmez-rendezés: Márkus László, 1932',
        personId: 4,
        type: 'image'
    },
    '10_ZodszkyZolt_Parsifal_001.jpg': {
        title: '',
        description: 'Richard Wagner: Parsifal – Parsifal: Závodszky Zoltán – jelmez: Márkus László, 1927',
        personId: 4,
        type: 'image'
    },
    '11_TutsekPiroska_Parsifal-1939_Kundry1.jpg': {
        title: '',
        description: 'Richard Wagner: Parsifal – Kundry: Tutsek Piroska – jelmez: Márkus László, 1939',
        personId: 4,
        type: 'image'
    },
    '12_siegfried_jelenet.jpg': {
        title: '',
        description: 'Richard Wagner: Siegfried – Némethy Ella és Závodszky Zoltán – díszlet-jelmez-rendezés: Márkus László, 1926',
        personId: 4,
        type: 'image'
    },
    '13_istenekalkonya_pilinszky.jpg': {
        title: '',
        description: 'Richard Wagner: Az istenek alkonya – Siegfried: Pilinszky Zsigmond – jelmez: Márkus László, 1927',
        personId: 4,
        type: 'image'
    },
    '14_istenekalkonya_venczell.jpg': {
        title: '',
        description: 'Richard Wagner: Az istenek alkonya – Hagen: Venczell Béla – jelmez: Márkus László, 1928',
        personId: 4,
        type: 'image'
    },
    '15_Cosifantutte1930_003.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Così fan tutte – Jelenet – díszlet-jelmez-rendezés: Márkus László, 1930',
        personId: 4,
        type: 'image'
    },
    '16_cosi_1930_markus.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Così fan tutte – AZ I. felvonás fináléja – díszlet-jelmez-rendezés: Márkus László, 1942',
        personId: 4,
        type: 'image'
    },
    '17_cosi_sandorerzsi_bodo_1930.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Così fan tutte – Sándor Erzsi (Fiordiligi) és Bodó Erzsi (Dorabella) – jelmez: Márkus László, 1930',
        personId: 4,
        type: 'image'
    },
    '18_figarohazassaga_1937_01.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Figaro házassága – Jelenet az I. felvonásból – díszlet-jelmez: Márkus László, 1937',
        personId: 4,
        type: 'image'
    },
    '19_figarohazassaga_1937_02.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Figaro házassága – Az opera zárójelenete – díszlet-jelmez: Márkus László, 1937',
        personId: 4,
        type: 'image'
    },
    '20_figarohazassaga_tablo_1937.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Figaro házassága – Jelenetképek – díszlet-jelmez: Márkus László, 1937',
        personId: 4,
        type: 'image'
    },
    '21.boriszgodunov_1930_markus.jpg': {
        title: '',
        description: 'Mogyeszt Petrovics Muszorgszkij: Borisz Godunov – Koronázási jelent – díszlet-jelmez-rendezés: Márkus László, 1931',
        personId: 4,
        type: 'image'
    },
    '22_turandot_1927.JPG': {
        title: '',
        description: 'Giacomo Puccini: Turandot – Az opera zárójelenete – díszlet-jelmez-rendezés: Márkus László, 1927',
        personId: 4,
        type: 'image'
    },
    '23_turandot_sandorerzsi_tihanyi.JPG': {
        title: '',
        description: 'Giacomo Puccini: Turandot – Sándor Erzsi (Liù) és Tihanyi Vilma (Turandot) – jelmez: Márkus László, 1927',
        personId: 4,
        type: 'image'
    },
    '24_francescadarimini1929_markus.jpg': {
        title: '',
        description: 'Riccardo Zandonai: Francesca di Rimini – Jelenet az I. felvonásból – díszlet-jelmez-rendezés: Márkus László, 1929',
        personId: 4,
        type: 'image'
    },
    '25_francescadarimini1929_markus_2.felv.jpg': {
        title: '',
        description: 'Riccardo Zandonai: Francesca di Rimini – Jelenet az II. felvonásból – díszlet-jelmez-rendezés: Márkus László, 1929',
        personId: 4,
        type: 'image'
    },
    '26_francescadarimini1929_markus_4.fe.jpg': {
        title: '',
        description: 'Riccardo Zandonai: Francesca di Rimini – Jelenet a IV. felvonás 2. képéből – díszlet-jelmez-rendezés: Márkus László, 1929',
        personId: 4,
        type: 'image'
    },
    '27_Falstaff_Palmre_001.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Falstaff – Falstaff: Palló Imre – jelmez: Márkus László, 1947',
        personId: 4,
        type: 'image'
    },
    '28_MarschalkMelisande1925_001.jpg': {
        title: '',
        description: 'Claude Debussy: Pelléas és Mélisande – Mélisande: Marschalkó Rózsi – jelmez: Márkus László, 1925',
        personId: 4,
        type: 'image'
    },
    '29_Oda-vissza1929_Losonczy_001.jpg': {
        title: '',
        description: 'Paul Hindemith: Oda-vissza – Jelenet az operából – díszlet-jelmez-rendezés: Márkus László, 1929',
        personId: 4,
        type: 'image'
    },
    '30_Oda-vissza1929_01.jpg': {
        title: '',
        description: 'Paul Hindemith: Oda-vissza – Jelenet az operából – díszlet-jelmez-rendezés: Márkus László, 1929',
        personId: 4,
        type: 'image'
    },
    '01.Petruska_1926.jpg': {
        title: '',
        description: 'Igor Stravinsky – Brada Ede: Petruska – Jelenet az 1. képből – díszlet-jelmez: Oláh Gusztáv, 1926',
        personId: 5,
        type: 'image'
    },
    '02.Xerxes_001_28.jpg': {
        title: '',
        description: 'Georg Friedrich Händel: Xerxes – Jelenet – díszlet-jelmez: Oláh Gusztáv, 1928',
        personId: 5,
        type: 'image'
    },
    '03.Diotoro_929.jpg': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij – Brada Ede: A diótörő – Jelenet a II. felvonásból – díszlet-jelmez: Oláh Gusztáv, 1929',
        personId: 5,
        type: 'image'
    },
    '04.Tenor_R_Markus002_29.jpg': {
        title: '',
        description: 'Dohnányi Ernő: A tenor– Jelenet a II. felvonásból – díszlet-jelmez: Oláh Gusztáv, 1929',
        personId: 5,
        type: 'image'
    },
    '05_csongor_1930.jpg': {
        title: '',
        description: 'Weiner Leó – Jan Ciepliński: Csongor és Tünde – Jelenet – díszlet-jelmez: Oláh Gusztáv, 1930',
        personId: 5,
        type: 'image'
    },
    '06_Traviata1931_1fv_001.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Traviata – Jelenet a II. felvonásból – díszlet-jelmez: Oláh Gusztáv, 1931',
        personId: 5,
        type: 'image'
    },
    '07_olah_elsslerfanny_1933.jpg': {
        title: '',
        description: 'Nádor Mihály – Jan Ciepliński: Elssler Fanny – Jelenet az 1. képből – díszlet-jelmez: Oláh Gusztáv, 1933',
        personId: 5,
        type: 'image'
    },
    '08.fabolfaragott1935_004_masolata.jpg': {
        title: '',
        description: 'Bartók Béla – Jan Ciepliński: A fából faragott királyfi – A fák jelenete – díszlet-jelmez: Oláh Gusztáv és Fülöp Zoltán, 1935',
        personId: 5,
        type: 'image'
    },
    '09_kekszakallu-1936.jpg': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára – Jelenet – díszlet-jelmez: Oláh Gusztáv, 1936',
        personId: 5,
        type: 'image'
    },
    '10.Bohem_1937.JPG': {
        title: '',
        description: 'Giacomo Puccini: Bohémélet – Jelenet a III. felvonásból – díszlet: Oláh Gusztáv, 1937',
        personId: 5,
        type: 'image'
    },
    '11_bohemelet_2006_jelenet.jpg': {
        title: '',
        description: 'Giacomo Puccini: Bohémélet – Jelenet a II. felvonásból – díszlet: Oláh Gusztáv, 2006',
        personId: 5,
        type: 'image'
    },
    '12_mesterdalnokok_1938.jpg': {
        title: '',
        description: 'Richard Wagner: A nürnbergi mesterdalnokok – Jelenet az I. felvonásból – díszlet-jelmez: Oláh Gusztáv, 1938',
        personId: 5,
        type: 'image'
    },
    '13_mesterdalnokok_1938.jpg': {
        title: '',
        description: 'Richard Wagner: A nürnbergi mesterdalnokok – Jelenet a III. felvonás 2. képéből – díszlet-jelmez: Oláh Gusztáv, 1938',
        personId: 5,
        type: 'image'
    },
    '14_A_fabol_faragott_kiralyfi_1939_001.jpg': {
        title: '',
        description: 'Bartók Béla – Harangozó Gyula: A fából faragott királyfi – A fák jelenete – díszlet-jelmez: Oláh Gusztáv, 1939',
        personId: 5,
        type: 'image'
    },
    '15_lang_milano_01.jpg': {
        title: '',
        description: 'Ottorino Respighi: A láng – Jelenet az I. felvonásból – díszlet-jelmez-rendezés: Oláh Gusztáv, 1940',
        personId: 5,
        type: 'image'
    },
    '16_lang_milano_02.jpg': {
        title: '',
        description: 'Ottorino Respighi: A láng – Jelenet a III. felvonás 1. képéből – díszlet-jelmez-rendezés: Oláh Gusztáv, 1940',
        personId: 5,
        type: 'image'
    },
    '17_lang_walter_lendvay.jpg': {
        title: '',
        description: 'Ottorino Respighi: A láng – Walter Rózsi (Silvana) és Lendvay Andor (Basilio) –jelmez: Oláh Gusztáv, 1935',
        personId: 5,
        type: 'image'
    },
    '18_kekszakallu_1948.jpg': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára – Jelenet – díszlet-jelmez-rendezés: Oláh Gusztáv, 1948',
        personId: 5,
        type: 'image'
    },
    '19_Balvanyos_var_1948_foto_Varkonyi_001.jpg': {
        title: '',
        description: 'Bartók Béla – Harangozó Gyula: Bálványosvár – Jelenet – díszlet-jelmez: Oláh Gusztáv, 1948',
        personId: 5,
        type: 'image'
    },
    '20_Carmen1949_001.jpg': {
        title: '',
        description: 'Georges Bizet: Carmen – Jelenet az I. felvonásból – díszlet-jelmez-rendezés: Oláh Gusztáv, 1949',
        personId: 5,
        type: 'image'
    },
    '21_Carmen1949tabla_001.jpg': {
        title: '',
        description: 'Georges Bizet: Carmen – Tabló – díszlet-jelmez-rendezés: Oláh Gusztáv, 1949',
        personId: 5,
        type: 'image'
    },
    '22_diotoro_kb20822_1950.jpg': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij – Vaszilij Vajnonen: A diótörő – Jelenet az I. felvonásból – díszlet-jelmez: Oláh Gusztáv, 1950 – Forrás: OSZK Színháztörténeti tár',
        personId: 5,
        type: 'image'
    },
    '23_diotoro.JPG': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij – Vaszilij Vajnonen: A diótörő – Jelenet az I. felvonásból – díszlet-jelmez: Oláh Gusztáv, 2012',
        personId: 5,
        type: 'image'
    },
    '24_Diotoro_1950_IIIfv3kep_01.jpg': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij – Vaszilij Vajnonen: A diótörő – Jelenet a III. felvonásból – díszlet-jelmez: Oláh Gusztáv, 1950',
        personId: 5,
        type: 'image'
    },
    '25_MG_3257_CSP.JPG': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij – Vaszilij Vajnonen: A diótörő – Jelenet a III. felvonásból – díszlet-jelmez: Oláh Gusztáv, 2012',
        personId: 5,
        type: 'image'
    },
    '26_Traviata1950_001.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Traviata – Jelenet az I. felvonásból – díszlet-rendezés: Oláh Gusztáv, 1950',
        personId: 5,
        type: 'image'
    },
    '27_cosi_1952Cosi1952_I_1.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Così fan tutte – Nyitókép – díszlet-rendezés: Oláh Gusztáv, 1952',
        personId: 5,
        type: 'image'
    },
    '28_Fabol_faragott1952_001.jpg': {
        title: '',
        description: 'Bartók Béla – Vashegyi Ernő: A fából faragott királyfi – Jelenet – díszlet-jelmez: Oláh Gusztáv, 1952',
        personId: 5,
        type: 'image'
    },
    '29_Pillangokisasszony1952_003.jpg': {
        title: '',
        description: 'Giacomo Puccini: Pillangókisasszony – Jelenet az I. felvonásból – díszlet: Oláh Gusztáv, 1952',
        personId: 5,
        type: 'image'
    },
    '30_tosca_1953.JPG': {
        title: '',
        description: 'Giacomo Puccini: Tosca – Jelenet az I felvonásból – díszlet: Oláh Gusztáv, 1953',
        personId: 5,
        type: 'image'
    },
    '01_Hovanscsina1936_total_01.jpg': {
        title: '',
        description: 'Mogyeszt Petrovics Muszorgszkij: Hovanscsina – Jelenet az I. felvonásból – jelmez: Márk Tivadar, 1936',
        personId: 6,
        type: 'image'
    },
    '02_Pataky_Bohemelet_1937_Lendvai_Losonczy_Szekely.jpg': {
        title: '',
        description: 'Giacomo Puccini: Bohémélet – Lendvay Andor (Schaunard), Pataky Kálmán (Rodolphe), Losonczy György (Marcello) és Székely Mihály (Colline) – jelmez: Márk Tivadar, 1937',
        personId: 6,
        type: 'image'
    },
    '03_lohengrin_1938_osvathjulia_002.jpg': {
        title: '',
        description: 'Richard Wagner: Lohengrin – Elza: Osváth Júlia – jelmez: Márk Tivadar, 1938',
        personId: 6,
        type: 'image'
    },
    '04_Salome1940_tabla_01.jpg': {
        title: '',
        description: 'Richard Strauss: Salome – Az 1940-es felújítás tablója – jelmez: Márk Tivadar, 1940',
        personId: 6,
        type: 'image'
    },
    '05_manon_1941_osvath.jpg': {
        title: '',
        description: 'Jules Massenet: Manon – Manon: Osváth Júlia – jelmez: Márk Tivadar, 1941',
        personId: 6,
        type: 'image'
    },
    '06_Csodahegedu_1942_orosz_osvath.jpg': {
        title: '',
        description: 'Werner Egk: A csodahegedű – Orosz Júlia (Ninabella grófnő) és Osváth Júlia (Gréti) – jelmez: Márk Tivadar, 1942',
        personId: 6,
        type: 'image'
    },
    '07_Pikk_dama_Budanovits_Orosz001.jpg': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij: Pikk dáma – Budanovits Máia (A grőfnő) és Orosz Júlia (Liza) – jelmez: Márk Tivadar, 1945',
        personId: 6,
        type: 'image'
    },
    '08_lohengrin_1948_palankay.jpg': {
        title: '',
        description: 'Richard Wagner: Lohengrin – Ortrud: Palánkay Klára – jelmez: Márk Tivadar, 1948',
        personId: 6,
        type: 'image'
    },
    '09_Igorherceg(2).jpg': {
        title: '',
        description: 'Alekszandr Borogyin: Igor herceg – Jaroszlavna: Warga Lívia – jelmez: Márk Tivadar, 1948',
        personId: 6,
        type: 'image'
    },
    '10_Mesterdalnokok1949_Sardy_Janos_Budanovits_Maria_Osvath_Julia_Simandy_Jozsef_Varkonyi_foto_011.jpg': {
        title: '',
        description: 'Richard Wagner: A nürnbergi mesterdalnokok – Simándy József (Stolzingi Walter), Osváth Júlia (Éva), Budanovits Mária (Magdaléna) és Sárdy János (Dávid) – jelmez: Márk Tivadar, 1948',
        personId: 6,
        type: 'image'
    },
    '11_Mindszenti_Adam_Gyurkovics_Maria_Melis_Gyorgy_Janosvitez_1953_rairvaDebrecen_004.jpg': {
        title: '',
        description: 'Kacsoh Pongrác: János vitéz – Mindszenti Ödön (A francia király), Gyurkovics Mária (A francia királykisasszony) és Melis György (Strázsamester) – jelmez: Márk Tivadar, 1953',
        personId: 6,
        type: 'image'
    },
    '12_Anyegin1955_Melis_Gyorgy_Hazy_Erzsebet_004.jpg': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij: Anyegin – Melis György (Anyegin) és Házy Erzsébet (Tatjána) – jelmez: Márk Tivadar, 1953',
        personId: 6,
        type: 'image'
    },
    '13_bahcsiszeraji_1996_veghkriszta.jpg': {
        title: '',
        description: 'Borisz Aszafjev – Rosztyiszlav Zaharov: A bahcsiszeráji szökőkút – Zaréma: Végh Krisztina – jelmez: Márk Tivadar, 1996',
        personId: 6,
        type: 'image'
    },
    '14_Harangozo_Gyula_Coppelia_002.jpg': {
        title: '',
        description: 'Léo Delibes – Harangozó Gyula: Coppélia – Coppélius: Harangozó Gyula – jeémez: Márk Tivadar, 1953',
        personId: 6,
        type: 'image'
    },
    '15_Bank_ban1953_Losonczy_Gyorgy_Simandy_Jozsef.jpg': {
        title: '',
        description: 'Erkel Ferenc: Bánk bán – Losonczy György (Tiborc) és Simándy József (Bánk bán) – jelmez: Márk Tivadar, 1953',
        personId: 6,
        type: 'image'
    },
    '16_pomade_1953_melis.jpg': {
        title: '',
        description: 'Ránki György: Pomádé király – Garda Roberto: Melis György – jelmez: Márk Tivadar, 1953',
        personId: 6,
        type: 'image'
    },
    '17_Joviczky_Jozsef_Otello_002.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Otello – Otello: Joviczky József – jelmez: Márk Tivadar, 1954',
        personId: 6,
        type: 'image'
    },
    '18_otello_1954_tiszay_losonczy.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Otello – Tiszay Magda (Emília) és Losonczy György (Jago) – jelmez: Márk Tivadar, 1954',
        personId: 6,
        type: 'image'
    },
    '19_donjuan_1956_nagypal_osvath.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Don Giovanni – Nagypál László (Don Ottavio) és Osváth Júlia (Donna Anna) – jelmez: Márk Tivadar, 1956',
        personId: 6,
        type: 'image'
    },
    '20_Acsodalatosmandarin1956_LakatosGabriella_004.jpg': {
        title: '',
        description: 'Bartók Béla – Harangozó Gyula: A csodálatos mandarin – A lány: Lakatos Gabriella – jelmez: Márk Tivadar, 1956',
        personId: 6,
        type: 'image'
    },
    '21_Takacs_Paula_Turandot_58.jpg': {
        title: '',
        description: 'Giacomo Puccini: Turandot – Turandot: Takács Paula – jelmez: Márk Tivadar, 1958',
        personId: 6,
        type: 'image'
    },
    '22_Avarazsfuvola1976_HormaiJ_Polgar_L_SudlikM_001.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: A varázsfuvola – Hormai József (Tamino), Polgár László (Sarastro) és Sudlik Mária (Pamina – jelmez: Márk Tivadar, 1976',
        personId: 6,
        type: 'image'
    },
    '23_Cestlaguerre1962_Farago_Andras_Matyas_Maria.jpg': {
        title: '',
        description: 'Petrovics Emil: C’est la guerre – Faragó András (Őrnagy) és Mátyás Mária (Feleség) – jelmez: Márk Tivadar, 1962',
        personId: 6,
        type: 'image'
    },
    '24_Brankovics_1962.jpg': {
        title: '',
        description: 'Erkel Ferenc: Brankovics György – Palcsó Sándor (Gerő), Simándy József (II. Murad) és Házy Erzsébet (István) – jelmez: Márk Tivadar, 1962',
        personId: 6,
        type: 'image'
    },
    '25_Lohengrin1964_AntalffyA_KanyaS_Matyas_001.jpg': {
        title: '',
        description: 'Richard Wagner: Lohengrin – Antalffy Albert (Madarász Henrik), Simándy József (Lohengrin), Mátyás Mária (Elza) és Svéd Sándor (Telramund) – jelmez: Márk Tivadar, 1964',
        personId: 6,
        type: 'image'
    },
    '26_DonCarlos.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Don Carlos – Szőnyi Olga (Eboli hercegnő), Simándy József (Don Carlos) és Melis György (Posa márki) – jelmez: Márk Tivadar, 1969',
        personId: 6,
        type: 'image'
    },
    '27_Samson1973_Melis_Gyorgy_Csanyi_Janos_001.jpg': {
        title: '',
        description: 'Szokolay Sándor: Sámson – Melis György (Sámson) és Csányi János (Jefte) – jelmez: Márk Tivadar, 1973',
        personId: 6,
        type: 'image'
    },
    '28_SimonBoccanegra1980_KincsesV_MillerL_004.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Simon Boccanegra – Kincses Veronika (Amelia Grimaldi) és Miller Lajos (Simon Boccanegra) – jelmez: Márk Tivadar, 1980',
        personId: 6,
        type: 'image'
    },
    '29_Molnar_Andras_Titus1991_001.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Titus kegyelme – Titus: Molnár András – jelmez: Márk Tivadar, 1991',
        personId: 6,
        type: 'image'
    },
    '30_torzene1999_002.jpg': {
        title: '',
        description: 'Johann Strauss – Kenessey Jenő – Harangozó Gyula: Térzene – Jelenet – Jelmez: Márk Tivadar, 1999',
        personId: 6,
        type: 'image'
    },
    '01_arvajozsi_1933_.jpg': {
        title: '',
        description: 'Kósa György: Árva Józsi három csodája – Józsi: Zsedényi Károly – díszlet-jelmez: Fülöp Zoltán, 1933',
        personId: 7,
        type: 'image'
    },
    '02_marta_1935.jpg': {
        title: '',
        description: 'Friedrich von Flotow: Márta – A richmondi vásár – díszlet-jelmez: Fülöp Zoltán, 1935',
        personId: 7,
        type: 'image'
    },
    '03_fabolfaragott_1935.jpg': {
        title: '',
        description: 'Bartók Béla – Jan Ciepliński: A fából faragott királyfi – Az erdő-jelenet – díszlet-jelmez: Fülöp Zoltán és Oláh Gusztáv, 1935',
        personId: 7,
        type: 'image'
    },
    '04_fabolfaragott_1935_02.jpg': {
        title: '',
        description: 'Bartók Béla – Jan Ciepliński: A fából faragott királyfi – A tündér: Bordy Bella – jelmez: Fülöp Zoltán, 1935',
        personId: 7,
        type: 'image'
    },
    '05_jatekdoboz_1936_02.jpg': {
        title: '',
        description: 'Claude Debussy – Jan Ciepliński: A játékdoboz – Jelenet – díszlet-jelmez: Fülöp Zoltán, 1936',
        personId: 7,
        type: 'image'
    },
    '06_csardajelenet_1936.jpg': {
        title: '',
        description: 'Hubay Jenő – Kenessey Jenő – Harangozó Gyula: Csárdajelenet – Jelenet – díszlet-jelmez: Fülöp Zoltán, 1936',
        personId: 7,
        type: 'image'
    },
    '07_lysistrata_1937.jpg': {
        title: '',
        description: 'Lajtha László – Brada Rezső: Lysistrata, 1937 – Jelenet – díszlet-jelmez: Fülöp Zoltán, 1937',
        personId: 7,
        type: 'image'
    },
    '08_salome_1940.jpg': {
        title: '',
        description: 'Richard Strauss: Salome – Jelenet – díszlet: Fülöp Zoltán, 1940',
        personId: 7,
        type: 'image'
    },
    '09_salome_1958.jpg': {
        title: '',
        description: 'Richard Strauss: Salome – Jelenet – díszlet: Fülöp Zoltán, 1958',
        personId: 7,
        type: 'image'
    },
    '10_cseresznyevirag_1941.jpg': {
        title: '',
        description: 'Dévényi Imre: Cseresznyevirág – Jelenet – díszlet: Fülöp Zoltán, 1942',
        personId: 7,
        type: 'image'
    },
    '11_walkur_1942.jpg': {
        title: '',
        description: 'Richard Wagner: A walkür – A II. felvonás nyitójelenete – díszlet: Fülöp Zoltán, 1942',
        personId: 7,
        type: 'image'
    },
    '12_petergrimes_1947.jpg': {
        title: '',
        description: 'Benjamin Britten: Peter Grimes – Jelenet az I. felvonásból – díszlet: Fülöp Zoltán, 1947',
        personId: 7,
        type: 'image'
    },
    '13_windsori_1948.jpg': {
        title: '',
        description: 'Otto Nicolai: A windsori víg nők – Falstaff: Székely Mihály – díszlet: Fülöp Zoltán, 1948',
        personId: 7,
        type: 'image'
    },
    '14_Bahcsi1952_.jpg': {
        title: '',
        description: 'Borisz Aszafjev – Rosztyiszlav Zaharov: A bahcsiszeráji szökőkút – Jelenet a III. felvonásból, Zaréma: Kovács Nóra – díszlet: Fülöp Zoltán, 1952',
        personId: 7,
        type: 'image'
    },
    '15_Halka1952_001.jpg': {
        title: '',
        description: 'Stanisław Moniuszko: Halka – Jelenet a III. felvonásból – díszlet: Fülöp Zoltán, 1952',
        personId: 7,
        type: 'image'
    },
    '16_Pomade_kiraly_1953_006.jpg': {
        title: '',
        description: 'Ránki György: Pomádé király – Trónterem – díszlet: Fülöp Zoltán, 1953',
        personId: 7,
        type: 'image'
    },
    '17_romeoesjulia_1955_02.jpg': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij – Harangozó Gyula: Rómeó és Júlia – Tér Veronában – díszlet: Fülöp Zoltán, 1955',
        personId: 7,
        type: 'image'
    },
    '18_csodalatosmandarin_1956.jpg': {
        title: '',
        description: 'Bartók Béla – Harangozó Gyula: A csodálatos mandarin- Nyitójelenet – díszlet: Fülöp Zoltán, 1956',
        personId: 7,
        type: 'image'
    },
    '19_orfeusz_1957_02.jpg': {
        title: '',
        description: 'Christoph Willibald Gluck: Orfeusz – Az I. felvonás balettje – díszlet: Fülöp Zoltán, 1957',
        personId: 7,
        type: 'image'
    },
    '20_fabolfaragott_1958_01.jpg': {
        title: '',
        description: 'Bartók Béla – Harangozó Gyula: A fából faragott királyfi – Jelenet – díszlet: Fülöp Zoltán, 1958',
        personId: 7,
        type: 'image'
    },
    '21_fabolfaragott_1958_02.jpg': {
        title: '',
        description: 'Bartók Béla – Harangozó Gyula: A fából faragott királyfi – Jelenet – díszlet: Fülöp Zoltán, 1958',
        personId: 7,
        type: 'image'
    },
    '22_turandot_1958.jpg': {
        title: '',
        description: 'Giacomo Puccini: Turandot – Nagykép – díszlet: Fülöp Zoltán, 1958',
        personId: 7,
        type: 'image'
    },
    '23_trisztan_1959.jpg': {
        title: '',
        description: 'Richard Wagner: Trisztán és Izolda – A III. felvonás nyitójelenete – díszlet: Fülöp Zoltán, 1959',
        personId: 7,
        type: 'image'
    },
    '24_varazsfuvola_1960.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: A varázsfuvola – Jelenet a II. felvonásból – díszlet: Fülöp Zoltán, 1960',
        personId: 7,
        type: 'image'
    },
    '25_varazsfuvola_1960_02.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: A varázsfuvola – Zárókép – díszlet: Fülöp Zoltán, 1960',
        personId: 7,
        type: 'image'
    },
    '26_Macbeth1961_002.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Macbeth – Jelenet a II. felvonásból – díszlet: Fülöp Zoltán, 1961',
        personId: 7,
        type: 'image'
    },
    '27_Manon1961.JPG': {
        title: '',
        description: 'Giacomo Puccini: Manon Lescaut – A III. felvonás fináléja – díszlet: Fülöp Zoltán, 1961',
        personId: 7,
        type: 'image'
    },
    '28_vernasz_1964.jpg': {
        title: '',
        description: 'Szokolay Sándor: Vérnász – Jelenet a II. felvonásból – díszlet: Fülöp Zoltán, 1964',
        personId: 7,
        type: 'image'
    },
    '29_tannhauser_67.jpg': {
        title: '',
        description: 'Richard Wagner: Tannhäuser – Jelenet a II. felvonásból – díszlet: Fülöp Zoltán, 1967',
        personId: 7,
        type: 'image'
    },
    '30_Csipkerozsika_001.jpg': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij – Pjotr Guszev: Csipkerózsika – A balett fináléja – díszlet: Fülöp Zoltán, 1967',
        personId: 7,
        type: 'image'
    },
    '01_orygrofja_1960_03Orygrofja_013.jpg': {
        title: '',
        description: 'Gioacchino Rossini: Ory grófja – Az opera fináléja – díszlet: Forray Gábor, 1960',
        personId: 8,
        type: 'image'
    },
    '02_hary_1962_01.jpg': {
        title: '',
        description: 'Kodály Zoltán: Háry János – 1. kaland - díszlet: Forray Gábor, 1962',
        personId: 8,
        type: 'image'
    },
    '03_wozzeck_1964_01.jpg': {
        title: '',
        description: 'Alban Berg: Wozzeck – díszlet: Forray Gábor, 1964',
        personId: 8,
        type: 'image'
    },
    '04_wozzeck_1964_02.jpg': {
        title: '',
        description: 'Alban Berg: Wozzeck – díszlet: Forray Gábor, 1964',
        personId: 8,
        type: 'image'
    },
    '05_poppea_1968.jpg': {
        title: '',
        description: 'Claudio Monteverdi: Poppea megkoronázása – Otho: Sólyom-Nagy Sándor, Nero: Korondy György és Drusilla: Andor Éva – díszlet: Forray Gábor, 1968',
        personId: 8,
        type: 'image'
    },
    '06_bun_esbunhodes_1969_01.jpg': {
        title: '',
        description: 'Petrovics Emil: Bűn és bűnhődés - díszlet: Forray Gábor',
        personId: 8,
        type: 'image'
    },
    '07_don-carlos.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Don Carlos – Autodafé-jelenet – díszlet: Forray Gábor, 2008',
        personId: 8,
        type: 'image'
    },
    '08_Laurencia1970_Forray_G_diszlete_016.jpg': {
        title: '',
        description: 'Alexander Abramovics Krejn – Vahtang Csabukiani: Laurencia – II. felvonás – díszlet: Forray Gábor, 1970',
        personId: 8,
        type: 'image'
    },
    '09_kekszakallu_1970_01.jpg': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára – díszlet: Forray Gábor, 1970',
        personId: 8,
        type: 'image'
    },
    '10_kekszakallu_1981_02.jpg': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára – díszlet: Forray Gábor, 1981',
        personId: 8,
        type: 'image'
    },
    '11_fabolfaragott_1970_02.jpg': {
        title: '',
        description: 'Bartók Béla – Seregi László: A fából faragott királyfi – díszlet: Forray Gábor, 1970',
        personId: 8,
        type: 'image'
    },
    '12_hovanscsina_1972.jpg': {
        title: '',
        description: 'Mogyeszt Petrovics Muszorgszkij: Hovanscsina – Az I. felvonás dináléja - díszlet: Forray Gábor, 1972',
        personId: 8,
        type: 'image'
    },
    '13_istenek_1972_02.jpg': {
        title: '',
        description: 'Richard Wagner: Az istenek alkonya – Az I. felvonás zárójelenete – díszlet: Forray Gábor, 1972',
        personId: 8,
        type: 'image'
    },
    '14_donjuan_1974_02.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Don Giovanni – A II. felvonás 1. képe – díszlet: Forray Gábor, 1974',
        personId: 8,
        type: 'image'
    },
    '15_donjuan_1974_04.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Don Giovanni – A Vacsora-jelenet – díszlet: Forray Gábor, 1974',
        personId: 8,
        type: 'image'
    },
    '16_Lombardok_1974_02.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A lombardok – Jelenet az utolsó képből – díszlet: Forray Gábor, 1974',
        personId: 8,
        type: 'image'
    },
    '17_mozes_1977_02.jpg': {
        title: '',
        description: 'Durkó Zsolt: Mózes – Az aranyborjú imádása – díszlet: Forray Gábor, 1977',
        personId: 8,
        type: 'image'
    },
    '18_bolygohollandi_1978.jpg': {
        title: '',
        description: 'Richard Wagner: A bolygó hollandi – Az I. felvonás fináléja – díszlet: Forray Gábor, 1978',
        personId: 8,
        type: 'image'
    },
    '19_chenier_1978_02.jpg': {
        title: '',
        description: 'Umberto Giordano: André Chénier – A II. felvonás fináléja – díszlet: Forray Gábor, 1978',
        personId: 8,
        type: 'image'
    },
    '20_chenier_1978_03.jpg': {
        title: '',
        description: 'Umberto Giordano: André Chénier – Jelenet a III. felvonásból – díszlet: Forray Gábor, 1978',
        personId: 8,
        type: 'image'
    },
    '21_chenier_1978_04.jpg': {
        title: '',
        description: 'Umberto Giordano: André Chénier – Az opera záróképe – díszlet: Forray Gábor, 1978',
        personId: 8,
        type: 'image'
    },
    '22_cosi_1979_01.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Così fan tutte – Nyitókép – díszlet: Forray Gábor, 1978',
        personId: 8,
        type: 'image'
    },
    '23_fabolfaragott_1981.jpg': {
        title: '',
        description: 'Bartók Béla – Seregi László: A fából faragott királyfi – díszlet: Forray Gábor, 1981',
        personId: 8,
        type: 'image'
    },
    '24_parsifal_2006.jpg': {
        title: '',
        description: 'Richard Wagner: Parsifal – Az I. felvonás 2. képe – díszlet: Forray Gábor, 2006',
        personId: 8,
        type: 'image'
    },
    '25_parsifal_2006_02.jpg': {
        title: '',
        description: 'Richard Wagner: Parsifal – Az II. felvonás 2. képe – díszlet: Forray Gábor, 2006',
        personId: 8,
        type: 'image'
    },
    '26_romeo_1993_01.jpg': {
        title: '',
        description: 'Szergej Prokofjev – Seregi László: Rómeó és Júlia – Tér Veronában – díszlet: Forray Gábor, 1993',
        personId: 8,
        type: 'image'
    },
    '27_romeo_1993_04.jpg': {
        title: '',
        description: 'Szergej Prokofjev – Seregi László: Rómeó és Júlia – Júlia sírja – díszlet: Forray Gábor, 1993',
        personId: 8,
        type: 'image'
    },
    '28_szentivaneji_1989_03.jpg': {
        title: '',
        description: 'Felix Mendelssohn – Seregi László: Szentivánéji álom – Az erdőben – díszlet: Forray Gábor, 1989',
        personId: 8,
        type: 'image'
    },
    '29_szentivaneji_1989_04.jpg': {
        title: '',
        description: 'Felix Mendelssohn – Seregi László: Szentivánéji álom – Zárókép – díszlet: Forray Gábor, 1989',
        personId: 8,
        type: 'image'
    },
    '30_fabolfaragott_1998_01.jpg': {
        title: '',
        description: 'Bartók Béla – Seregi László: A fából faragott királyfi – díszlet: Forray Gábor, 1998',
        personId: 8,
        type: 'image'
    },
    '01_cosi_1979.jpg': {
        title: '',
        description: 'Wolfgang Mozart: Così fan tutte –Gulyás Dénes (Ferrando), Kalmár Magda (Despina) és Gáti István (Guglielmo) – jelmez: Schäffer Judit, 1979',
        personId: 9,
        type: 'image'
    },
    '02_cosi_2006_02.jpg': {
        title: '',
        description: 'Wolfgang Mozart: Così fan tutte – Molnár Levente (Guglielmo) és Szappanos Tibor (Ferrnado) – jelmez: Schäffer Judit, 2006',
        personId: 9,
        type: 'image'
    },
    '03_cosi_2008.jpg': {
        title: '',
        description: 'Wolfgang Mozart: Così fan tutte – Vághelyi Éva (Dorabella) és Kolonits Klára (Fiordiligi) – jelmez: Schäffer Judit, 2008',
        personId: 9,
        type: 'image'
    },
    '04_kekszakall_1981.jpg': {
        title: '',
        description: 'Jacques Offenbach: Kékszakáll –Karizs Béla (Kékszakáll lovag) és Sudlik Mária (Boulotte) – jelmez: Schäffer Judit, 1981',
        personId: 9,
        type: 'image'
    },
    '05_proba_2005_02.JPG': {
        title: '',
        description: 'Johann Sebastian Bach – Presser Gábor – Fodor Antal: A próba – Nyitójelenet – jelmez: Schäffer Judit, 2005',
        personId: 9,
        type: 'image'
    },
    '06_proba_2005.jpg': {
        title: '',
        description: 'Johann Sebastian Bach – Presser Gábor – Fodor Antal: A próba – Középen Cserta József (A Jézust játszó táncos) – jelmez: Schäffer Judit, 2005',
        personId: 9,
        type: 'image'
    },
    '07_traviata_1986.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Traviata –Pitti Katalin (Violetta Valery) és Melis György (Giorgio Germont) – jelmez: Schäffer Judit, 1986',
        personId: 9,
        type: 'image'
    },
    '08_traviata_2012_02.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Traviata – Violetta Valery: Kolonits Klára – jelmez: Schäffer Judit, 2012',
        personId: 9,
        type: 'image'
    },
    '09_traviata_2012_03.JPG': {
        title: '',
        description: 'Giuseppe Verdi: Traviata – Jelenet a II. felvonásból – jelmez: Schäffer Judit, 2012',
        personId: 9,
        type: 'image'
    },
    '10_nabucco_2006.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Nabucco – Cserhalmi Ferenc (Baál főpapja) és Rálik Szilvia (Abigél) – jelmez: Schäffer Judit, 2006',
        personId: 9,
        type: 'image'
    },
    '11_nabucco_2006_02.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Nabucco – Szüle Tamás (Baál főpapja) és Lukács Gyöngyi (Abigél) – jelmez: Schäffer Judit, 2006',
        personId: 9,
        type: 'image'
    },
    '12_nabucco_2006_04.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Nabucco – Nabucco: Perencz Béla – jelmez: Schäffer Judit, 2006',
        personId: 9,
        type: 'image'
    },
    '13_hary_1988_02.jpg': {
        title: '',
        description: 'Kodály Zoltán: Háry János –Maria Teresa Uribe (Mária Lujza) és Déry Gabriella (A császárné) – jelmez: Schäffer Judit, 1988',
        personId: 9,
        type: 'image'
    },
    '14_hary_1988_03.jpg': {
        title: '',
        description: 'Kodály Zoltán: Háry János – Martin János (Marci bácsi) és Sánta Jolán (Örzse) – jelmez: Schäffer Judit, 1988',
        personId: 9,
        type: 'image'
    },
    '15_hary_1988.jpg': {
        title: '',
        description: 'Kodály Zoltán: Háry János – Jelent az 1. kalandból – Az előtérben: Gerdesits Ferenc (Ebelasztin lovag), Maria Teresa Uribe (Mária Lujza) és Csurja Tamás (Háry János) – jelmez: Schäffer Judit, 1988',
        personId: 9,
        type: 'image'
    },
    '16_romeo_1988.jpg': {
        title: '',
        description: 'Charles Gounod: Rómeó és Júlia – Gulyás Dénes (Rómeó) és Rost Andrea (Júlia) – jelmez: Schäffer Judit, 1989',
        personId: 9,
        type: 'image'
    },
    '17_romeo_2011.jpg': {
        title: '',
        description: 'Charles Gounod: Rómeó és Júlia – Laczó András (Tybald), Káldi Kis András (Capulet) és Ambrus Ákos (Gregorio) – jelmez: Schäffer Judit, 2001',
        personId: 9,
        type: 'image'
    },
    '18_trisztan_1988.jpg': {
        title: '',
        description: 'Richard Wagner: Trisztán és Izolda –Kasza Katalin (Brangäne), Misura Zsuzsa Izolda) és Molnár András (Trisztán) – jelmez: Schäffer Judit, 1988',
        personId: 9,
        type: 'image'
    },
    '19_salome_1989.jpg': {
        title: '',
        description: 'Richard Strauss: Salome – Salome: Zempléni Mária – jelmez: Schäffer Judit, 1989',
        personId: 9,
        type: 'image'
    },
    '20_salome_2002.jpg': {
        title: '',
        description: 'Richard Strauss: Salome –Hormai József (Heródes) és Anja Silja (Heródias) – jelmez: Schäffer Judit, 2002',
        personId: 9,
        type: 'image'
    },
    '21_salome_2009.jpg': {
        title: '',
        description: 'Richard Strauss: Salome – Perencz Béla (Jochanaan) és Rálik Szilvia (Salome) – jelmez: Schäffer Judit, 2009',
        personId: 9,
        type: 'image'
    },
    '22_kekszakallu_1989.jpg': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára –Kováts Kolos (A kékszakállú herceg) és Komlósi Ildikó (Judit) – jelmez: Schäffer Judit, 1989',
        personId: 9,
        type: 'image'
    },
    '23_kekszakallu_1989_02.jpg': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára – Judit: Komlósi Ildikó – jelmez: Schäffer Judit, 1989',
        personId: 9,
        type: 'image'
    },
    '24_hunyadi_1989.jpg': {
        title: '',
        description: 'Erkel Ferenc: Hunyadi László – Hunyadi László: Molnár András – jelmez: Schäffer Judit, 1989',
        personId: 9,
        type: 'image'
    },
    '25_hunyadi_2003_03.jpg': {
        title: '',
        description: 'Erkel Ferenc: Hunyadi László – Cecília Lloyd (Gara Mária) és Fekete Attila (Hunyadi László) – jelmez: Schäffer Judit, 2003',
        personId: 9,
        type: 'image'
    },
    '26_tannhauser_2012_02.jpg': {
        title: '',
        description: 'Richard Wagner: Tannhäuser – Az I. felvonás fináléja – jelmez: Schäffer Judit, 2012',
        personId: 9,
        type: 'image'
    },
    '27_Tannhauser_Sur_2002_061.jpg': {
        title: '',
        description: 'Richard Wagner: Tannhäuser – Erzsébet: Sudár Gyöngyvér – jelmez: Schäffer Judit, 2002',
        personId: 9,
        type: 'image'
    },
    '28_csipke1991_03.jpg': {
        title: '',
        description: 'Pjotr Iljics Csajkovszkij – Róna Viktor: Csipkerózsika – Középen Végh Krisztina (Orgonatündér) – jelmez: Schäffer Judit, 1991',
        personId: 9,
        type: 'image'
    },
    '29_velencei_mor_1994_03.jpg': {
        title: '',
        description: 'Franz Schubert – Pártay Lilla: A velencei mór –Havas Ferenc (Brabantio), Volf Katalin (Desdemona) és Szakály György (Othello) – jelmez: Schäffer Judit, 1994',
        personId: 9,
        type: 'image'
    },
    '30_elfujta_2007_04.JPG': {
        title: '',
        description: "Antonín Dvořák – Pártay Lilla: Elfújta a szél –ifj. Nagy Zoltán (Rhett Buttler) és Popova Aleszja (Scarlett O'Hara) – jelmez: Schäffer Judit, 2007",
        personId: 9,
        type: 'image'
    },
    '01.Falstaff1985_Sudlik_Melis.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Falstaff – Sudlik Mária (Alice Ford) és Melis György (Falstaff) – díszlet-jelmez: Makai Péter, 1985',
        personId: 10,
        type: 'image'
    },
    '02_falstaff_1992_02.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Falstaff – Jelenet az I. felvonásból – díszlet-jelmez: Makai Péter, 1992',
        personId: 10,
        type: 'image'
    },
    '03_ariadne_1967_01.jpg': {
        title: '',
        description: 'Richard Strauss: Ariadné Naxosz szigetén – Jelenet az Előjátékból – díszlet-jelmez-rendezés: Makai Péter, 1967',
        personId: 10,
        type: 'image'
    },
    '04_ariadne_1967_02.jpg': {
        title: '',
        description: 'Richard Strauss: Ariadné Naxosz szigetén – Jelenet a Felvonásból – díszlet-jelmez-rendezés: Makai Péter, 1967',
        personId: 10,
        type: 'image'
    },
    '05_ariadne_1967_03.jpg': {
        title: '',
        description: 'Richard Strauss: Ariadné Naxosz szigetén – Bacchus érkezése – díszlet-jelmez-rendezés: Makai Péter, 1967',
        personId: 10,
        type: 'image'
    },
    '06_Mahagonny_067.jpg': {
        title: '',
        description: 'Kurt Weill: Mahagonny – Jelenet a II. felvonásból – díszlet-jelmez: Makai Péter, 1967',
        personId: 10,
        type: 'image'
    },
    '07_szoktetes_02.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Szöktetés a szerájból – Jelenet az I. felvonásból – díszlet-jelmez-rendezés: Makai Péter, 1968',
        personId: 10,
        type: 'image'
    },
    '08_szoktetes_1968_03.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Szöktetés a szerájból – Jelenet a II. felvonásból – díszlet-jelmez-rendezés: Makai Péter, 1968',
        personId: 10,
        type: 'image'
    },
    '09_porgy_1970_01.jpg': {
        title: '',
        description: 'George Gershwin: Porgy és Bess – Jelenet az I. felvonásból – díszlet-jelmez: Makai Péter, 1970',
        personId: 10,
        type: 'image'
    },
    '10_porgy_1970_02.jpg': {
        title: '',
        description: 'George Gershwin: Porgy és Bess – Jelenet az III. felvonásból – díszlet-jelmez: Makai Péter, 1970',
        personId: 10,
        type: 'image'
    },
    '11_rozsalovag_1997_kukely.jpg': {
        title: '',
        description: 'Richard Strauss: A rózsalovag – A tábornagyné: Kukely Júlia – díszlet-jelmez: Makai Péter, 1997',
        personId: 10,
        type: 'image'
    },
    '12_rozsalovag_1971_01.jpg': {
        title: '',
        description: 'Richard Strauss: A rózsalovag – Az ezüstrózsa átadása – díszlet-jelmez: Makai Péter, 1971',
        personId: 10,
        type: 'image'
    },
    '13_rozsalovag_1997_01.jpg': {
        title: '',
        description: 'Richard Strauss: A rózsalovag – A II. felvonás fináléja – díszlet-jelmez: Makai Péter, 1997',
        personId: 10,
        type: 'image'
    },
    '14_Lulu1973_Kalmar_M_RozsosI_001.jpg': {
        title: '',
        description: 'Alban Berg: Lulu – Kalmár Magda (Lulu és Rozsos István (Alwa) – díszlet-jelmez: Makai Péter, 1973',
        personId: 10,
        type: 'image'
    },
    '15_Haromnarancs_total_107.jpg': {
        title: '',
        description: 'Szergej Prokofjev: A három narancs szerelmese – Nyitókép – díszlet: Makai Péter, 1975',
        personId: 10,
        type: 'image'
    },
    '16_legyjo_1975_02.jpg': {
        title: '',
        description: 'Szabó Ferenc: Légy jó mindhalálig - Jelenet – díszlet: Makai Péter, 1975',
        personId: 10,
        type: 'image'
    },
    '17_vilagteremtese_1976_01.jpg': {
        title: '',
        description: 'Andrej Petrov – Natalia Kaszatkina – Vlagyimir Vasziljov: A világ teremtése – Jelenet – díszlet-jelmez: Makai Péter, 1976',
        personId: 10,
        type: 'image'
    },
    '18_ajtonkivul_1978_01.jpg': {
        title: '',
        description: 'Balassa Sándor: Az ajtón kívül – Fülöp Attila (Beckmann) és Tóth Sándor (Kabaréigazgató) – díszlet-jelmez: Makai Péter, 1978',
        personId: 10,
        type: 'image'
    },
    '19.Aravasz_rokacska_1979_Kukely_Julia_003.jpg': {
        title: '',
        description: 'Leoš Janáček: A ravasz rókácska – Kukely Júlia (A rókalány) – díszlet-jelmez: Makai Péter, 1978',
        personId: 10,
        type: 'image'
    },
    '20_ernani_1980_01.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Ernani – Jelenet a II. felvonásból – díszlet: Makai Péter, 1980',
        personId: 10,
        type: 'image'
    },
    '21_varazsfuvola_1980_02.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: A varázsfuvola – Az I. felvonás fináléja – díszlet-jelmez: Makai Péter, 1980',
        personId: 10,
        type: 'image'
    },
    '22_parsifal_1983_01.jpg': {
        title: '',
        description: 'Richard Wagner: Parsifal – Molnár András (Parsifal) és Polgár László (Gurnemanz) – jelmez: Makai Péter, 1983',
        personId: 10,
        type: 'image'
    },
    '23_parsifal_1983_02.JPG': {
        title: '',
        description: 'Richard Wagner: Parsifal – Molnár András (Parsifal), Polgár László (Gurnemanz) és Kasza Katalin (Kundry) – jelmez: Makai Péter, 1983',
        personId: 10,
        type: 'image'
    },
    '24_werther_1984053.jpg': {
        title: '',
        description: 'Jules Massenet: Werther – Jelenet az I. felvonásból – díszlet-jelmez: Makai Péter, 1984',
        personId: 10,
        type: 'image'
    },
    '25_trisztan_1988.jpg': {
        title: '',
        description: 'Richard Wagner: Trisztán és Izolda – Jelenet az I. felvonásból – díszlet: Makai Péter, 1988',
        personId: 10,
        type: 'image'
    },
    '26_lang_1989.jpg': {
        title: '',
        description: 'Ottorino Respighi: A láng – Jelenet a III. felvonás 2. képéből – díszlet-jelmez: Makai Péter, 1989',
        personId: 10,
        type: 'image'
    },
    '27_Vegzet_Tokody051.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A végzet hatalma – Jelenet az I. felvonásból – díszlet-jelmez: Makai Péter, 1990',
        personId: 10,
        type: 'image'
    },
    '28.Gioconda_1991_002.jpg': {
        title: '',
        description: 'Amilchare Ponchielli: Gioconda – Kovács Pál (Barnaba) – díszlet: Makai Péter, 1991',
        personId: 10,
        type: 'image'
    },
    '29_bolygohollandi_1997_01.jpg': {
        title: '',
        description: 'Richard Wagner: A bolygó hollandi – Jelenet az I. felvonásból – díszlet-jelmez: Makai Péter, 1997 (az 1987-es szegedi produkció operaházi átvétele)',
        personId: 10,
        type: 'image'
    },
    '30_bolygohollandi_1997_03.jpg': {
        title: '',
        description: 'Richard Wagner: A bolygó hollandi – Jelenet a III. felvonásból – díszlet-jelmez: Makai Péter, 1997 (az 1987-es szegedi produkció operaházi átvétele)',
        personId: 10,
        type: 'image'
    },
    '01_trubadur_1969_02.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet a I. felvonás 2. képéből – díszlet: Csikós Attila, 1969',
        personId: 11,
        type: 'image'
    },
    '02_trubadur_1969_01.jpg': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet a II. felvonás 2. képéből – díszlet: Csikós Attila, 1969',
        personId: 11,
        type: 'image'
    },
    '03_pillango_1971_02.jpg': {
        title: '',
        description: 'Giacomo Puccini: Pillangókisasszony – Jelenet az I. felvonásból – díszlet: Csikós Attila, 1971',
        personId: 11,
        type: 'image'
    },
    '04_pillango_1971_01.jpg': {
        title: '',
        description: 'Giacomo Puccini: Pillangókisasszony – Jelenet a II. felvonásból – díszlet: Csikós Attila, 1971',
        personId: 11,
        type: 'image'
    },
    '05_salome__A47A6942.jpg': {
        title: '',
        description: 'Richard Strauss: Salome – Jelenet – díszlet: Csikós Attila, 2009',
        personId: 11,
        type: 'image'
    },
    '06_Mozes_Rossini_1992055.jpg': {
        title: '',
        description: 'Gioacchino Rossini: Mózes – Jelenet a III. felvonásból – díszlet: Csikós Attila, 1992',
        personId: 11,
        type: 'image'
    },
    '07_Rajna_1993_056.jpg': {
        title: '',
        description: 'Richard Wagner: A Rajna kincse – Jelenet az 1. képből – díszlet: Csikós Attila, 1993',
        personId: 11,
        type: 'image'
    },
    '08_aida_2011.JPG': {
        title: '',
        description: 'Giuseppe Verdi: Aida – Jelenet az I. felvonás 1. képéből – díszlet: Csikós Attila, 2011',
        personId: 11,
        type: 'image'
    },
    '09_aida_2011_02.JPG': {
        title: '',
        description: 'Giuseppe Verdi: Aida – Jelenet az I. felvonás 2. képéből – díszlet: Csikós Attila, 2011',
        personId: 11,
        type: 'image'
    },
    '10_otello_diszlet_1_1130607_6149.JPG': {
        title: '',
        description: 'Giuseppe Verdi: Otello – A felépített díszlet – díszlet: Csikós Attila, 1994',
        personId: 11,
        type: 'image'
    },
    '11_otello_2011_02.JPG': {
        title: '',
        description: 'Giuseppe Verdi: Otello – Nyitójelenet – díszlet: Csikós Attila, 2011',
        personId: 11,
        type: 'image'
    },
    '12_otello_2011_03.JPG': {
        title: '',
        description: 'Giuseppe Verdi: Otello – Jelenet a III. felvonásból – díszlet: Csikós Attila, 2011',
        personId: 11,
        type: 'image'
    },
    '13_Walkar_1995_total_057.jpg': {
        title: '',
        description: 'Richard Wagner: A walkür – Zárójelenet – díszlet: Csikós Attila, 1995',
        personId: 11,
        type: 'image'
    },
    '14_siegfried_1996_03.jpg': {
        title: '',
        description: 'Richard Wagner: Siegfried – Jelenet a II. felvonásból – díszlet: Csikós Attila, 1996',
        personId: 11,
        type: 'image'
    },
    '15_trubadur_1996_MG_0044.JPG': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet a III. felvonás 1. képéből – díszlet: Csikós Attila, 1996',
        personId: 11,
        type: 'image'
    },
    '16_trubadur_2006_02.JPG': {
        title: '',
        description: 'Giuseppe Verdi: A trubadúr – Jelenet a IV. felvonás 1. képéből – díszlet: Csikós Attila, 2006',
        personId: 11,
        type: 'image'
    },
    '17_giselle_1996_01.jpg': {
        title: '',
        description: 'Adolphe Adam – Leonyid Lavrovszkij: Giselle – Jelenet az I. felvonásból – díszlet: Csikós Attila, 1996',
        personId: 11,
        type: 'image'
    },
    '18_falstaff_2002.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Falstaff – Jelenet a II. felvonásból – díszlet: Csikós Attila, 2002',
        personId: 11,
        type: 'image'
    },
    '19_donquijote_2012_03.JPG': {
        title: '',
        description: 'Ludwig Minkus – Marius Petipa: Don Quijote – Kozmér Alexandra (Mercedes) és Liebich Roland (Espada) – díszlet: Csikós Attila, 2012',
        personId: 11,
        type: 'image'
    },
    '20_szekelyfono_1998.jpg': {
        title: '',
        description: 'Kodály Zoltán: Székely fonó – Jelenet – díszlet: Csikós Attila, 1998',
        personId: 11,
        type: 'image'
    },
    '21_szekelyfono_1998_02.jpg': {
        title: '',
        description: 'Kodály Zoltán: Székely fonó – Zárójelenet – díszlet: Csikós Attila, 1998',
        personId: 11,
        type: 'image'
    },
    '22_istenek_1998.jpg': {
        title: '',
        description: 'Richard Wagner: Az istenek alkonya – Jelenet a III. felvonásból – díszlet: Csikós Attila, 1998',
        personId: 11,
        type: 'image'
    },
    '23_istenek_1998_02.jpg': {
        title: '',
        description: 'Richard Wagner: Az istenek alkonya – Zárójelenet – díszlet: Csikós Attila, 1998',
        personId: 11,
        type: 'image'
    },
    '24_figaro_2015_02.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Figaro házassága – A II. felvonás fináléja – díszlet: Csikós Attila, 2004',
        personId: 11,
        type: 'image'
    },
    '25_hary_1999_02.jpg': {
        title: '',
        description: 'Kodály Zoltán: Háry János – Jelenet a 3. kalandból – díszlet: Csikós Attila, 1999',
        personId: 11,
        type: 'image'
    },
    '26_carmen2000_001.jpg': {
        title: '',
        description: 'Georges Bizet: Carmen – Jelenet az I. felvonásból – díszlet: Csikós Attila, 2000',
        personId: 11,
        type: 'image'
    },
    '27_szoktetes_2002_01.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Szöktetés a szerájból – Jelenet II. felvonásnak – díszlet: Csikós Attila, 2002',
        personId: 11,
        type: 'image'
    },
    '28_vajdatornya_2003_001.jpg': {
        title: '',
        description: 'Dohnányi Ernő: A vajda tornya – Jelenet az I. felvonásból – díszlet: Csikós Attila, 2003',
        personId: 11,
        type: 'image'
    },
    '29_rigoletto_2005_03.jpg': {
        title: '',
        description: 'Giuseppe Verdi: Rigoletto – Jelenet az I. felvonásból – díszlet: Csikós Attila, 2005',
        personId: 11,
        type: 'image'
    },
    '30_arabella_2012_02.jpg': {
        title: '',
        description: 'Richard Strauss: Arabella – Jelenet a II. felvonásból – díszlet: Csikós Attila, 2012',
        personId: 11,
        type: 'image'
    },
    '01_Bolygo_978_KovacsEszter_Senta_002.jpg': {
        title: '',
        description: 'Richard Wagner: A bolygó hollandi – jelenet a II. felvonásból, Senta: Kovács Eszter – jelmez: Vágó Nelly, 1978',
        personId: 12,
        type: 'image'
    },
    '02_pillango_1983.jpg': {
        title: '',
        description: 'Giacomo Puccini: Pillangókisasszony – Cso-cso-szán: Pitti Katalin – jelmez: Vágó Nelly, 1983',
        personId: 12,
        type: 'image'
    },
    '03_pillango_1983_02.JPG': {
        title: '',
        description: 'Giacomo Puccini: Pillangókisasszony –Tokody Ilona (Cso-cso-szán) és Bartha Alfonz (Yamadori herceg) – jelmez: Vágó Nelly, 1983',
        personId: 12,
        type: 'image'
    },
    '04_romeo_1993.jpg': {
        title: '',
        description: 'Szergej Prokofjev – Seregi László: Rómeó és Júlia – Gulácsi Ágnes (Júlia dajkája) és Nagyszentpéteri Miklós (Mercutio) – jelmez: Vágó Nelly, 1995',
        personId: 12,
        type: 'image'
    },
    '05_romeo_1993_02.jpg': {
        title: '',
        description: 'Szergej Prokofjev – Seregi László: Rómeó és Júlia – Jelenet a II. felvonásból – jelmez: Vágó Nelly, 1995',
        personId: 12,
        type: 'image'
    },
    '06_Janos_vitez_1987_Lacza_A_VerebicsI_01.jpg': {
        title: '',
        description: 'Kacsoh Pongrác: János vitéz – Laczó András (Kukorica Jancsi) És Verebics Ibolya (Iluska) – jelmez: Vágó Nelly, 1987',
        personId: 12,
        type: 'image'
    },
    '07_tosca_2005.jpg': {
        title: '',
        description: 'Giacomo Puccini: Tosca – Tosca: Rálik Szilvia – jelmez: Vágó Nelly, 2005',
        personId: 12,
        type: 'image'
    },
    '08_szentivaneji_1989_02.jpg': {
        title: '',
        description: 'Felix Mendelssohn – Seregi László: Szentivánéji álom – Titánia: Volf Katalin – jelmez: Vágó Nelly, 1990',
        personId: 12,
        type: 'image'
    },
    '09_szentivaneji_1989.jpg': {
        title: '',
        description: 'Felix Mendelssohn – Seregi László: Szentivánéji álom – Oberon: Szakály György – jelmez: Vágó Nelly, 1989',
        personId: 12,
        type: 'image'
    },
    '10_Ciganybaro_1991_Melis_004.jpg': {
        title: '',
        description: 'Johann Strauss: A cigánybáró – Zsupán Kálmán: Melis György – jelmez: Vágó Nelly, 1991',
        personId: 12,
        type: 'image'
    },
    '11_Adriana_1992_058.jpg': {
        title: '',
        description: 'Francesco Cilea: Adriana Lecouvreur – Jelenet a IV. felvonásból – jelmez: Vágó Nelly, 1992',
        personId: 12,
        type: 'image'
    },
    '12_fabolfaragott_1993_02.jpg': {
        title: '',
        description: 'Bartók Béla – Fodor Antal: A fából faragott királyfi – A királyfi: Kováts Tibor – jelmez: Vágó Nelly, 1993',
        personId: 12,
        type: 'image'
    },
    '13_Mandarin_Fodor_1993_Venekei060.jpg': {
        title: '',
        description: 'Bartók Béla – Fodor Antal: A csodálatos mandarin – A lány: Venekei Marianna – jelmez: Vágó Nelly, 1993',
        personId: 12,
        type: 'image'
    },
    '14_kekszakallu_1993.jpg': {
        title: '',
        description: 'Bartók Béla: A kékszakállú herceg vára – Balatoni Éva (Judit) és Berczelly Isván (A kékszakállú herceg) – jelmez: Vágó Nelly, 1993',
        personId: 12,
        type: 'image'
    },
    '15_aida_2011_02.JPG': {
        title: '',
        description: 'Giuseppe Verdi: Aida – Bevonulási jelenet – jelmez: Vágó Nelly, 2011',
        personId: 12,
        type: 'image'
    },
    '16_dozsa_1994_.jpg': {
        title: '',
        description: 'Erkel Ferenc: Dózsa György – Csáki Laura: Pitti Katalin – jelmez: Vágó Nelly, 1994',
        personId: 12,
        type: 'image'
    },
    '17_otello_2011.JPG': {
        title: '',
        description: 'Giuseppe Verdi: Otello – Jelenet a III. felvonásból, Otello: Wendler Attila – jelmez: Vágó Nelly, 2011',
        personId: 12,
        type: 'image'
    },
    '18_Makrancos1994_Popova_Havas_002.jpg': {
        title: '',
        description: 'Goldmark Károly – Seregi László: A makrancos Kata – Popova Aleszja (Kata) és Havas Ferenc (Baptista) – jelmez: Vágó Nelly, 1994',
        personId: 12,
        type: 'image'
    },
    '19_makrancos_1994_02.jpg': {
        title: '',
        description: 'Goldmark Károly – Seregi László: A makrancos Kata -Kata: Hágai Katalin – jelmez: Vágó Nelly, 1994',
        personId: 12,
        type: 'image'
    },
    '20_walkur_1995.jpg': {
        title: '',
        description: 'Richard Wagner: A walkür – Berczelly István (Wotan) és Kasza Katalin (Brünnhilde) – jelmez: Vágó Nelly, 1995',
        personId: 12,
        type: 'image'
    },
    '21_faust_1996.jpg': {
        title: '',
        description: 'Charles Gounod: Faust – Mefisztó: Kováts Kolos – jelmez: Vágó Nelly, 1996',
        personId: 12,
        type: 'image'
    },
    '22_giselle_1996(1).jpg': {
        title: '',
        description: 'Adolphe Adam – Leonyid Lavrovszkij: Giselle – ifj. Nagy Zoltán (Albert gróf) és Volf Katalin (Giselle) – jelmez: Vágó Nelly, 1996',
        personId: 12,
        type: 'image'
    },
    '23_don_quijote_1997.jpg': {
        title: '',
        description: 'Ludwig Minkus – Marius Petipa: Don Quijote – Popova Aleszja (Kitri) és Tóth Richárd (Don Quijote) – jelmez: Vágó Nelly, 1997',
        personId: 12,
        type: 'image'
    },
    '24_Haromakislany1997_Melis_Kalmar_M_002.jpg': {
        title: '',
        description: 'Franz Schubert – Berté Henrik: Három a kislány – Melis György (Tschöll papa) és Kalmár Magda (Tschöll mama) – jelmez: Vágó Nelly, 1997',
        personId: 12,
        type: 'image'
    },
    '25_figaro_2010.jpg': {
        title: '',
        description: 'Wolfgang Amadeus Mozart: Figaro házassága – A grófné: Rost Andrea – jelmez: Vágó Nelly, 2010',
        personId: 12,
        type: 'image'
    },
    '26_fabolfaragott_1998.jpg': {
        title: '',
        description: 'Bartók Béla – Seregi László: A fából faragott királyfi – Volf Katalin (A királykisasszony), Csonka Roland (A fabáb) és ifj. Nagy Zoltán (A királyfi) – jelmez: Vágó Nelly, 1998',
        personId: 12,
        type: 'image'
    },
    '27_szekelyfono_1998_03.jpg': {
        title: '',
        description: 'Kodály Zoltán: Székely fonó - Zárójelenet – jelmez: Vágó Nelly, 1998',
        personId: 12,
        type: 'image'
    },
    '28_hary_1999_01.jpg': {
        title: '',
        description: 'Kodály Zoltán: Háry János – Örzse: Ulbich Andrea – jelmez: Vágó Nelly, 1999',
        personId: 12,
        type: 'image'
    },
    '29_seherezade_1999(2).jpg': {
        title: '',
        description: 'Nyikolaj Rimszkij-Korszakov – Harangozó Gyula: Seherezádé – Hágai Katalin (Seherezádé) és ifj. Nagy Zoltán (Az ifjú) – jelmez: Vágó Nelly, 1999',
        personId: 12,
        type: 'image'
    },
    '30_Denever_2001_Bathory_Garday_059.jpg': {
        title: '',
        description: 'Johann Strauss: A denevér – Gárday Gábor (Falke) és Bátori Éva (Rosalinda) – jelmez: Vágó Nelly, 2001',
        personId: 12,
        type: 'image'
    }
}