import React from 'react';
import Carousel from "../Carousel/Carousel";
import classes from "./SliderGame.module.scss";
import DefaultScreen from "../DefaultScreen/DefaultScreen";
import {sliderGameConfig} from "../../store/static/sliderGame";
import {useSelector} from "react-redux";
import {Rnd} from "react-rnd";

const SliderGame = () => {
    const person = useSelector(store => store.person.selectedPerson);

    return (
        <DefaultScreen>
            <Carousel showArrows={true} swipeable={false} gap="14rem">
                {sliderGameConfig['person#' + person.id].map((imagePair, i) => (
                    <div className={classes['text']} key={i}>
                        <div className={classes['overlay-container']}>
                            <img src={imagePair.after} alt=""/>
                            <Rnd enableResizing={{
                                top: false,
                                right: true,
                                bottom: false,
                                left: false,
                                topRight: false,
                                bottomRight: false,
                                bottomLeft: false,
                                topLeft: false
                            }}
                                 resizeHandleWrapperClass={classes['resize']}
                                 maxWidth={'100%'}
                                 default={{x: 4, y: 4, width: '50%', height: '100%'}}
                                 disableDragging={true}>
                                <div className={classes['overlay-container__absolute']}
                                     style={{backgroundImage: `url(${imagePair.before})`}}>
                                </div>
                            </Rnd>
                        </div>
                        <p>{imagePair.title}</p>
                    </div>))}
            </Carousel>
        </DefaultScreen>
    );
};

export default SliderGame;
