import React from 'react';
import classes from './Image.module.scss';

const Image = ({file}) => (
    <div className={classes['image']}>
        <img src={file.devicePath} alt=""/>
    </div>
);

export default Image;
