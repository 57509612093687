import {SCREENSIZES} from "../../utils/constants";

export const hotspotContainerConfig = {
    [SCREENSIZES.LARGE1]: [
        {
            id: 1,
            bgImagePath: '/assets/hotspot/img-magyar-abrandok.jpg',
            title: 'Liszt Ferenc – Jan Ciepliński: Magyar ábrándok – zárójelenet',
            description: <>A Liszt Ferenc-zenékből összeállított háromtételes balettet 1933-ban mutatta be az Operaház a
                lengyel Jan Ciepliński koreográfiájában, Fülöp Zoltán díszleteiben és jelmezeiben. A képen látható 3.
                kép cselekményét így írta le a korabeli beszámoló: <i>„A mézeskalácsos sátornál legények és lányok
                    hancúroznak boldogan. Minden lánynak van párja, aki szép, cifra szívet vesz neki, csak a kis
                    cigánylánynak nincs senkije és a boldog szerelmesek bántják és csúfolják az árvát. A szegény
                    cigányleány halálos nagy vágya egy szív után végre is csodát tesz: egy mézeskalácskenyér
                    megelevenedik és szívet ad neki és magával viszi a boldog mézeskalács országba.”</i></>,
        },
        {
            id: 2,
            bgImagePath: '/assets/hotspot/img-harom-a-kislany.jpg',
            title: 'Franz Schubert – Berté Henrik: Három a kislány – nyitójelenet',
            description: 'Berté Henrik Franz Schubert zenéiből összeállított, az osztrák zeneszerző életét feldolgozó operettjét 1916-ban mutatták be Bécsben, majd Budapesten. A Városi (ma Erkel) Színház 1951-ben Nádasdy Kálmán rendezésében, Fülöp Zoltán realista díszleteiben és Márk Tivadar jelmezeiben mutatta be a daljátékot, mely hosszú évekig a közönség kedvence maradt. Az I. felvonás nyitójelenete egy bécsi ház udvarának mindennapjaiba varázsolja a nézőket.'
        }
    ],
    [SCREENSIZES.LARGE2]: [
        {
            id: 1,
            bgImagePath: '/assets/hotspot/img-turandot.jpg',
            title: <>Giacomo Puccini: <i>Turandot</i> – a II. felvonás 2. képe</>,
            description: <>Giacomo Puccini születésének centenáriumán, 1958 decemberében újította fel az Operaház az
                olasz komponista egyik legnépszerűbb operáját, a színház repertoárjáról régóta hiányzó <i>Turandot</i>ot.
                A produkciót Nádasdy Kálmán és Mikó András rendezte, a díszleteket Fülöp Zoltán, a jelmezeket Márk
                Tivadar tervezte. Az előadást harminc éven keresztül, közel 200-szor láthatta az Erkel Színház
                közönsége. A <i>Turandot</i> központi jelenete az úgynevezett nagykép, ahol másfél felvonásnyi várakozás
                után végre megszólal a címszereplő. A grandiózus jelenet minden tervezőnek, színpadi és műszaki
                közreműködőnek hatalmas kihívás.</>,
        },
        {
            id: 2,
            bgImagePath: '/assets/hotspot/img-tosca1.jpg',
            title: <>Giacomo Puccini: <i>Tosca</i> – jelenet a II. felvonásból</>,
            description: <>A <i>Tosca</i> igen izgalmas II. felvonásának központi jelenetében Bonaparte Napóleon
                marengói győzelme hírére a megkínzott, félájult Cavaradossi Scarpia képébe vágja: <i>„Zsarnok!”</i> A
                festő kitörése igencsak ellentétes reakciókat vált ki a jelenlévőkből: Tosca felismeri, hogy kedvese
                ezzel aláírta a halálos ítéletét, a rendőrminiszter már intézkedik is a bebörtönzéséről, míg Scarpia
                beosztottjai ugrásra készen várják, hogy elhurcolhassák a festőt. A <i>Tosca</i> 1988 óta Nagy Viktor
                klasszikus rendezésében, Vayer Tamás díszleteiben, Vágó Nelly jelmezeiben közel 300 előadást ért meg.</>
        }
    ],
    [SCREENSIZES.LARGE3]: [
        {
            id: 1,
            bgImagePath: '/assets/hotspot/img-kaloz.jpg',
            title: <>Adolphe Adam – Anna-Marie Holmes – Solymosi Tamás:<br/> <i>A kalóz</i> – jelenet az I.
                felvonásból</>,
            description: <><i>A kalóz</i> egyike a legrégebbi klasszikus baletteknek, mely számos átdolgozásban ma is
                része a
                nagy balettegyüttesek repertoárjának. A Magyar Nemzeti Balett 2017-es előadása méltán aratott nagy
                közönségsikert. Rózsa István díszletei és Rományi Nóra jelmezei keleti mesevilágba repítik a nézőket. Az
                I. felvonás egy bazárban játszódik, ahol Lankendem árulja rabszolgalányait. Amikor megérkezik Seyd pasa,
                a kormányzó, a rabszolgakereskedő három lányt mutat neki. A pasának azonban egyik sem tetszik. Ekkor
                Lankendem egy látványos <i>pas
                    de deux</i> keretén belül bemutatja a rejtélyes Gulnarét. Seyd azonnal megveszi a nőt.</>,
        },
        {
            id: 2,
            bgImagePath: '/assets/hotspot/img-figaro.jpg',
            title: <>Gioachino Rossini – Wolfgang Amadeus Mozart – Darius Milhaud: <i>Figaro3</i> – jelenet</>,
            description: <>A <i>Figaro3</i> című, 2021 júniusában bemutatott „opera-mix” három zeneszerző három operája
                egy
                estén. Beaumarchais <i>Figaro</i>-trilógiája Rossini és Mozart operáján kívül Darius Milhaud-t is
                megihlette. A
                produkcióban Dömötör András és Litkai Gergely sajátos feldolgozása látható, mely a drámatrilógiából
                Rosina és Almaviva történetét teszi meg középpontjául. Sosemvolt lehetőség nyílik az operaénekesek
                számára: végre végigvihetik szerepeiket a teljes trilógián. A „kortárs operához” természetesen friss
                szellemiségű produkció dukál (rendező: Dömötör András, díszlettervező: Horváth Jenny, jelmeztervező:
                Nagy Fruzsina).</>
        }
    ],
}
