import React from 'react';
import classes from './Hotspot.module.scss';

const Hotspot = ({isOpen, position, onClick}) => {
    const classList = isOpen ? [classes['hotspot-container'], classes['hotspot-container--is-open']] : [classes['hotspot-container']];

    return (
        <div className={classList.join(' ')} onClick={onClick} style={{top: position.y, left: position.x}}>
            <div className={classes['pulse']}></div>
            <div className={classes['pulse']}></div>
            <img className={classes['hotspot']} src={'/assets/hotspot-close.svg'} alt=""/>
        </div>
    );
};

export default Hotspot;
