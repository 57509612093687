import React, {useState} from 'react';
import {IonButton, IonContent, IonLabel, IonPage, IonSelect, IonSelectOption} from '@ionic/react';
import {useDispatch} from "react-redux";
import {setSelectedScreen} from "../../store/slices/screen";
import {useHistory} from "react-router";
import Center from "../../hoc/Center/Center";
import {MEDIUM_SCREENS, SCREENSELECTOPTIONS} from "../../utils/constants";
import {isSizedScreen} from "../../utils/functions/screen";


const ScreenSelect = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selected, setSelected] = useState(false);

    const updateSelectedScreen = (e) => {
        setSelected(e.detail.value);
    }

    const onMoveToSyncClick = () => {
        dispatch(setSelectedScreen(selected));
        if (isSizedScreen(selected, MEDIUM_SCREENS)) {
            history.push('/medium-home')
        }
        else {
            history.push('/home');
        }
        //history.push('/sync');
    }

    return (
        <IonPage>
            <IonContent>
                <Center>
                    <div>
                        <IonLabel>Aktív Képernyő:</IonLabel>
                        <IonSelect value={selected} okText="OK" cancelText="Mégse"
                                   onIonChange={updateSelectedScreen}>
                            {SCREENSELECTOPTIONS.map(option => (
                                <IonSelectOption key={option.value} value={option.value}>
                                    {option.displayName}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                        {selected && (<IonButton onClick={onMoveToSyncClick}>
                            Tovább
                        </IonButton>)}
                    </div>
                </Center>
            </IonContent>
        </IonPage>
    );
};

export default ScreenSelect;
