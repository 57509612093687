import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {configureStore} from "@reduxjs/toolkit";
import {Provider} from "react-redux";
import screenReducer from './store/slices/screen.js';
import fileReducer from './store/slices/files.js';
import contentReducer from './store/slices/content';
import personReducer from './store/slices/person';
import {IonReactRouter} from "@ionic/react-router";

const store = configureStore({
    reducer: {
        screen: screenReducer,
        files: fileReducer,
        content: contentReducer,
        person: personReducer
    },
})

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <IonReactRouter>
                <App/>
            </IonReactRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
