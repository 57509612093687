import React from 'react';
import {useHistory} from "react-router";
import Row from "../../hoc/Row/Row";
import classes from './PersonSelect.module.scss';
import {useDispatch} from "react-redux";
import {setSelectedPerson} from "../../store/slices/person";

const PersonSelect = ({people}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onPersonClick = (person) => {
        dispatch(setSelectedPerson(person));
        history.push(person.to)
    }

    const getPersonCard = (person, i) => (
        <div key={i} className={classes['card']} onClick={() => onPersonClick(person)}>
            <img src={person.imagePath} alt=""/>
            <div className={classes['card__name']}><strong>{person.fname}</strong> {person.lname}</div>
        </div>
    )

    return (
        <Row justify={'space-evenly'} width={`repeat(${people.length}, auto)`} align={'center'}>
            {people.map((p, i) => getPersonCard(p, i))}
        </Row>
    );
};

export default PersonSelect;
