import React from 'react';
import BgImage from "../../hoc/BgImage/BgImage";
import Padded from "../../hoc/Padded/Padded";
import WithBackButton from "../../hoc/WithBackButton/WithBackButton";

const MediumSidePanel = ({bgColor, imagePath, children}) => {
    return (
        <div style={{backgroundColor: bgColor, height: '100%'}}>
            <BgImage position={'left 47vh'} size={'30vw auto'} imagePath={'/assets/sello-white.svg'}
                     fullHeight={true}>
                <Padded fullHeight={true}>
                    <div>
                        <WithBackButton>
                            <img alt='' src={imagePath}/>
                            {children}
                        </WithBackButton>
                    </div>
                </Padded>
            </BgImage>
        </div>
    );
};

export default MediumSidePanel;
