export const sliderGameConfig = {
    'person#7': [
        {
            title: 'Bartók Béla – Jan Ciepliński: A fából faragott királyfi, 1935 – Forrás: OSZK Színháztörténeti Tár',
            before: '/assets/slidergame/medium4/01a_fulop_fabolfaragott_1935_oszk.jpg',
            after: '/assets/slidergame/medium4/01b_fabolfaragott_1935.jpg'
        },
        {
            title: 'Lajtha László – Brada Rezső: Lysistrata, 1937 – Forrás: OSZK Színháztörténeti Tár',
            before: '/assets/slidergame/medium4/02a_fulop_lysistrata_1937__oszk.jpg',
            after: '/assets/slidergame/medium4/02b_fulop_lysistrata_1937__oszk.jpg'
        },
        {
            title: 'Richard Strauss: Salome, 1940',
            before: '/assets/slidergame/medium4/03a_fulop_salome_1940.jpg',
            after: '/assets/slidergame/medium4/03b_fulop_salome_1940.jpg'
        },
        {
            title: 'Richard Wagner: A walkür, 1942',
            before: '/assets/slidergame/medium4/04a_fulop_rajnakincse_1940_02.jpg',
            after: '/assets/slidergame/medium4/04b_walkur_1942.jpg'
        },
        {
            title: 'Benjamin Britten: Peter Grimes – II. felvonás 2. kép, 1947',
            before: '/assets/slidergame/medium4/05aPeterGrimes1947terve_002.jpg',
            after: '/assets/slidergame/medium4/05bPeterGrimes_1948_2felv106.jpg'
        },
        {
            title: 'Otto Nicolai: A windsori víg nők – II. felvonás 1. kép, 1948',
            before: '/assets/slidergame/medium4/06a_fulop_windsori_1948_02.jpg',
            after: '/assets/slidergame/medium4/06b_fulop_windsori_1948_02.jpg'
        },
        {
            title: 'Stanisław Moniuszko: Halka – III. felvonás, 1952',
            before: '/assets/slidergame/medium4/07a.Halka_terve_001.jpg',
            after: '/assets/slidergame/medium4/07bHalka 1952_001.jpg'
        },
        {
            title: 'Richard Wagner: Trisztán és Izolda – III. felvonás, 1959',
            before: '/assets/slidergame/medium4/08a.Trisztan_terve_004.jpg',
            after: '/assets/slidergame/medium4/08b.Trisztan1959_05.jpg'
        },
        {
            title: 'Giacomo Puccini: Manon Lescaut – I. felvonás, 1961',
            before: '/assets/slidergame/medium4/09a.Manon-Lescaut_terve_001.jpg',
            after: '/assets/slidergame/medium4/09b._Manon_002.jpg'
        },
        {
            title: 'Szokolay Sándor: Vérnász, 1964',
            before: '/assets/slidergame/medium4/10a_fulop_Vernasz_1964_makett.jpg',
            after: '/assets/slidergame/medium4/10b_fulop_Vernasz_1964_makett.jpg'
        },
        {
            title: 'Richard Wagner: Tannhäuser – II. felvonás, 1967',
            before: '/assets/slidergame/medium4/11a_tannhauser_67.jpg',
            after: '/assets/slidergame/medium4/11b_tannhauser1967_fulop_makett.jpg'
        }
    ]
}
