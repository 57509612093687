import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import GalleryNavButton from "../GalleryNavButton/GalleryNavButton";
import classes from './Carousel.module.scss';

const CustomCarousel = ({children, onChange, gap, ...rest}) => {
    let classList = [classes['custom-carousel']];
    if (gap) classList.push(classes['custom-carousel--gap-14']);

    return (
        <Carousel className={classList.join(' ')} interval={99999999999}
                  infiniteLoop={true}
                  showStatus={false} autoPlay={false}
                  showIndicators={false} showThumbs={false}
                  {...rest}
                  renderArrowPrev={(onClick) => rest.showArrows ? <GalleryNavButton type={'prev'} action={onClick}/> : null}
                  renderArrowNext={(onClick) => rest.showArrows ? <GalleryNavButton type={'next'} action={onClick}/> : null}
                  onChange={onChange ?? (() => {
                  })}
        >
            {children}
        </Carousel>
    );
};

export default CustomCarousel;
