import React from 'react';

const Row = ({width, justify, align, children, gap}) => {

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: width,
            justifyContent: justify,
            alignItems: align,
            gridGap: gap ?? 0
        }}>
            {children}
        </div>
    );
};

export default Row;
