import {SCREENSIZES} from "../../utils/constants";

export const hotspots = {
    [SCREENSIZES.LARGE1]: {
        'hotspotContainer#1': [
            {
                popupId: 1,
                position: {
                    x: '11.5rem',
                    y: '30.5rem'
                }
            },
            {
                popupId: 2,
                position: {
                    x: '19rem',
                    y: '4rem'
                }
            },
            {
                popupId: 3,
                position: {
                    x: '27rem',
                    y: '18rem'
                }
            },
            {
                popupId: 4,
                position: {
                    x: '31rem',
                    y: '20rem'
                }
            },
            {
                popupId: 5,
                position: {
                    x: '36rem',
                    y: '22rem'
                }
            },
            {
                popupId: 6,
                position: {
                    x: '46rem',
                    y: '25rem'
                }
            },
            {
                popupId: 7,
                position: {
                    x: '57rem',
                    y: '28rem'
                }
            },
            {
                popupId: 8,
                position: {
                    x: '57rem',
                    y: '15rem'
                }
            },
        ],
        'hotspotContainer#2': [
            {
                popupId: 9,
                position: {
                    x: '4rem',
                    y: '14rem'
                }
            },
            {
                popupId: 10,
                position: {
                    x: '10rem',
                    y: '28rem'
                }
            },
            {
                popupId: 11,
                position: {
                    x: '20rem',
                    y: '31rem'
                }
            },
            {
                popupId: 12,
                position: {
                    x: '25rem',
                    y: '27rem'
                }
            },
            {
                popupId: 13,
                position: {
                    x: '26rem',
                    y: '15rem'
                }
            },
            {
                popupId: 14,
                position: {
                    x: '29rem',
                    y: '33rem'
                }
            },
            {
                popupId: 15,
                position: {
                    x: '34rem',
                    y: '12rem'
                }
            },
            {
                popupId: 16,
                position: {
                    x: '35rem',
                    y: '25rem'
                }
            },
            {
                popupId: 17,
                position: {
                    x: '35rem',
                    y: '29rem'
                }
            },
            {
                popupId: 18,
                position: {
                    x: '41rem',
                    y: '27rem'
                }
            },
        ]
    },
    [SCREENSIZES.LARGE2]: {
        'hotspotContainer#1': [
            {
                popupId: 19,
                position: {
                    x: '8rem',
                    y: '19rem'
                }
            },
            {
                popupId: 20,
                position: {
                    x: '11rem',
                    y: '7rem'
                }
            },
            {
                popupId: 21,
                position: {
                    x: '18rem',
                    y: '30rem'
                }
            },
            {
                popupId: 22,
                position: {
                    x: '22rem',
                    y: '24rem'
                }
            },
            {
                popupId: 23,
                position: {
                    x: '30rem',
                    y: '9rem'
                }
            },
            {
                popupId: 24,
                position: {
                    x: '30rem',
                    y: '20rem'
                }
            },
            {
                popupId: 25,
                position: {
                    x: '29rem',
                    y: '27rem'
                }
            },
            {
                popupId: 26,
                position: {
                    x: '30rem',
                    y: '32rem'
                }
            },
            {
                popupId: 27,
                position: {
                    x: '34rem',
                    y: '16rem'
                }
            },
            {
                popupId: 28,
                position: {
                    x: '41rem',
                    y: '30rem'
                }
            },
            {
                popupId: 29,
                position: {
                    x: '46rem',
                    y: '12rem'
                }
            },
            {
                popupId: 30,
                position: {
                    x: '47rem',
                    y: '22rem'
                }
            },
            {
                popupId: 31,
                position: {
                    x: '53rem',
                    y: '10rem'
                }
            },
            {
                popupId: 32,
                position: {
                    x: '56rem',
                    y: '32rem'
                }
            },
        ],
        'hotspotContainer#2': [
            {
                popupId: 33,
                position: {
                    x: '10rem',
                    y: '29rem'
                }
            },
            {
                popupId: 34,
                position: {
                    x: '11rem',
                    y: '24rem'
                }
            },
            {
                popupId: 35,
                position: {
                    x: '17rem',
                    y: '26rem'
                }
            },
            {
                popupId: 36,
                position: {
                    x: '23rem',
                    y: '22rem'
                }
            },
            {
                popupId: 37,
                position: {
                    x: '26rem',
                    y: '28rem'
                }
            },
            {
                popupId: 38,
                position: {
                    x: '28rem',
                    y: '23rem'
                }
            },
            {
                popupId: 39,
                position: {
                    x: '31rem',
                    y: '6rem'
                }
            },
            {
                popupId: 40,
                position: {
                    x: '35rem',
                    y: '32rem'
                }
            },
            {
                popupId: 41,
                position: {
                    x: '41rem',
                    y: '24rem'
                }
            },
            {
                popupId: 42,
                position: {
                    x: '47rem',
                    y: '29rem'
                }
            },
            {
                popupId: 43,
                position: {
                    x: '51rem',
                    y: '13rem'
                }
            },
        ],
    },
    [SCREENSIZES.LARGE3]: {
        'hotspotContainer#1': [
            {
                popupId: 44,
                position: {
                    x: '7rem',
                    y: '29rem'
                }
            },
            {
                popupId: 45,
                position: {
                    x: '14rem',
                    y: '23rem'
                }
            },
            {
                popupId: 46,
                position: {
                    x: '17rem',
                    y: '24rem'
                }
            },
            {
                popupId: 47,
                position: {
                    x: '18rem',
                    y: '33rem'
                }
            },
            {
                popupId: 48,
                position: {
                    x: '25rem',
                    y: '4rem'
                }
            },
            {
                popupId: 49,
                position: {
                    x: '28rem',
                    y: '31rem'
                }
            },
            {
                popupId: 50,
                position: {
                    x: '28rem',
                    y: '28rem'
                }
            },
            {
                popupId: 51,
                position: {
                    x: '31rem',
                    y: '13rem'
                }
            },
            {
                popupId: 52,
                position: {
                    x: '32rem',
                    y: '25rem'
                }
            },
            {
                popupId: 53,
                position: {
                    x: '36rem',
                    y: '21rem'
                }
            },
            {
                popupId: 54,
                position: {
                    x: '44rem',
                    y: '30rem'
                }
            },
            {
                popupId: 55,
                position: {
                    x: '45rem',
                    y: '24rem'
                }
            },
            {
                popupId: 56,
                position: {
                    x: '50rem',
                    y: '19rem'
                }
            },
        ],
        'hotspotContainer#2': [
            {
                popupId: 57,
                position: {
                    x: '10rem',
                    y: '23rem'
                }
            },
            {
                popupId: 58,
                position: {
                    x: '18rem',
                    y: '9rem'
                }
            },
            {
                popupId: 59,
                position: {
                    x: '19rem',
                    y: '22rem'
                }
            },
            {
                popupId: 60,
                position: {
                    x: '46rem',
                    y: '7rem'
                }
            },
            {
                popupId: 61,
                position: {
                    x: '55rem',
                    y: '28rem'
                }
            },
        ],
    }
}
