import React from 'react';
import PersonSelect from "../../components/PersonSelect/PersonSelect";
import {useSelector} from "react-redux";
import {people} from "../../store/static/person";
import BgImage from "../../hoc/BgImage/BgImage";
import Valign from "../../hoc/Valign/Valign";
import WithBackButton from "../../hoc/WithBackButton/WithBackButton";

const People = () => {
    const screen = useSelector(store => store.screen.selectedScreen);

    return (
        <BgImage fullHeight={true} imagePath='/assets/main-bg-screen4.jpg'>
            <WithBackButton>
                <Valign align={'center'}>
                    <PersonSelect people={people.filter(p => p.screen === screen)}/>
                </Valign>
            </WithBackButton>
        </BgImage>
    );
};

export default People;
