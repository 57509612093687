import {SCREENSIZES} from "../../utils/constants";

export const people = [
    {
        id: 1,
        screen: SCREENSIZES.MEDIUM1,
        fname: 'SPANNRAFT',
        lname: 'ÁGOSTON',
        imagePath: '/assets/people/spannraft.png',
        to: '/sub-select'
    },
    {
        id: 2,
        screen: SCREENSIZES.MEDIUM1,
        fname: 'KÉMÉNDY',
        lname: 'JENŐ',
        imagePath: '/assets/people/kemendy.png',
        to: '/sub-select'
    },
    {
        id: 3,
        screen: SCREENSIZES.MEDIUM2,
        fname: 'Bánffy',
        lname: 'Miklós',
        imagePath: '/assets/people/banffy.png',
        to: '/sub-select'
    },
    {
        id: 4,
        screen: SCREENSIZES.MEDIUM2,
        fname: 'Márkus',
        lname: 'László',
        imagePath: '/assets/people/markus.png',
        to: '/sub-select'
    },
    {
        id: 5,
        screen: SCREENSIZES.MEDIUM3,
        fname: 'Oláh',
        lname: 'Gusztáv',
        imagePath: '/assets/people/olah.png',
        to: '/sub-select'
    },
    {
        id: 6,
        screen: SCREENSIZES.MEDIUM3,
        fname: 'Márk',
        lname: 'Tivadar',
        imagePath: '/assets/people/mark.png',
        to: '/sub-select'
    },
    {
        id: 7,
        screen: SCREENSIZES.MEDIUM4,
        fname: 'Fülöp',
        lname: 'Zoltán',
        imagePath: '/assets/people/fulop.png',
        to: '/sub-select'
    },
    {
        id: 8,
        screen: SCREENSIZES.MEDIUM4,
        fname: 'Forray',
        lname: 'Gábor',
        imagePath: '/assets/people/forray.png',
        to: '/sub-select'
    },
    {
        id: 9,
        screen: SCREENSIZES.MEDIUM4,
        fname: 'Schaffer',
        lname: 'Judit',
        imagePath: '/assets/people/schaffer.png',
        to: '/sub-select'
    },
    {
        id: 10,
        screen: SCREENSIZES.MEDIUM5,
        fname: 'Makai',
        lname: 'Péter',
        imagePath: '/assets/people/makai.png',
        to: '/sub-select'
    },
    {
        id: 11,
        screen: SCREENSIZES.MEDIUM5,
        fname: 'Csikós',
        lname: 'Attila',
        imagePath: '/assets/people/csikos.png',
        to: '/sub-select'
    },
    {
        id: 12,
        screen: SCREENSIZES.MEDIUM5,
        fname: 'Vágó',
        lname: 'Nelly',
        imagePath: '/assets/people/vago.png',
        to: '/sub-select'
    },
]
