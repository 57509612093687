export const defaultFiles = [
    {
        devicePath: '/assets/defaults/mark/MFH_1951_49_3.mp4',
        name: 'MFH_1951_49_3.mp4'
    },
    {
        devicePath: '/assets/defaults/mark/MFH_1958_9_2.mp4',
        name: 'MFH_1958_9_2.mp4'
    },
    {
        devicePath: '/assets/defaults/fulop/MFH_1956_23_3.mp4',
        name: 'MFH_1956_23_3.mp4'
    },
    {
        devicePath: '/assets/defaults/fulop/MFH_1961_04_7.mp4',
        name: 'MFH_1961_04_7.mp4'
    },
    {
        devicePath: '/assets/defaults/schaffer/MFH_1986_50.mp4',
        name: 'MFH_1986_50.mp4'
    },
    {
        devicePath: '/assets/defaults/spannraft/01_spannraft_bankban_1884.jpg',
        name: '01_spannraft_bankban_1884.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/02_Maleczkyne_Bank_ban.jpg',
        name: '02_Maleczkyne_Bank_ban.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/03_spannraft_aprofeta_1885.jpg',
        name: '03_spannraft_aprofeta_1885.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/04_Broulik_DSC_2921bs.jpg',
        name: '04_Broulik_DSC_2921bs.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/05_spannraft-tannhauser_1885_002.jpg',
        name: '05_spannraft-tannhauser_1885_002.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/06_spannraft_tannhauser_1885_001.jpg',
        name: '06_spannraft_tannhauser_1885_001.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/07_spannraft-tannhauser_1885_003.jpg',
        name: '07_spannraft-tannhauser_1885_003.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/08_tannh_jelmez002.jpg',
        name: '08_tannh_jelmez002.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/09_tannh_jelmez003.jpg',
        name: '09_tannh_jelmez003.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/10_tannh_jelmez004.jpg',
        name: '10_tannh_jelmez004.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/11_spannraft_aida_002.jpg',
        name: '11_spannraft_aida_002.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/12_spannraft_aida_001.jpg',
        name: '12_spannraft_aida_001.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/13_kellek_aida_004.jpg',
        name: '13_kellek_aida_004.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/14_aida04.jpg',
        name: '14_aida04.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/15_spannraft_rigoletto1885.jpg',
        name: '15_spannraft_rigoletto1885.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/16_spannraft_sackingenitrombitas_1886.jpg',
        name: '16_spannraft_sackingenitrombitas_1886.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/17_A_sackingeni_trombitas_1886_Marie1_001.jpg',
        name: '17_A_sackingeni_trombitas_1886_Marie1_001.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/18_A_sackingeni_trombitas_1886_Damian_001.jpg',
        name: '18_A_sackingeni_trombitas_1886_Damian_001.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/19_SpannraftAbencerage_002.jpg',
        name: '19_SpannraftAbencerage_002.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/20_Abencerage1887_004.jpg',
        name: '20_Abencerage1887_004.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/21_spannraft_abolygohollandi_1888.jpg',
        name: '21_spannraft_abolygohollandi_1888.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/22_Bolygo_hollandi1888_Ahollandi-Bandiss_Oszkar_figurinje002.jpg',
        name: '22_Bolygo_hollandi1888_Ahollandi-Bandiss_Oszkar_figurinje002.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/23_spannraft_rajnakincse1889_oszk.JPG',
        name: '23_spannraft_rajnakincse1889_oszk.JPG'
    },
    {
        devicePath: '/assets/defaults/spannraft/24_Rajnakincse_Sello_02_.jpg',
        name: '24_Rajnakincse_Sello_02_.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/25_Awalker_1889_Spannraftterv_001.jpg',
        name: '25_Awalker_1889_Spannraftterv_001.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/26_Awalker_Wotan-jelmezterv-1887.jpg',
        name: '26_Awalker_Wotan-jelmezterv-1887.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/27_Walker_Ortlinde.jpg',
        name: '27_Walker_Ortlinde.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/28_spannraft_toldi_1893.jpg',
        name: '28_spannraft_toldi_1893.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/29_Toldiszerelme_001.jpg',
        name: '29_Toldiszerelme_001.jpg'
    },
    {
        devicePath: '/assets/defaults/spannraft/30_spannraft_andrechenier_1893_oszkKF239.jpg',
        name: '30_spannraft_andrechenier_1893_oszkKF239.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/01_BertsMimi_Trisztan_Brangene_01.jpg',
        name: '01_BertsMimi_Trisztan_Brangene_01.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/02_Glatz_1902.jpg',
        name: '02_Glatz_1902.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/03_Glatz1_1902.jpg',
        name: '03_Glatz1_1902.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/04_SzoyerI_Gabor_Csavargo_es_a_kiralylany_1903.jpg',
        name: '04_SzoyerI_Gabor_Csavargo_es_a_kiralylany_1903.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/05_KrammerTerez_Tosca_1903.jpg',
        name: '05_KrammerTerez_Tosca_1903.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/06_Gemma_DalnokiV_MarkusE_1904.jpg',
        name: '06_Gemma_DalnokiV_MarkusE_1904.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/07_AnthesGyorgy_Samson_1904.jpg',
        name: '07_AnthesGyorgy_Samson_1904.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/08_Szamosi_Samson_1904.jpg',
        name: '08_Szamosi_Samson_1904.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/09_Flattna_Nemo1905.jpg',
        name: '09_Flattna_Nemo1905.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/10_Gabor_Jozsef_pillango.jpg',
        name: '10_Gabor_Jozsef_pillango.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/11_Pillango_1906_Varady_Margit_Szuzuki.jpg',
        name: '11_Pillango_1906_Varady_Margit_Szuzuki.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/12_kemendy_elektra1910_001.jpg',
        name: '12_kemendy_elektra1910_001.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/13_KrammerTerez_Elektra1910.jpg',
        name: '13_KrammerTerez_Elektra1910.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/14_Paolo_es_Francesca_1912_Szekelyhidy_ferenc_MedekAnna_003.jpg',
        name: '14_Paolo_es_Francesca_1912_Szekelyhidy_ferenc_MedekAnna_003.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/15_Paolo_es_Francesca_1912_MedekAnna_Szekelyhidy_Ferenc_003.jpg',
        name: '15_Paolo_es_Francesca_1912_MedekAnna_Szekelyhidy_Ferenc_003.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/16_Nyugat_lanya_1912_01.jpg',
        name: '16_Nyugat_lanya_1912_01.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/17_Nyugat_lanya_1912_03.jpg',
        name: '17_Nyugat_lanya_1912_03.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/18_Rodosto_Sandor_Erzsi_1912_001.jpg',
        name: '18_Rodosto_Sandor_Erzsi_1912_001.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/19_Kiraly_es_a_kiralylany_SandorErzsi_Szemere_Arpad_1912.jpg',
        name: '19_Kiraly_es_a_kiralylany_SandorErzsi_Szemere_Arpad_1912.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/20_Salome_1912_01.jpg',
        name: '20_Salome_1912_01.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/21_Domotor_Ilona_Gabor_Jozsef_Boccaccio_1913.jpg',
        name: '21_Domotor_Ilona_Gabor_Jozsef_Boccaccio_1913.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/22_Galafor_es_Pierrette_1913.jpg',
        name: '22_Galafor_es_Pierrette_1913.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/23_Venczell_Balazs_saiba_kiralyneja_1916.jpg',
        name: '23_Venczell_Balazs_saiba_kiralyneja_1916.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/24_Aholtszemek1921_001.jpg',
        name: '24_Aholtszemek1921_001.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/25_Walter_Rozsi_Thais1924_001.jpg',
        name: '25_Walter_Rozsi_Thais1924_001.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/26_Carmen1929_1fv_002.jpg',
        name: '26_Carmen1929_1fv_002.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/27_Carmen1929_2fv_003.jpg',
        name: '27_Carmen1929_2fv_003.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/28_Carmen1929_3fv_004.jpg',
        name: '28_Carmen1929_3fv_004.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/29_Carmen1929_4fv_006.jpg',
        name: '29_Carmen1929_4fv_006.jpg'
    },
    {
        devicePath: '/assets/defaults/kemendy/30_Siegfried_Kemendy_J_sarkanya_001.jpg',
        name: '30_Siegfried_Kemendy_J_sarkanya_001.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/01.Varazs_MagyarIparmuveszet_1913__pages170-170_page-0001.jpg',
        name: '01.Varazs_MagyarIparmuveszet_1913__pages170-170_page-0001.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/02.varazsfuvola_venczellbelajelmeze.jpg',
        name: '02.varazsfuvola_venczellbelajelmeze.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/03.Szoktetes_MagyarIparmuveszet_1913__pages169-169_page-0001.jpg',
        name: '03.Szoktetes_MagyarIparmuveszet_1913__pages169-169_page-0001.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/04.Szoktetes_Hevesi001.jpg',
        name: '04.Szoktetes_Hevesi001.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/05.Szoktetes_Hevesi002.jpg',
        name: '05.Szoktetes_Hevesi002.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/06.szoktetes001.jpg',
        name: '06.szoktetes001.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/07.Oberon1_MagyarIparmuveszet_1914__pages13-13_page-0001.jpg',
        name: '07.Oberon1_MagyarIparmuveszet_1914__pages13-13_page-0001.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/08.Oberon2_MagyarIparmuveszet_1914__pages33-33_page-0001.jpg',
        name: '08.Oberon2_MagyarIparmuveszet_1914__pages33-33_page-0001.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/09.infansno_1918_01.jpg',
        name: '09.infansno_1918_01.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/10.infansno_1918_02.jpg',
        name: '10.infansno_1918_02.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/11_kekszakallu_szinhazielet_cikk_01.JPG',
        name: '11_kekszakallu_szinhazielet_cikk_01.JPG'
    },
    {
        devicePath: '/assets/defaults/banffy/12_kekszakallu_szinhazielet_cikk_02.JPG',
        name: '12_kekszakallu_szinhazielet_cikk_02.JPG'
    },
    {
        devicePath: '/assets/defaults/banffy/13_kekszakallu_szinhazielet_cikk_03.JPG',
        name: '13_kekszakallu_szinhazielet_cikk_03.JPG'
    },
    {
        devicePath: '/assets/defaults/banffy/14.donjuan_1917_02.jpg',
        name: '14.donjuan_1917_02.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/15.donjuan_1917_01.jpg',
        name: '15.donjuan_1917_01.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/16.Hoffmannmesei1931_07.jpg',
        name: '16.Hoffmannmesei1931_07.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/17.Hoffmannmesei1931_03.jpg',
        name: '17.Hoffmannmesei1931_03.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/18.Hoffmannmesei1931_01.jpg',
        name: '18.Hoffmannmesei1931_01.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/19..trubadur_003.jpg',
        name: '19..trubadur_003.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/20.trubadur_008.jpg',
        name: '20.trubadur_008.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/21.Trubadur_Hevesi_Banffy002.jpg',
        name: '21.Trubadur_Hevesi_Banffy002.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/22.trubadur_007.jpg',
        name: '22.trubadur_007.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/23.Trubadur_1912.jpg',
        name: '23.Trubadur_1912.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/24trubadur_005.jpg',
        name: '24trubadur_005.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/25.Aida_01.jpg',
        name: '25.Aida_01.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/26.Aida_02.jpg',
        name: '26.Aida_02.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/27.Aida_03.jpg',
        name: '27.Aida_03.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/28.aida_04.jpg',
        name: '28.aida_04.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/29.aida_07.jpg',
        name: '29.aida_07.jpg'
    },
    {
        devicePath: '/assets/defaults/banffy/30.aida_03.jpg',
        name: '30.aida_03.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/01.Farsangilakodalom1931_005.jpg',
        name: '01.Farsangilakodalom1931_005.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/02_farsangilakodalom_markus_01.jpg',
        name: '02_farsangilakodalom_markus_01.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/03_farsangilakodalom_markus_02.jpg',
        name: '03_farsangilakodalom_markus_02.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/04_Farsangilakodalom_NmethyElla_SebeAnna004.jpg',
        name: '04_Farsangilakodalom_NmethyElla_SebeAnna004.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/05_Farsangi_bemutat.jpg',
        name: '05_Farsangi_bemutat.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/06_parsifal_total_01.jpg',
        name: '06_parsifal_total_01.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/07_parsifal_total_02.jpg',
        name: '07_parsifal_total_02.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/08_KA5678_4.jpg',
        name: '08_KA5678_4.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/09_parsifal_total_03.jpg',
        name: '09_parsifal_total_03.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/10_ZodszkyZolt_Parsifal_001.jpg',
        name: '10_ZodszkyZolt_Parsifal_001.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/11_TutsekPiroska_Parsifal-1939_Kundry1.jpg',
        name: '11_TutsekPiroska_Parsifal-1939_Kundry1.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/12_siegfried_jelenet.jpg',
        name: '12_siegfried_jelenet.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/13_istenekalkonya_pilinszky.jpg',
        name: '13_istenekalkonya_pilinszky.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/14_istenekalkonya_venczell.jpg',
        name: '14_istenekalkonya_venczell.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/15_Cosifantutte1930_003.jpg',
        name: '15_Cosifantutte1930_003.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/16_cosi_1930_markus.jpg',
        name: '16_cosi_1930_markus.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/17_cosi_sandorerzsi_bodo_1930.jpg',
        name: '17_cosi_sandorerzsi_bodo_1930.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/18_figarohazassaga_1937_01.jpg',
        name: '18_figarohazassaga_1937_01.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/19_figarohazassaga_1937_02.jpg',
        name: '19_figarohazassaga_1937_02.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/20_figarohazassaga_tablo_1937.jpg',
        name: '20_figarohazassaga_tablo_1937.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/21.boriszgodunov_1930_markus.jpg',
        name: '21.boriszgodunov_1930_markus.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/22_turandot_1927.JPG',
        name: '22_turandot_1927.JPG'
    },
    {
        devicePath: '/assets/defaults/markus/23_turandot_sandorerzsi_tihanyi.JPG',
        name: '23_turandot_sandorerzsi_tihanyi.JPG'
    },
    {
        devicePath: '/assets/defaults/markus/24_francescadarimini1929_markus.jpg',
        name: '24_francescadarimini1929_markus.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/25_francescadarimini1929_markus_2.felv.jpg',
        name: '25_francescadarimini1929_markus_2.felv.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/26_francescadarimini1929_markus_4.fe.jpg',
        name: '26_francescadarimini1929_markus_4.fe.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/27_Falstaff_Palmre_001.jpg',
        name: '27_Falstaff_Palmre_001.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/28_MarschalkMelisande1925_001.jpg',
        name: '28_MarschalkMelisande1925_001.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/29_Oda-vissza1929_Losonczy_001.jpg',
        name: '29_Oda-vissza1929_Losonczy_001.jpg'
    },
    {
        devicePath: '/assets/defaults/markus/30_Oda-vissza1929_01.jpg',
        name: '30_Oda-vissza1929_01.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/01.Petruska_1926.jpg',
        name: '01.Petruska_1926.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/02.Xerxes_001_28.jpg',
        name: '02.Xerxes_001_28.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/03.Diotoro_929.jpg',
        name: '03.Diotoro_929.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/04.Tenor_R_Markus002_29.jpg',
        name: '04.Tenor_R_Markus002_29.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/05_csongor_1930.jpg',
        name: '05_csongor_1930.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/06_Traviata1931_1fv_001.jpg',
        name: '06_Traviata1931_1fv_001.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/07_olah_elsslerfanny_1933.jpg',
        name: '07_olah_elsslerfanny_1933.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/08.fabolfaragott1935_004_masolata.jpg',
        name: '08.fabolfaragott1935_004_masolata.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/09_kekszakallu-1936.jpg',
        name: '09_kekszakallu-1936.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/10.Bohem_1937.JPG',
        name: '10.Bohem_1937.JPG'
    },
    {
        devicePath: '/assets/defaults/olah/11_bohemelet_2006_jelenet.jpg',
        name: '11_bohemelet_2006_jelenet.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/12_mesterdalnokok_1938.jpg',
        name: '12_mesterdalnokok_1938.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/13_mesterdalnokok_1938.jpg',
        name: '13_mesterdalnokok_1938.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/14_A_fabol_faragott_kiralyfi_1939_001.jpg',
        name: '14_A_fabol_faragott_kiralyfi_1939_001.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/15_lang_milano_01.jpg',
        name: '15_lang_milano_01.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/16_lang_milano_02.jpg',
        name: '16_lang_milano_02.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/17_lang_walter_lendvay.jpg',
        name: '17_lang_walter_lendvay.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/18_kekszakallu_1948.jpg',
        name: '18_kekszakallu_1948.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/19_Balvanyos_var_1948_foto_Varkonyi_001.jpg',
        name: '19_Balvanyos_var_1948_foto_Varkonyi_001.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/20_Carmen1949_001.jpg',
        name: '20_Carmen1949_001.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/21_Carmen1949tabla_001.jpg',
        name: '21_Carmen1949tabla_001.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/22_diotoro_kb20822_1950.jpg',
        name: '22_diotoro_kb20822_1950.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/23_diotoro.JPG',
        name: '23_diotoro.JPG'
    },
    {
        devicePath: '/assets/defaults/olah/24_Diotoro_1950_IIIfv3kep_01.jpg',
        name: '24_Diotoro_1950_IIIfv3kep_01.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/25_MG_3257_CSP.JPG',
        name: '25_MG_3257_CSP.JPG'
    },
    {
        devicePath: '/assets/defaults/olah/26_Traviata1950_001.jpg',
        name: '26_Traviata1950_001.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/27_cosi_1952Cosi1952_I_1.jpg',
        name: '27_cosi_1952Cosi1952_I_1.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/28_Fabol_faragott1952_001.jpg',
        name: '28_Fabol_faragott1952_001.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/29_Pillangokisasszony1952_003.jpg',
        name: '29_Pillangokisasszony1952_003.jpg'
    },
    {
        devicePath: '/assets/defaults/olah/30_tosca_1953.JPG',
        name: '30_tosca_1953.JPG'
    },
    {
        devicePath: '/assets/defaults/mark/01_Hovanscsina1936_total_01.jpg',
        name: '01_Hovanscsina1936_total_01.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/02_Pataky_Bohemelet_1937_Lendvai_Losonczy_Szekely.jpg',
        name: '02_Pataky_Bohemelet_1937_Lendvai_Losonczy_Szekely.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/03_lohengrin_1938_osvathjulia_002.jpg',
        name: '03_lohengrin_1938_osvathjulia_002.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/04_Salome1940_tabla_01.jpg',
        name: '04_Salome1940_tabla_01.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/05_manon_1941_osvath.jpg',
        name: '05_manon_1941_osvath.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/06_Csodahegedu_1942_orosz_osvath.jpg',
        name: '06_Csodahegedu_1942_orosz_osvath.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/07_Pikk_dama_Budanovits_Orosz001.jpg',
        name: '07_Pikk_dama_Budanovits_Orosz001.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/08_lohengrin_1948_palankay.jpg',
        name: '08_lohengrin_1948_palankay.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/09_Igorherceg(2).jpg',
        name: '09_Igorherceg(2).jpg'
    },
    {
        devicePath: '/assets/defaults/mark/10_Mesterdalnokok1949_Sardy_Janos_Budanovits_Maria_Osvath_Julia_Simandy_Jozsef_Varkonyi_foto_011.jpg',
        name: '10_Mesterdalnokok1949_Sardy_Janos_Budanovits_Maria_Osvath_Julia_Simandy_Jozsef_Varkonyi_foto_011.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/11_Mindszenti_Adam_Gyurkovics_Maria_Melis_Gyorgy_Janosvitez_1953_rairvaDebrecen_004.jpg',
        name: '11_Mindszenti_Adam_Gyurkovics_Maria_Melis_Gyorgy_Janosvitez_1953_rairvaDebrecen_004.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/12_Anyegin1955_Melis_Gyorgy_Hazy_Erzsebet_004.jpg',
        name: '12_Anyegin1955_Melis_Gyorgy_Hazy_Erzsebet_004.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/13_bahcsiszeraji_1996_veghkriszta.jpg',
        name: '13_bahcsiszeraji_1996_veghkriszta.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/14_Harangozo_Gyula_Coppelia_002.jpg',
        name: '14_Harangozo_Gyula_Coppelia_002.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/15_Bank_ban1953_Losonczy_Gyorgy_Simandy_Jozsef.jpg',
        name: '15_Bank_ban1953_Losonczy_Gyorgy_Simandy_Jozsef.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/16_pomade_1953_melis.jpg',
        name: '16_pomade_1953_melis.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/17_Joviczky_Jozsef_Otello_002.jpg',
        name: '17_Joviczky_Jozsef_Otello_002.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/18_otello_1954_tiszay_losonczy.jpg',
        name: '18_otello_1954_tiszay_losonczy.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/19_donjuan_1956_nagypal_osvath.jpg',
        name: '19_donjuan_1956_nagypal_osvath.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/20_Acsodalatosmandarin1956_LakatosGabriella_004.jpg',
        name: '20_Acsodalatosmandarin1956_LakatosGabriella_004.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/21_Takacs_Paula_Turandot_58.jpg',
        name: '21_Takacs_Paula_Turandot_58.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/22_Avarazsfuvola1976_HormaiJ_Polgar_L_SudlikM_001.jpg',
        name: '22_Avarazsfuvola1976_HormaiJ_Polgar_L_SudlikM_001.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/23_Cestlaguerre1962_Farago_Andras_Matyas_Maria.jpg',
        name: '23_Cestlaguerre1962_Farago_Andras_Matyas_Maria.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/24_Brankovics_1962.jpg',
        name: '24_Brankovics_1962.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/25_Lohengrin1964_AntalffyA_KanyaS_Matyas_001.jpg',
        name: '25_Lohengrin1964_AntalffyA_KanyaS_Matyas_001.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/26_DonCarlos.jpg',
        name: '26_DonCarlos.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/27_Samson1973_Melis_Gyorgy_Csanyi_Janos_001.jpg',
        name: '27_Samson1973_Melis_Gyorgy_Csanyi_Janos_001.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/28_SimonBoccanegra1980_KincsesV_MillerL_004.jpg',
        name: '28_SimonBoccanegra1980_KincsesV_MillerL_004.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/29_Molnar_Andras_Titus1991_001.jpg',
        name: '29_Molnar_Andras_Titus1991_001.jpg'
    },
    {
        devicePath: '/assets/defaults/mark/30_torzene1999_002.jpg',
        name: '30_torzene1999_002.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/01_arvajozsi_1933_.jpg',
        name: '01_arvajozsi_1933_.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/02_marta_1935.jpg',
        name: '02_marta_1935.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/03_fabolfaragott_1935.jpg',
        name: '03_fabolfaragott_1935.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/04_fabolfaragott_1935_02.jpg',
        name: '04_fabolfaragott_1935_02.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/05_jatekdoboz_1936_02.jpg',
        name: '05_jatekdoboz_1936_02.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/06_csardajelenet_1936.jpg',
        name: '06_csardajelenet_1936.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/07_lysistrata_1937.jpg',
        name: '07_lysistrata_1937.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/08_salome_1940.jpg',
        name: '08_salome_1940.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/09_salome_1958.jpg',
        name: '09_salome_1958.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/10_cseresznyevirag_1941.jpg',
        name: '10_cseresznyevirag_1941.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/11_walkur_1942.jpg',
        name: '11_walkur_1942.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/12_petergrimes_1947.jpg',
        name: '12_petergrimes_1947.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/13_windsori_1948.jpg',
        name: '13_windsori_1948.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/14_Bahcsi1952_.jpg',
        name: '14_Bahcsi1952_.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/15_Halka1952_001.jpg',
        name: '15_Halka1952_001.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/16_Pomade_kiraly_1953_006.jpg',
        name: '16_Pomade_kiraly_1953_006.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/17_romeoesjulia_1955_02.jpg',
        name: '17_romeoesjulia_1955_02.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/18_csodalatosmandarin_1956.jpg',
        name: '18_csodalatosmandarin_1956.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/19_orfeusz_1957_02.jpg',
        name: '19_orfeusz_1957_02.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/20_fabolfaragott_1958_01.jpg',
        name: '20_fabolfaragott_1958_01.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/21_fabolfaragott_1958_02.jpg',
        name: '21_fabolfaragott_1958_02.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/22_turandot_1958.jpg',
        name: '22_turandot_1958.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/23_trisztan_1959.jpg',
        name: '23_trisztan_1959.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/24_varazsfuvola_1960.jpg',
        name: '24_varazsfuvola_1960.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/25_varazsfuvola_1960_02.jpg',
        name: '25_varazsfuvola_1960_02.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/26_Macbeth1961_002.jpg',
        name: '26_Macbeth1961_002.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/27_Manon1961.JPG',
        name: '27_Manon1961.JPG'
    },
    {
        devicePath: '/assets/defaults/fulop/28_vernasz_1964.jpg',
        name: '28_vernasz_1964.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/29_tannhauser_67.jpg',
        name: '29_tannhauser_67.jpg'
    },
    {
        devicePath: '/assets/defaults/fulop/30_Csipkerozsika_001.jpg',
        name: '30_Csipkerozsika_001.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/01_orygrofja_1960_03Orygrofja_013.jpg',
        name: '01_orygrofja_1960_03Orygrofja_013.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/02_hary_1962_01.jpg',
        name: '02_hary_1962_01.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/03_wozzeck_1964_01.jpg',
        name: '03_wozzeck_1964_01.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/04_wozzeck_1964_02.jpg',
        name: '04_wozzeck_1964_02.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/05_poppea_1968.jpg',
        name: '05_poppea_1968.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/06_bun_esbunhodes_1969_01.jpg',
        name: '06_bun_esbunhodes_1969_01.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/07_don-carlos.jpg',
        name: '07_don-carlos.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/08_Laurencia1970_Forray_G_diszlete_016.jpg',
        name: '08_Laurencia1970_Forray_G_diszlete_016.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/09_kekszakallu_1970_01.jpg',
        name: '09_kekszakallu_1970_01.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/10_kekszakallu_1981_02.jpg',
        name: '10_kekszakallu_1981_02.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/11_fabolfaragott_1970_02.jpg',
        name: '11_fabolfaragott_1970_02.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/12_hovanscsina_1972.jpg',
        name: '12_hovanscsina_1972.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/13_istenek_1972_02.jpg',
        name: '13_istenek_1972_02.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/14_donjuan_1974_02.jpg',
        name: '14_donjuan_1974_02.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/15_donjuan_1974_04.jpg',
        name: '15_donjuan_1974_04.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/16_Lombardok_1974_02.jpg',
        name: '16_Lombardok_1974_02.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/17_mozes_1977_02.jpg',
        name: '17_mozes_1977_02.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/18_bolygohollandi_1978.jpg',
        name: '18_bolygohollandi_1978.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/19_chenier_1978_02.jpg',
        name: '19_chenier_1978_02.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/20_chenier_1978_03.jpg',
        name: '20_chenier_1978_03.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/21_chenier_1978_04.jpg',
        name: '21_chenier_1978_04.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/22_cosi_1979_01.jpg',
        name: '22_cosi_1979_01.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/23_fabolfaragott_1981.jpg',
        name: '23_fabolfaragott_1981.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/24_parsifal_2006.jpg',
        name: '24_parsifal_2006.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/25_parsifal_2006_02.jpg',
        name: '25_parsifal_2006_02.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/26_romeo_1993_01.jpg',
        name: '26_romeo_1993_01.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/27_romeo_1993_04.jpg',
        name: '27_romeo_1993_04.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/28_szentivaneji_1989_03.jpg',
        name: '28_szentivaneji_1989_03.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/29_szentivaneji_1989_04.jpg',
        name: '29_szentivaneji_1989_04.jpg'
    },
    {
        devicePath: '/assets/defaults/forray/30_fabolfaragott_1998_01.jpg',
        name: '30_fabolfaragott_1998_01.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/01_cosi_1979.jpg',
        name: '01_cosi_1979.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/02_cosi_2006_02.jpg',
        name: '02_cosi_2006_02.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/03_cosi_2008.jpg',
        name: '03_cosi_2008.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/04_kekszakall_1981.jpg',
        name: '04_kekszakall_1981.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/05_proba_2005_02.JPG',
        name: '05_proba_2005_02.JPG'
    },
    {
        devicePath: '/assets/defaults/schaffer/06_proba_2005.jpg',
        name: '06_proba_2005.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/07_traviata_1986.jpg',
        name: '07_traviata_1986.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/08_traviata_2012_02.jpg',
        name: '08_traviata_2012_02.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/09_traviata_2012_03.JPG',
        name: '09_traviata_2012_03.JPG'
    },
    {
        devicePath: '/assets/defaults/schaffer/10_nabucco_2006.jpg',
        name: '10_nabucco_2006.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/11_nabucco_2006_02.jpg',
        name: '11_nabucco_2006_02.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/12_nabucco_2006_04.jpg',
        name: '12_nabucco_2006_04.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/13_hary_1988_02.jpg',
        name: '13_hary_1988_02.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/14_hary_1988_03.jpg',
        name: '14_hary_1988_03.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/15_hary_1988.jpg',
        name: '15_hary_1988.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/16_romeo_1988.jpg',
        name: '16_romeo_1988.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/17_romeo_2011.jpg',
        name: '17_romeo_2011.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/18_trisztan_1988.jpg',
        name: '18_trisztan_1988.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/19_salome_1989.jpg',
        name: '19_salome_1989.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/20_salome_2002.jpg',
        name: '20_salome_2002.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/21_salome_2009.jpg',
        name: '21_salome_2009.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/22_kekszakallu_1989.jpg',
        name: '22_kekszakallu_1989.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/23_kekszakallu_1989_02.jpg',
        name: '23_kekszakallu_1989_02.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/24_hunyadi_1989.jpg',
        name: '24_hunyadi_1989.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/25_hunyadi_2003_03.jpg',
        name: '25_hunyadi_2003_03.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/26_tannhauser_2012_02.jpg',
        name: '26_tannhauser_2012_02.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/27_Tannhauser_Sur_2002_061.jpg',
        name: '27_Tannhauser_Sur_2002_061.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/28_csipke1991_03.jpg',
        name: '28_csipke1991_03.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/29_velencei_mor_1994_03.jpg',
        name: '29_velencei_mor_1994_03.jpg'
    },
    {
        devicePath: '/assets/defaults/schaffer/30_elfujta_2007_04.JPG',
        name: '30_elfujta_2007_04.JPG'
    },
    {
        devicePath: '/assets/defaults/makai/01.Falstaff1985_Sudlik_Melis.jpg',
        name: '01.Falstaff1985_Sudlik_Melis.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/02_falstaff_1992_02.jpg',
        name: '02_falstaff_1992_02.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/03_ariadne_1967_01.jpg',
        name: '03_ariadne_1967_01.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/04_ariadne_1967_02.jpg',
        name: '04_ariadne_1967_02.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/05_ariadne_1967_03.jpg',
        name: '05_ariadne_1967_03.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/06_Mahagonny_067.jpg',
        name: '06_Mahagonny_067.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/07_szoktetes_02.jpg',
        name: '07_szoktetes_02.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/08_szoktetes_1968_03.jpg',
        name: '08_szoktetes_1968_03.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/09_porgy_1970_01.jpg',
        name: '09_porgy_1970_01.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/10_porgy_1970_02.jpg',
        name: '10_porgy_1970_02.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/11_rozsalovag_1997_kukely.jpg',
        name: '11_rozsalovag_1997_kukely.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/12_rozsalovag_1971_01.jpg',
        name: '12_rozsalovag_1971_01.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/13_rozsalovag_1997_01.jpg',
        name: '13_rozsalovag_1997_01.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/14_Lulu1973_Kalmar_M_RozsosI_001.jpg',
        name: '14_Lulu1973_Kalmar_M_RozsosI_001.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/15_Haromnarancs_total_107.jpg',
        name: '15_Haromnarancs_total_107.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/16_legyjo_1975_02.jpg',
        name: '16_legyjo_1975_02.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/17_vilagteremtese_1976_01.jpg',
        name: '17_vilagteremtese_1976_01.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/18_ajtonkivul_1978_01.jpg',
        name: '18_ajtonkivul_1978_01.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/19.Aravasz_rokacska_1979_Kukely_Julia_003.jpg',
        name: '19.Aravasz_rokacska_1979_Kukely_Julia_003.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/20_ernani_1980_01.jpg',
        name: '20_ernani_1980_01.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/21_varazsfuvola_1980_02.jpg',
        name: '21_varazsfuvola_1980_02.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/22_parsifal_1983_01.jpg',
        name: '22_parsifal_1983_01.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/23_parsifal_1983_02.JPG',
        name: '23_parsifal_1983_02.JPG'
    },
    {
        devicePath: '/assets/defaults/makai/24_werther_1984053.jpg',
        name: '24_werther_1984053.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/25_trisztan_1988.jpg',
        name: '25_trisztan_1988.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/26_lang_1989.jpg',
        name: '26_lang_1989.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/27_Vegzet_Tokody051.jpg',
        name: '27_Vegzet_Tokody051.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/28.Gioconda_1991_002.jpg',
        name: '28.Gioconda_1991_002.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/29_bolygohollandi_1997_01.jpg',
        name: '29_bolygohollandi_1997_01.jpg'
    },
    {
        devicePath: '/assets/defaults/makai/30_bolygohollandi_1997_03.jpg',
        name: '30_bolygohollandi_1997_03.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/01_trubadur_1969_02.jpg',
        name: '01_trubadur_1969_02.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/02_trubadur_1969_01.jpg',
        name: '02_trubadur_1969_01.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/03_pillango_1971_02.jpg',
        name: '03_pillango_1971_02.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/04_pillango_1971_01.jpg',
        name: '04_pillango_1971_01.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/05_salome__A47A6942.jpg',
        name: '05_salome__A47A6942.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/06_Mozes_Rossini_1992055.jpg',
        name: '06_Mozes_Rossini_1992055.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/07_Rajna_1993_056.jpg',
        name: '07_Rajna_1993_056.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/08_aida_2011.JPG',
        name: '08_aida_2011.JPG'
    },
    {
        devicePath: '/assets/defaults/csikos/09_aida_2011_02.JPG',
        name: '09_aida_2011_02.JPG'
    },
    {
        devicePath: '/assets/defaults/csikos/10_otello_diszlet_1_1130607_6149.JPG',
        name: '10_otello_diszlet_1_1130607_6149.JPG'
    },
    {
        devicePath: '/assets/defaults/csikos/11_otello_2011_02.JPG',
        name: '11_otello_2011_02.JPG'
    },
    {
        devicePath: '/assets/defaults/csikos/12_otello_2011_03.JPG',
        name: '12_otello_2011_03.JPG'
    },
    {
        devicePath: '/assets/defaults/csikos/13_Walkar_1995_total_057.jpg',
        name: '13_Walkar_1995_total_057.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/14_siegfried_1996_03.jpg',
        name: '14_siegfried_1996_03.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/15_trubadur_1996_MG_0044.JPG',
        name: '15_trubadur_1996_MG_0044.JPG'
    },
    {
        devicePath: '/assets/defaults/csikos/16_trubadur_2006_02.JPG',
        name: '16_trubadur_2006_02.JPG'
    },
    {
        devicePath: '/assets/defaults/csikos/17_giselle_1996_01.jpg',
        name: '17_giselle_1996_01.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/18_falstaff_2002.jpg',
        name: '18_falstaff_2002.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/19_donquijote_2012_03.JPG',
        name: '19_donquijote_2012_03.JPG'
    },
    {
        devicePath: '/assets/defaults/csikos/20_szekelyfono_1998.jpg',
        name: '20_szekelyfono_1998.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/21_szekelyfono_1998_02.jpg',
        name: '21_szekelyfono_1998_02.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/22_istenek_1998.jpg',
        name: '22_istenek_1998.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/23_istenek_1998_02.jpg',
        name: '23_istenek_1998_02.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/24_figaro_2015_02.jpg',
        name: '24_figaro_2015_02.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/25_hary_1999_02.jpg',
        name: '25_hary_1999_02.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/26_carmen2000_001.jpg',
        name: '26_carmen2000_001.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/27_szoktetes_2002_01.jpg',
        name: '27_szoktetes_2002_01.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/28_vajdatornya_2003_001.jpg',
        name: '28_vajdatornya_2003_001.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/29_rigoletto_2005_03.jpg',
        name: '29_rigoletto_2005_03.jpg'
    },
    {
        devicePath: '/assets/defaults/csikos/30_arabella_2012_02.jpg',
        name: '30_arabella_2012_02.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/01_Bolygo_978_KovacsEszter_Senta_002.jpg',
        name: '01_Bolygo_978_KovacsEszter_Senta_002.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/02_pillango_1983.jpg',
        name: '02_pillango_1983.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/03_pillango_1983_02.JPG',
        name: '03_pillango_1983_02.JPG'
    },
    {
        devicePath: '/assets/defaults/vago/04_romeo_1993.jpg',
        name: '04_romeo_1993.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/05_romeo_1993_02.jpg',
        name: '05_romeo_1993_02.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/06_Janos_vitez_1987_Lacza_A_VerebicsI_01.jpg',
        name: '06_Janos_vitez_1987_Lacza_A_VerebicsI_01.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/07_tosca_2005.jpg',
        name: '07_tosca_2005.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/08_szentivaneji_1989_02.jpg',
        name: '08_szentivaneji_1989_02.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/09_szentivaneji_1989.jpg',
        name: '09_szentivaneji_1989.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/10_Ciganybaro_1991_Melis_004.jpg',
        name: '10_Ciganybaro_1991_Melis_004.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/11_Adriana_1992_058.jpg',
        name: '11_Adriana_1992_058.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/12_fabolfaragott_1993_02.jpg',
        name: '12_fabolfaragott_1993_02.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/13_Mandarin_Fodor_1993_Venekei060.jpg',
        name: '13_Mandarin_Fodor_1993_Venekei060.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/14_kekszakallu_1993.jpg',
        name: '14_kekszakallu_1993.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/15_aida_2011_02.JPG',
        name: '15_aida_2011_02.JPG'
    },
    {
        devicePath: '/assets/defaults/vago/16_dozsa_1994_.jpg',
        name: '16_dozsa_1994_.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/17_otello_2011.JPG',
        name: '17_otello_2011.JPG'
    },
    {
        devicePath: '/assets/defaults/vago/18_Makrancos1994_Popova_Havas_002.jpg',
        name: '18_Makrancos1994_Popova_Havas_002.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/19_makrancos_1994_02.jpg',
        name: '19_makrancos_1994_02.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/20_walkur_1995.jpg',
        name: '20_walkur_1995.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/21_faust_1996.jpg',
        name: '21_faust_1996.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/22_giselle_1996(1).jpg',
        name: '22_giselle_1996(1).jpg'
    },
    {
        devicePath: '/assets/defaults/vago/23_don_quijote_1997.jpg',
        name: '23_don_quijote_1997.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/24_Haromakislany1997_Melis_Kalmar_M_002.jpg',
        name: '24_Haromakislany1997_Melis_Kalmar_M_002.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/25_figaro_2010.jpg',
        name: '25_figaro_2010.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/26_fabolfaragott_1998.jpg',
        name: '26_fabolfaragott_1998.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/27_szekelyfono_1998_03.jpg',
        name: '27_szekelyfono_1998_03.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/28_hary_1999_01.jpg',
        name: '28_hary_1999_01.jpg'
    },
    {
        devicePath: '/assets/defaults/vago/29_seherezade_1999(2).jpg',
        name: '29_seherezade_1999(2).jpg'
    },
    {
        devicePath: '/assets/defaults/vago/30_Denever_2001_Bathory_Garday_059.jpg',
        name: '30_Denever_2001_Bathory_Garday_059.jpg'
    }
]