import React from 'react';
import Padded from "../../hoc/Padded/Padded";
import UltraBold from "../../hoc/UltraBold/UltraBold";
import Button from "../Button/Button";
import {useHistory} from "react-router";
import Row from "../../hoc/Row/Row";

const SubMenuButtons = ({buttons}) => {
    const history = useHistory();

    const getButtons = () => buttons.map((button, i) => (
        <Button key={i} fullHeight={true} type={'primary'}
                action={() => history.push(button.to)}>
            <Padded top={'1.5vw'} bottom={'1.6vw'} left={0} right={0}>
                <img src={button.iconPath}
                     style={{width: '5vw'}} alt=""/>
                <UltraBold>
                    <div className={'fz-1'}>{button.title}</div>
                </UltraBold>
            </Padded>
        </Button>
    ));

    return (
        <Row align={'center'} gap={'3rem'} width={'13.5rem '.repeat(buttons.length)} justify={'center'}>
            {getButtons()}
        </Row>
    )
};

export default SubMenuButtons;
