import React from "react";

export const popupContent = [
    {
        id: 1,
        title: <h2>Jelmez</h2>,
        content: (<>
            <p>Olyan viselet, mely nélkül csak a legritkább esetben lépnek színpadra az előadás szereplői. A
                <i>Magyar ábrándok</i>ban természetesen magyaros viseletet tervezett a táncosoknak Fülöp Zoltán.</p>
        </>),
        position: {
            x: '2rem',
            y: '20rem'
        }
    },
    {
        id: 2,
        title: <h2>Díszlet</h2>,
        content: (<>
            <p>Vászonra festett mézeskalács huszár figurák, amelyeket igen könnyű volt az adott színpadi pillanatban
                mozgatni.
            </p>
        </>),
        position: {
            x: '10rem',
            y: '8rem'
        }
    },
    {
        id: 3,
        title: <h2>kalap, fejdísz</h2>,
        content: (<>
            <p>Olyan jelmezkiegészítők (jelen esetben csákók, illetve a Cigányleányt táncoló Szalay Karola koronája),
                melyek a Kalaposműhelyben készülnek.
            </p>
        </>),
        position: {
            x: '16rem',
            y: '9rem'
        }
    },
    {
        id: 4,
        title: <h2>magántáncos</h2>,
        content: (<>
            <p>Balettművész, akit adottságai, képességei, tehetsége szólista feladatokra predesztinál. A képen Szalay
                Karola (Cigányleány) és Kőszegi Ferenc (Mézeskalács huszár).
            </p>
        </>),
        position: {
            x: '22rem',
            y: '10rem'
        }
    },
    {
        id: 5,
        title: <h2>balettnövendék</h2>,
        content: (<>
            <p>Vagy ahogy régen nevezték: balettpatkány. Egykor 8-10 éves lányokat is felvettek az Opera
                balettiskolájába, ahol nagyon gyorsan színpadra kerültek. Egész nap a színházban éltek: délelőtt
                próbáltak, utána egy tanítónő oktatta nekik az általános iskola elméleti tárgyait, este pedig az
                előadásokban táncoltak vagy statisztáltak.
            </p>
        </>),
        position: {
            x: '26rem',
            y: '11rem'
        }
    },
    {
        id: 6,
        title: <h2>tánckari tag</h2>,
        content: (<>
            <p>Az 1930-as évekig csak lányokat vettek fel a balettkarba, ők táncolták a férfi szerepeket is. A
                világháború után, a szovjet balettek térhódításával ez megváltozott.
            </p>
        </>),
        position: {
            x: '36rem',
            y: '15rem'
        }
    },
    {
        id: 7,
        title: <h2>balettcipők</h2>,
        content: (<>
            <p>Már az 1800-as években is speciális cipőket használtak a balettekben. Megkülönböztettünk spicc-cipőt,
                karaktercipőt, balettcsizmát, gyakorlócipőt, illetve jazzcipőt.
            </p>
        </>),
        position: {
            x: '40rem',
            y: '17rem'
        }
    },
    {
        id: 8,
        title: <h2>takarás</h2>,
        content: (<>
            <p>Általában fekete függönyök a színpad két oldalán, amelyek megakadályozzák, hogy az avatatlan szemek
                belássanak a kulisszák mögé, ahol a művészek, díszítők, kellékesek vagy világosítók várakoznak.
            </p>
        </>),
        position: {
            x: '40rem',
            y: '4rem'
        }
    },
    {
        id: 9,
        title: <h2>díszlet</h2>,
        content: (<>
            <p>A realista, festett díszletfalak egy bécsi külvárosi ház udvarát ábrázolják az 1820-as években.
            </p>
        </>),
        position: {
            x: '1rem',
            y: '5rem'
        }
    },
    {
        id: 10,
        title: <h2>jelmez</h2>,
        content: (<>
            <p>Márk Tivadar egyedi műgonddal tervezett kosztümegyüttese méltó párja a díszletnek, együttesen idézik meg
                a biedermeier fénykorát, az 1820-as évek Bécsét.
            </p>
        </>),
        position: {
            x: '3rem',
            y: '17rem'
        }
    },
    {
        id: 11,
        title: <h2>kellék</h2>,
        content: (<>
            <p>A jelenetben számos kellék is szerepel, melyeket a díszletekhez és a jelmezekhez hasonlóan szintén az
                Operaház műtermei készítenek. Érdemes megszámolni, hány apró és nagyobb kellék van a színpadon: hordó,
                terítő, madárkalitka, prakker, bot, pad stb.
            </p>
        </>),
        position: {
            x: '11rem',
            y: '19rem'
        }
    },
    {
        id: 12,
        title: <h2>segédszereplő</h2>,
        content: (<>
            <p>Más szóval statiszták. Napjainkban zömében olyan fiatalok, akik tanulmányaik mellett ezt választják
                pénzkeresetnek. Az 1950-es években azonban számos állástalan színész vagy kispénzű nyugdíjas is az
                Operaház színpadára került.
            </p>
        </>),
        position: {
            x: '16rem',
            y: '16rem'
        }
    },
    {
        id: 13,
        title: <h2>gyermekkar</h2>,
        content: (<>
            <p>A múlt homályába vész, hogy pontosan mikor alakult az Opera Gyermekkara. Az 1950-es években már nagyon
                sok előadásban szerepeltek a <i>Carmen</i>től a <i>Háry János</i>ig.
            </p>
        </>),
        position: {
            x: '16rem',
            y: '5rem'
        }
    },
    {
        id: 14,
        title: <h2>súgóluk</h2>,
        content: (<>
            <p>Apró dobogó a színpad elején, középen. Ebben ül az előadás alatt a súgó, aki végszavaz a művészeknek,
                vagy kisegíti őket az esetleges memóriazavarok esetén.
            </p>
        </>),
        position: {
            x: '18rem',
            y: '22rem'
        }
    },
    {
        id: 15,
        title: <h2>ajtó, ablak</h2>,
        content: (<>
            <p>A korabeli díszletgyártás egyik kihívása volt olyan működőképes ajtókat és ablakokat csinálni, melyek
                használat közben nem remegtették meg az egész házat.
            </p>
        </>),
        position: {
            x: '25rem',
            y: '16rem'
        }
    },
    {
        id: 16,
        title: <h2>magánénekes</h2>,
        content: (<>
            <p>Azaz szólista. Bár a jelenetben zömével segédszereplők és gyermekkari tagok szerepelnek, egy magánénekest
                is felfedezhetünk: az egyik utcai énekest játszó Kishegyi Árpádot.
            </p>
        </>),
        position: {
            x: '25rem',
            y: '14rem'
        }
    },
    {
        id: 17,
        title: <h2>hangszer</h2>,
        content: (<>
            <p>A színpadon gyakran szerepelnek hangszerek. Olykor maguk a muzsikusok szólaltatják meg, más esetben
                azonban csak kellékek, melyek imitálják a játékot, miközben a zenekarból, vagy a színpad széléről
                csendül fel a valódi hangszer. Vélhetően a <i>Három a kislány</i>ban is ez történt.
            </p>
        </>),
        position: {
            x: '25rem',
            y: '18rem'
        }
    },
    {
        id: 18,
        title: <h2>énekkari tag</h2>,
        content: (<>
            <p>Olyan művész, akit hangi adottságai, alkata vagy szerencséje nem predesztinálja szólistának. Egy részük
                olykor szólófeladatokhoz is jut. Ilyen volt az egyik utcai énekest játszó lengyel származású Laboch
                Gerard is.
            </p>
        </>),
        position: {
            x: '33rem',
            y: '16rem'
        }
    },
    {
        id: 19,
        title: <h2>oldalfalak</h2>,
        content: (<>
            <p>A középső pagodával azonos stílusban készült díszletelemek, melyek a színpadtér szűkítésére is
                szolgálnak.
            </p>
        </>),
        position: {
            x: '4rem',
            y: '23rem'
        }
    },
    {
        id: 20,
        title: <h2>lampionok</h2>,
        content: (<>
            <p>A világosítók nem csak a reflektorokért és a fejgépekért felelősök, ők állítják be azokat a világító
                lampionokat is, melyek a mesebeli kelet hangulatát fokozzák.
            </p>
        </>),
        position: {
            x: '6rem',
            y: '11rem'
        }
    },
    {
        id: 21,
        title: <h2>énekkari tag</h2>,
        content: (<>
            <p>Énekkari szempontból a <i>Turandot</i> a törzsrepertoár egyik legnehezebb és egyben leghálásabb feladata.
                Az
                énekkari művészek alakítják a pórnépet, udvari elöljárókat és a titkokat őrző bölcseket is.
            </p>
        </>),
        position: {
            x: '10rem',
            y: '20rem'
        }
    },
    {
        id: 22,
        title: <h2>statiszta</h2>,
        content: (<>
            <p>A látványos jelenetben számos statiszta is szerepel: alabárdosok, miniszteri szolgák és Turandot
                udvarhölgyei színesítik a császári udvart.
            </p>
        </>),
        position: {
            x: '12rem',
            y: '14rem'
        }
    },
    {
        id: 23,
        title: <h2>pagoda</h2>,
        content: (<>
            <p>A díszlet felső emeletén, elfüggönyözve ült az Altoum császárt éneklő tenorista.
            </p>
        </>),
        position: {
            x: '19rem',
            y: '13rem'
        }
    },
    {
        id: 24,
        title: <h2>fejdísz</h2>,
        content: (<>
            <p>Turandot szerepének nemcsak a híres-hírhedt szólam a buktatója, hanem a többkilós gyöngyökkel kivarrt
                fejék is, melyet a művésznek viselnie kellett.
            </p>
        </>),
        position: {
            x: '21rem',
            y: '10rem'
        }
    },
    {
        id: 25,
        title: <h2>Turandot-szoknya</h2>,
        content: (<>
            <p>Turandot megjelenése az opera legizgalmasabb pillanata. Márk Tivadar a kínai királylány varázslatos
                személyiségét azzal is hangsúlyossá tette, hogy olyan hatalmas ruhát tervezett számára, melynek uszályát
                a címszereplő megjelenésekor nyolc statisztalány hozta.
            </p>
        </>),
        position: {
            x: '20rem',
            y: '15rem'
        }
    },
    {
        id: 26,
        title: <h2>lépcsősor</h2>,
        content: (<>
            <p>A II. világháború utáni magyar operaszcenika legnagyobb kőszínházi lépcsősora. Bár igen látványos volt,
                de veszélyeket is rejtett magában. A díszlet maximálisan kihasználta az Erkel Színház szűk kereteit, a
                lépcsősort pedig csak igen kis fokokkal lehetett megoldani. Így igencsak kényelmetlenül érezte magát az
                a Turandot, akinek 37-esnél nagyobb volt a cipőmérete.
            </p>
        </>),
        position: {
            x: '21rem',
            y: '20rem'
        }
    },
    {
        id: 27,
        title: <h2>középső díszletelem</h2>,
        content: (<>
            <p>Fülöp Zoltán többemeletes kínai pagodát idéző díszlete nemcsak esztétikailag volt kiemelkedő alkotás,
                hanem nagyszerűen ki is használta az Erkel Színház nem túl mély színpadát is.
            </p>
        </>),
        position: {
            x: '25rem',
            y: '20rem'
        }
    },
    {
        id: 28,
        title: <h2>magánénekes</h2>,
        content: (<>
            <p>A fotón legalább hét szólista áll a reflektorfényben vagy bujkál: Turandot a lépcső közepén, Altoum
                császár a fenti pagodában, Az ismeretlen herceg a kereten kívülről szemléli a császári udvart, a három
                miniszter a rendezői balon, míg a Mandarin a jobbon áll a tömeg között.
            </p>
        </>),
        position: {
            x: '30rem',
            y: '18rem'
        }
    },
    {
        id: 29,
        title: <h2>operafólia</h2>,
        content: (<>
            <p>A hátsó félkör alakú takarófüggöny szakmai neve. A fekete háttér itt még jobban kiemeli a díszletfalak
                élénk színeit.
            </p>
        </>),
        position: {
            x: '35rem',
            y: '16rem'
        }
    },
    {
        id: 30,
        title: <h2>kellék</h2>,
        content: (<>
            <p>A produkcióban számos egyedi gyártású kellék szerepelt, például díszes ernyők, zászlók, keleties
                alabárdok.
            </p>
        </>),
        position: {
            x: '38rem',
            y: '13rem'
        }
    },
    {
        id: 31,
        title: <h2>színpadi világítás</h2>,
        content: (<>
            <p>Az Operaház 1884-es megnyitóján még gázzal világítottak a színházban, a villanyt csak néhány évvel később
                vezették be a színházba. A színpadi világítás az 1930-as évektől lesz egyre árnyaltabb s ez a technikai
                fejlődés a mai napig tart.
            </p>
        </>),
        position: {
            x: '40rem',
            y: '14rem'
        }
    },
    {
        id: 32,
        title: <h2>jelmez</h2>,
        content: (<>
            <p>Márk Tivadar pazar jelmezegyüttese a kínai mesebirodalom teljes társadalmát igyekszik „korhű” módon
                bemutatni.
            </p>
        </>),
        position: {
            x: '40rem',
            y: '21rem'
        }
    },
    {
        id: 33,
        title: <h2>jelmez</h2>,
        content: (<>
            <p>Vágó Nelly korhű jelmezei a mű klasszikus produkcióinak hagyományai szerint készültek.
            </p>
        </>),
        position: {
            x: '3rem',
            y: '20rem'
        }
    },
    {
        id: 34,
        title: <h2>paróka</h2>,
        content: (<>
            <p>A korhű előadások elengedhetetlen kiegészítői a parókák, melyek az Opera Parókaműhelyében készülnek,
                feltevésükről pedig a fodrászok gondoskodnak. A <i>Toscában</i> nem csak Scarpia és az Államügyész visel
                vendéghajat, a fodrászok számára a legnagyobb kihívás a címszereplő felvonásonként változó frizurájának
                elkészítése.
            </p>
        </>),
        position: {
            x: '3rem',
            y: '12rem'
        }
    },
    {
        id: 35,
        title: <h2>kellék</h2>,
        content: (<>
            <p> Tosca legfontosabb kelléke az a színpadi tőr, melynek helye becsúszik a nyelébe, amikor a díva Scarpiába
                mártja. Szintén a Kelléktár dolga Scarpia vacsorájának elkészítése, valamint gondoskodni arról, hogy az
                íróállványon mindig legyen papír és toll.
            </p>
        </>),
        position: {
            x: '8rem',
            y: '15rem'
        }
    },
    {
        id: 36,
        title: <h2>szobor</h2>,
        content: (<>
            <p> Az Operaház szcenikai műhelyében 1884 óta készülnek szobrok. Ez ma sincs másként, napjainkban is
                dolgoznak diplomás szobrászművészek az Opera kötelékében.
            </p>
        </>),
        position: {
            x: '14rem',
            y: '11rem'
        }
    },
    {
        id: 37,
        title: <h2>vér</h2>,
        content: (<>
            <p> A színpadi vér mindig hatásos látványeleme az előadásoknak. Amennyiben a nyílt színen sebesül meg a
                szereplő, egy apró művérpatront nyom szét a megfelelő időben. Cavaradossit azonban nem a színpadon
                kínozzák meg, így a tenorista fehér ingét a színpad szélén, az úgynevezett takarásban kenik össze az
                öltöztetők. A művér egyik jó tulajdonsága, hogy mosható, nem hagy foltot és nem károsítja az anyagot.
            </p>
        </>),
        position: {
            x: '17rem',
            y: '15rem'
        }
    },
    {
        id: 38,
        title: <h2>mellékszereplők</h2>,
        content: (<>
            <p> Egy operában általában nemcsak főszerepek vannak, hanem apróbb szólók is. Ilyen egyfelvonásnyi szerep
                Sciarrone, Scarpia báró belső embere is
            </p>
        </>),
        position: {
            x: '20rem',
            y: '12rem'
        }
    },
    {
        id: 39,
        title: <h2>fény</h2>,
        content: (<>
            <p> A díszletfal teteje nyitott, plafon helyett egy rácsos fal fedi, mely csak még nyomasztóbbá,
                börtönszerűbbé teszi Scarpia szobáját. Fény azonban nem csak felülről éri a színpadot, oldalt, valamint
                az ajtók mögött is vannak reflektorok, a főszereplők mozgását pedig az első páholyokban ülő fejgépesek
                kísérik.
            </p>
        </>),
        position: {
            x: '23rem',
            y: '10rem'
        }
    },
    {
        id: 40,
        title: <h2>főszereplők</h2>,
        content: (<>
            <p> A <i>Tosca</i> három főszereplője (Floria Tosca – szoprán, Mario Cavaradossi – tenor, Scarpia – bariton)
                csak két rövid ideig tartózkodik egyszerre a színpadon. A hálásan megírt szólamok a legtöbb operaénekes
                álomszerepei közé tartoznak.
            </p>
        </>),
        position: {
            x: '27rem',
            y: '20rem'
        }
    },
    {
        id: 41,
        title: <h2>statiszta</h2>,
        content: (<>
            <p> A <i>Tosca</i> I. felvonásában több olyan statisztaszerep is van (Roberti, Vizsgálóbíró), melyeket
                Puccini a mű kottájában is rögzített. Rajtuk kívül három sbir – Scarpia fogdmegjei –, valamint egy írnok
                is tartózkodik a Farnese-palota termében. A segédszereplőknek az ilyen kis alakítások különösen
                kedvesek, a régebbi előadásokban még a színlapon is név szerint tüntették fel őket.
            </p>
        </>),
        position: {
            x: '33rem',
            y: '12rem'
        }
    },
    {
        id: 42,
        title: <h2>bútor</h2>,
        content: (<>
            <p> A színpadi bútorok berakása szintén a kellékesek feladatai közé tartoznak. Egyedi gyártású bútorok
                éppúgy megtalálhatók a színpadon, mint olyanok, melyeket több darabban láthatott már a közönség (ilyen a
                pamlag).
            </p>
        </>),
        position: {
            x: '38rem',
            y: '18rem'
        }
    },
    {
        id: 43,
        title: <h2>díszlet</h2>,
        content: (<>
            <p> Vayer Tamás márvány hatású zöldes falai úgynevezett masszázott technikával készültek. A festőműtermi
                művészek egy hatalmas vastepsiben vizet melegítenek, amibe többszínű olajfestéket csurgatnak, melyet egy
                pálcával óvatosan megkevernek. Papírlapokat fektetnek a víz tetejére, amin a forró víz tetején lebegő
                festék megtapad. A lapokat leemelik és megszárítják, a kész márványos mintát ecsettel korrigálják.
            </p>
        </>),
        position: {
            x: '40rem',
            y: '17rem'
        }
    },
    {
        id: 44,
        title: <h2>karaktercipő</h2>,
        content: (<>
            <p><i>A kalóz</i> I. felvonásában ilyet hord a tánckar egy része. A színházi cipészek készítik személyre
                szabva.
            </p>
        </>),
        position: {
            x: '3rem',
            y: '19rem'
        }
    },
    {
        id: 45,
        title: <h2>maszk</h2>,
        content: (<>
            <p>A szólisták maszkjait általában a fodrászok készítik, míg a balettkar maga sminkel az öltözőben.
            </p>
        </>),
        position: {
            x: '6rem',
            y: '14rem'
        }
    },
    {
        id: 46,
        title: <h2>jelmez</h2>,
        content: (<>
            <p>A tervezőknek a balettkosztümök elkészítésénél nemcsak a táncosnők alakjára kell gondosan odafigyelniük,
                hanem a jelmezek súlyára is. Minél könnyebb anyagokból kell készíteni a balettruhákat, tánc közben
                ugyanis minden gramm plusz súly nehezítést jelent.
            </p>
        </>),
        position: {
            x: '9rem',
            y: '13rem'
        }
    },
    {
        id: 47,
        title: <h2>balettpadló</h2>,
        content: (<>
            <p>Valaha a baletteket a színházak fapadlóján táncolták. Az utóbbi időben elsősorban a táncosok egészségének
                védelme érdekében a színpadra speciális balettpadló került, amit aztán festett linóleumcsíkokkal
                borítanak. <i>A kalóz</i> kopott márványlapokat imitáló padlóját az Opera Festőműtermében készítették.
            </p>
        </>),
        position: {
            x: '10rem',
            y: '22rem'
        }
    },
    {
        id: 48,
        title: <h2>díszlet</h2>,
        content: (<>
            <p>A díszletfalak váza az Opera Díszletlakatos- és Díszletasztalos-műhelyében készül, ezután kerül a
                Festőműterembe.
            </p>
        </>),
        position: {
            x: '15rem',
            y: '8rem'
        }
    },
    {
        id: 49,
        title: <h2>spicc-cipő</h2>,
        content: (<>
            <p>A romantikus és klasszikus balettek elengedhetetlen kelléke. A balerinák légiességét jelképező spiccelés
                az 1820-as évektől már biztosan megjelent a színpadokon. A spicc-cipőket hajdan a színházak cipészei
                készítették, ma már jelentős gyárakból rendelik a különböző világmárkákat.
            </p>
        </>),
        position: {
            x: '19rem',
            y: '19rem'
        }
    },
    {
        id: 50,
        title: <h2>tütü</h2>,
        content: (<>
            <p>Nagyjából a spicc-cipővel együtt – az 1830-as években jelent meg a színpadon. A romantikus tütüből
                legfeljebb a balerinák térde villanhatott ki, aztán a táncszoknya rövidülni kezdett… A tütükészítés ma
                is külön művészet, szerencsére az Opera varrónői ehhez is értenek.
            </p>
        </>),
        position: {
            x: '19rem',
            y: '16rem'
        }
    },
    {
        id: 51,
        title: <h2>háttérfüggöny</h2>,
        content: (<>
            <p>A kézzel festett háttérfüggöny ma is klasszikus technikával az Opera Festőműtermében készül.
            </p>
        </>),
        position: {
            x: '22rem',
            y: '17rem'
        }
    },
    {
        id: 52,
        title: <h2>magántáncos</h2>,
        content: (<>
            <p>Vezető szólista, principál, első magántáncos, számos névvel illetik azokat a balettművészeket, akik a
                főszerepeket táncolják.
            </p>
        </>),
        position: {
            x: '24rem',
            y: '15rem'
        }
    },
    {
        id: 53,
        title: <h2>tánckari tag</h2>,
        content: (<>
            <p>Ugyanolyan képzésben vesznek részt, mint a magántáncosok. A munkájuk sem könnyebb a szólistákénál. Számos
                klasszikus balettben jutnak nagy és nehéz feladathoz, vannak olyan előadások, ahol minden felvonásban
                más-más karaktert kell megjeleníteniük vagy egészen más stílusú táncokat előadniuk.
            </p>
        </>),
        position: {
            x: '29rem',
            y: '9rem'
        }
    },
    {
        id: 54,
        title: <h2>balettcsizma</h2>,
        content: (<>
            <p>A balettek karakterszereplői – egykor mímusnak is nevezték őket – csizmát vagy karaktercipőt hordanak a
                színpadon.
            </p>
        </>),
        position: {
            x: '34rem',
            y: '19rem'
        }
    },
    {
        id: 55,
        title: <h2>álszakáll</h2>,
        content: (<>
            <p>A parókák mellett az álszakállak is a Parókaműhelyben készülnek, a szakszerű felrakásukról pedig a
                fodrászok gondoskodnak esténként.
            </p>
        </>),
        position: {
            x: '37rem',
            y: '13rem'
        }
    },
    {
        id: 56,
        title: <h2>kellék</h2>,
        content: (<>
            <p>A keleti miliőt számos egyedi gyártású kellék – legyezők, korsók, kelmék – erősíti.
            </p>
        </>),
        position: {
            x: '40rem',
            y: '9rem'
        }
    },
    {
        id: 57,
        title: <h2>hangosítás</h2>,
        content: (<>
            <p>A mikroport, miközben musical-előadásokon és gigantikus koncerteken már régóta megszokott jelenség, az
                operaházakban viszonylag új. Az egyre tökéletesedő hangtechnika ezúttal az előadás prózai színészeit
                segíti.
            </p>
        </>),
        position: {
            x: '3rem',
            y: '11rem'
        }
    },
    {
        id: 58,
        title: <h2>magánénekes</h2>,
        content: (<>
            <p>Egy kortárs opera vagy egy modern rendezés egészen más követelményeket támaszt az énekesekkel szemben,
                mint egy hagyományos produkció vagy egy romantikus opera. A <i>Figaro3</i> esetében külön nehézségeket
                jelent
                az újraírt szöveg betanulása, illetve a Mozart, Rossini és Milhaud művei közti stílusbeli átállás.
            </p>
        </>),
        position: {
            x: '5rem',
            y: '13rem'
        }
    },
    {
        id: 59,
        title: <h2>díszlet</h2>,
        content: (<>
            <p>Egy kortárs produkció látványvilágának csak a tervezők és a rendező fantáziája szab határt. Az új
                produkciók a díszletgyártó műhelyeket is új kihívások elé állítják, új anyagok kerülnek a színpadra,
                melyek kezelése-gyártásai is a hagyományostól eltérő technikákat igényel.
            </p>
        </>),
        position: {
            x: '11rem',
            y: '10rem'
        }
    },
    {
        id: 60,
        title: <h2>kellék</h2>,
        content: (<>
            <p>Ez esetben a kelléktár nemcsak az egyedi gyártású felnagyított asztali dekorációról gondoskodik, hanem a
                lufik beszerzése és felfújása is az ő feladatuk.
            </p>
        </>),
        position: {
            x: '40rem',
            y: '13rem'
        }
    },
    {
        id: 61,
        title: <h2>színész</h2>,
        content: (<>
            <p>Az operatörténetben rendre felbukkannak egyes művekben színészek. A leghíresebb prózai szerep a <i>Szöktetés
                a szerájból</i> Szelim basája. A kortárs alkotások, ha lehet, még nagyobb hangsúlyt fektetnek a műfajok
                közti átjárhatóságra, így az Opera is több színművészt léptet fel produkcióiban.
            </p>
        </>),
        position: {
            x: '40rem',
            y: '16rem'
        }
    },
]
