import {createSlice} from '@reduxjs/toolkit'
import {defaultFiles} from "../static/defaultFiles";

const initialState = {
    files: [],
    defaultFiles: defaultFiles
}

export const fileSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setFiles: (state, action) => {
            state.files = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setFiles} = fileSlice.actions

export default fileSlice.reducer
