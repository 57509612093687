import React, {useState} from 'react';
import Row from "../../hoc/Row/Row";
import {LEFT_PANEL_WIDTH_BIG, RIGHT_PANEL_WIDTH_BIG} from "../../utils/constants";
import BgImage from "../../hoc/BgImage/BgImage";
import Padded from "../../hoc/Padded/Padded";
import WithBackButton from "../../hoc/WithBackButton/WithBackButton";
import Valign from "../../hoc/Valign/Valign";
import {useSelector} from "react-redux";
import Carousel from "../Carousel/Carousel";
import {hotspotContainerConfig} from "./hotspotContainerConfig";
import Hotspot from "../Hotspot/Hotspot";
import Popup from "../Popup/Popup";
import {popupContent} from "../../store/static/popupContent";
import {hotspots} from "../../store/static/hotSpot";
import classes from './HotspotGame.module.scss';

const HotspotGame = () => {
    const screen = useSelector(store => store.screen.selectedScreen);
    const [currentHotspot, updateCurrentHotspot] = useState(null);
    const [currentPopup, updateCurrentPopup] = useState(null);

    const onHotSpotClick = (item) => {
        if (currentHotspot && item.popupId === currentHotspot.popupId) {
            updateCurrentHotspot(null);
            updateCurrentPopup(null);
        } else {
            updateCurrentHotspot(item);
            updateCurrentPopup(popupContent.find(p => p.id === item.popupId));
        }
    }

    const onSwipe = () => {
        updateCurrentHotspot(null);
        updateCurrentPopup(null);
    }

    return (
        <BgImage fullHeight={true} imagePath='/assets/main-bg.png'>
            <Row width={[LEFT_PANEL_WIDTH_BIG, RIGHT_PANEL_WIDTH_BIG].join(' ')} align={'start'} justify={'start'}>
                <div style={{backgroundColor: '#3C343B', height: '100vh'}}>
                    <BgImage position={'left 30vh'} size={'35vw auto'} imagePath={'/assets/sello-white.svg'}
                             fullHeight={true}>
                        <Padded fullHeight={true}>
                            <div>
                                <WithBackButton hasLabel={true}>
                                    <img style={{width: '70%', marginBottom: '1rem'}} alt='Műhely. Titkok.'
                                         src={'/assets/logo-muhelytitkok.svg'}/>
                                    <img style={{width: '80%', marginLeft: 'auto', display: 'block'}}
                                         src={'/assets/double-logo.svg'} alt=""/>
                                </WithBackButton>
                            </div>
                        </Padded>
                    </BgImage>
                </div>
                <div style={{height: '100vh'}}>
                    <Valign align={'center'}>
                        <Padded left={'4vh'} right={'4vh'}>
                            <Carousel showArrows={true} swipeable={false} onChange={onSwipe}>
                                {hotspotContainerConfig[screen].map((gameConfig, containerIndex) => (
                                    <div key={containerIndex} className={classes['game']}>
                                        <h1 className={classes['game__title']}>{gameConfig.title}</h1>
                                        <p className={classes['game__description']}>{gameConfig.description}</p>
                                        <div style={{position: 'relative'}}>
                                            <img src={gameConfig.bgImagePath} alt=""/>
                                            {hotspots[screen]['hotspotContainer#' + (1 + containerIndex)].map((item, i) => {
                                                return <Hotspot position={item.position} key={i}
                                                                onClick={() => onHotSpotClick(item)}
                                                                isOpen={currentHotspot && currentHotspot.popupId === item.popupId}/>
                                            })}
                                            {currentHotspot && currentPopup && (
                                                <Popup title={currentPopup.title}
                                                       position={currentPopup.position}
                                                       lineClass={currentPopup.lineClass}>
                                                    {currentPopup.content}
                                                </Popup>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </Padded>
                    </Valign>
                </div>
            </Row>
        </BgImage>
    );
};

export default HotspotGame;
