import React from 'react';

const BgImage = ({imagePath, fullHeight, position, size, children}) => {
    const blockHeight = fullHeight ? '100%' : 'auto';
    const bgPosition = position ?? 'center';
    const bgSize = size ?? 'contain';

    return (
        <div style={{
            backgroundImage: `url(${imagePath})`,
            height: blockHeight,
            backgroundPosition: bgPosition,
            backgroundSize: bgSize,
            backgroundRepeat: 'no-repeat'
        }}>
            {children}
        </div>
    );
};

export default BgImage;
