import React from 'react';
import {useHistory} from "react-router";
import classes from './WithBackButton.module.scss';
import Button from "../../components/Button/Button";
import {DEFAULT_PADDING, MEDIUM_SCREENS} from "../../utils/constants";
import UltraBold from "../UltraBold/UltraBold";
import {useSelector} from "react-redux";
import {isSizedScreen} from "../../utils/functions/screen";

const WithBackButton = ({hasLabel, children}) => {
    const history = useHistory();
    const screen = useSelector(store => store.screen.selectedScreen);
    let backIconWidth = '1.7rem';

    if (isSizedScreen(screen, MEDIUM_SCREENS)) {
        backIconWidth = '2rem';
    }

    return (
        <>
            {children}
            <div className={classes['with-back-button']} style={{bottom: DEFAULT_PADDING, left: DEFAULT_PADDING}}>
                <Button type={'default'} action={history.goBack}>
                    <div className={classes['content']}
                         style={{minHeight: isSizedScreen(screen, MEDIUM_SCREENS) && '4rem'}}>
                        <img style={{width: backIconWidth}} src={'/assets/back.svg'} alt=""/>
                        {!!hasLabel && <div className={classes['label']}><UltraBold>Vissza</UltraBold></div>}
                    </div>
                </Button>
            </div>
        </>
    );
};

export default WithBackButton;
