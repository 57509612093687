import React, {useEffect, useState} from 'react';
import {IonButton, IonContent, IonPage, IonSpinner} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {downloadNewFiles, getAssetsForScreen, getNewFilesFromConfig} from "../../utils/functions/sync";
import {setIsSynced} from "../../store/slices/screen";
import {setFiles} from "../../store/slices/files";
import {Capacitor} from "@capacitor/core";
import {setStorage} from "../../store/idb/storage";
import {CONTENTSTORAGE, FILESTORAGE} from "../../utils/constants";
import {setContent} from "../../store/slices/content";
import Center from "../../hoc/Center/Center";
import {useHistory} from "react-router";

const Sync = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const screen = useSelector(store => store.screen.selectedScreen);
    const files = useSelector(store => store.files.files);
    const [isCheckingForUpdates, setIsCheckingForUpdates] = useState(true);
    const [hasUpdate, setHasUpdate] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [assetConfig, updateAssetConfig] = useState();
    const [hasError, updateHasError] = useState(false);

    useEffect(async () => {
        const data = await getAssetsForScreen(screen);
        updateAssetConfig(data.data);

        const needsSync = (await getNewFilesFromConfig(data.data)).length > 0;
        if (needsSync) {
            setHasUpdate(true);
        }

        setIsCheckingForUpdates(false);
    }, []);

    const onDownloadClick = async () => {
        console.log('Clicked on Download');
        setIsDownloading(true);
        await downloadNewFiles(assetConfig, onDownloadSuccess, onDownloadError);
    }

    const onDownloadSuccess = async (fileConfig) => {
        await setStorage(CONTENTSTORAGE, assetConfig.content);
        dispatch(setContent(assetConfig.content));
        await setStorage(FILESTORAGE, fileConfig);
        dispatch(setFiles(fileConfig));
        dispatch(setIsSynced(true));
        setHasUpdate(false);
        setIsDownloading(false);
        console.log('END CLICK');
    }

    const onDownloadError = () => {
        setIsDownloading(false);
        updateHasError(true);
    }

    const getPageContent = () => {
        if (hasError) {
            return <div>Hiba lépett fel, a letöltés sikertelen. A korábban letöltött tartalmak továbbra is elérhetők az
                alkalmazás számára.
                <IonButton onClick={() => history.push('/select')} expand="block" color="warning">Vissza a
                    képernyőválasztáshoz</IonButton>
                <IonButton onClick={() => history.push(screen + '/home')} expand="block" color="success">Tovább a
                    választott
                    képernyő főoldalára</IonButton>
            </div>
        }

        if (isCheckingForUpdates) {
            return <div>Szinkronizálás...<IonSpinner name="crescent"/></div>;
        }

        if (isDownloading) {
            return <div>Letöltés folyamatban...<IonSpinner name="crescent"/></div>;
        }

        if (hasUpdate) {
            return (
                <div>
                    <div>A felhasznált médiákhoz frissítés érhető el. Szeretné ezeket letölteni?</div>
                    <IonButton onClick={onDownloadClick}>
                        Letöltés
                    </IonButton>
                    <IonButton onClick={() => history.push(screen + '/home')} color="danger">
                        Kihagyás
                    </IonButton>
                </div>
            )
        }

        return files?.map(file => {
            if (file.devicePath.endsWith('.png') || file.devicePath.endsWith('.jpg')) {
                return <img src={Capacitor.convertFileSrc(file.devicePath)} alt=""/>;
            }
            return null;
        });
    }

    return (
        <IonPage>
            <IonContent>
                <Center>
                    {getPageContent()}
                </Center>
            </IonContent>
        </IonPage>
    );
};

export default Sync;
