import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    selectedScreen: false,
    isSynced: false,
    isAborted: false,
}

export const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {
        setSelectedScreen: (state, action) => {
            state.selectedScreen = action.payload
        },
        setIsSynced: (state, action) => {
            state.isSynced = action.payload
        },
        setIsAborted: (state, action) => {
            state.isAborted = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {setSelectedScreen, setIsSynced, setIsAborted} = screenSlice.actions

export default screenSlice.reducer
