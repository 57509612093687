import React from 'react';
import classes from './Center.module.scss';

const Center = (props) => {
    return (
        <div className={classes['center']}>
            {props.children}
        </div>
    );
};

export default Center;
