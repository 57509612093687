import axios from "axios";
import {API_FULL_PATH, API_TOKEN} from "./utils/constants";

const instance = axios.create({
    baseURL: API_FULL_PATH,
    headers: {
        'X-Authorization': API_TOKEN,
    }
});

export default instance;
