import React from 'react';
import BgImage from "../../../hoc/BgImage/BgImage";
import Padded from "../../../hoc/Padded/Padded";
import Valign from "../../../hoc/Valign/Valign";
import Row from "../../../hoc/Row/Row";
import Button from "../../../components/Button/Button";
import UltraBold from "../../../hoc/UltraBold/UltraBold";
import {useHistory} from "react-router";

const Home = () => {
    const history = useHistory();

    return (
        <BgImage fullHeight={true} imagePath='/assets/main-bg.png'>
            <BgImage position={'39vw -27vh'} size={'75vw auto'} fullHeight={true} imagePath='/assets/sello-white.svg'>
                <Padded fullHeight={true}>
                    <Valign align={'space-between'}>
                        <img style={{width: '35vw'}} alt='Műhely. Titkok.' src={'/assets/logo-muhelytitkok.svg'}/>
                        <Row justify={'end'} align={'end'} width={'35vw 18vw'} gap={'9vw'}>
                            <Row justify={'space-between'} align={'end'} width={'15.5vw 15.5vw'} gap={'4vw'}>
                                <Button fullHeight={true} type={'primary'}
                                        action={() => history.push('/website')}>
                                    <Padded top={'2.5vw'} bottom={'2.5vw'} left={0} right={0}>
                                        <img src={'/assets/icon-internet.svg'}
                                             style={{width: '6.25vw', marginBottom: '1rem'}} alt=""/>
                                        <UltraBold>
                                            <div className={'fz-1'}>opera.hu</div>
                                        </UltraBold>
                                    </Padded>
                                </Button>
                                <Button fullHeight={true} type={'secondary'}
                                        action={() => history.push('/game')}>
                                    <Padded top={'2.5vw'} bottom={'2.5vw'} left={0} right={0}>
                                        <img src={'/assets/icon-touch.svg'}
                                             style={{width: '6.25vw', marginBottom: '1rem'}} alt=""/>
                                        <UltraBold>
                                            <div className={'fz-1'}>játék</div>
                                        </UltraBold>
                                    </Padded>
                                </Button>
                            </Row>
                            <img src={'/assets/double-logo.svg'} alt=""/>
                        </Row>
                    </Valign>
                </Padded>
            </BgImage>
        </BgImage>
    );
};

export default Home;
