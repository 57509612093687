import {File} from "@ionic-native/file";

export const API_FULL_PATH = 'https://test.operahaz-api.jmdemo.hu';
export const API_TOKEN = 'Uk65efUuDzbem0rh44JwJuuKy9GSfl7qwzmcM0C5';
export const DEVICE_STORAGE_PATH = File.dataDirectory;
export const FILESTORAGE = 'files';
export const CONTENTSTORAGE = 'content';
export const LEFT_PANEL_WIDTH_BIG = '28%';
export const LEFT_PANEL_WIDTH_MEDIUM = '32%';
export const RIGHT_PANEL_WIDTH_BIG = '72%';
export const RIGHT_PANEL_WIDTH_MEDIUM = '68%';
export const DEFAULT_PADDING = '9.25vh';
export const SCREENSIZES = {
    LARGE1: 'screen1-large',
    LARGE2: 'screen2-large',
    LARGE3: 'screen3-large',
    MEDIUM1: 'screen4-medium',
    MEDIUM2: 'screen5-medium',
    MEDIUM3: 'screen6-medium',
    MEDIUM4: 'screen7-medium',
    MEDIUM5: 'screen8-medium'
};
export const MEDIUM_SCREENS = [SCREENSIZES.MEDIUM1, SCREENSIZES.MEDIUM2, SCREENSIZES.MEDIUM3, SCREENSIZES.MEDIUM4, SCREENSIZES.MEDIUM5];
export const LARGE_SCREENS = [SCREENSIZES.LARGE1, SCREENSIZES.LARGE2, SCREENSIZES.LARGE3];
export const SCREENSELECTOPTIONS = [
    {
        value: SCREENSIZES.LARGE1,
        displayName: 'Liszt - Schubert'
    },
    {
        value: SCREENSIZES.LARGE2,
        displayName: 'Giacomo'
    },
    {
        value: SCREENSIZES.LARGE3,
        displayName: 'Adolphe - Gioachino'
    },
    {
        value: SCREENSIZES.MEDIUM1,
        displayName: '1. szikla (Spannraft Ágoston - Kéméndy Jenő)'
    },
    {
        value: SCREENSIZES.MEDIUM2,
        displayName: '2. szikla (Bánffy Miklós - Márkus László)'
    },
    {
        value: SCREENSIZES.MEDIUM3,
        displayName: '3. szikla (Oláh Gusztáv - Márk Tivadar)'
    },
    {
        value: SCREENSIZES.MEDIUM4,
        displayName: '4. szikla (Fülöp Zoltán - Forray Gábor - Schaffer Judit)'
    },
    {
        value: SCREENSIZES.MEDIUM5,
        displayName: '5. szikla (Makai Péter - Csikós Attila - Vágó Nelly)'
    }
];
