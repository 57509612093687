import React from 'react';
import classes from './Button.module.scss';

const Button = ({type, action, fullHeight, children}) => {
    const blockHeight = fullHeight ? '100%' : 'auto'

    return (
        <button style={{height: blockHeight}}
                className={[classes['button'], classes['button--' + type]].join(' ')}
                onClick={action}>
            {children}
        </button>
    );
};

export default Button;
