import {createSlice} from '@reduxjs/toolkit'
import {defaultContent} from "../static/defaultContent";

const initialState = {
    content: [],
    defaultContent: defaultContent
}

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        setContent: (state, action) => {
            state.content = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {setContent} = contentSlice.actions

export default contentSlice.reducer
