import React from 'react';
import BgImage from "../../hoc/BgImage/BgImage";
import Padded from "../../hoc/Padded/Padded";
import Valign from "../../hoc/Valign/Valign";
import WithBackButton from "../../hoc/WithBackButton/WithBackButton";

const DefaultScreen = ({children}) => {
    return (
        <BgImage fullHeight={true} imagePath='/assets/main-bg-gallery.jpg'>
            <BgImage position={'-5vw -18vh'} size={'67vw auto'} fullHeight={true} imagePath='/assets/sello-white.svg'>
                <Padded fullHeight={true}>
                    <WithBackButton>
                        <Valign align={'center'}>
                            {children}
                        </Valign>
                    </WithBackButton>
                </Padded>
            </BgImage>
        </BgImage>
    );
};

export default DefaultScreen;
