import {Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet} from '@ionic/react';
import { AndroidFullScreen } from '@ionic-native/android-full-screen';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css';
import ScreenSelect from "./pages/ScreenSelect/ScreenSelect";
import {useDispatch, useSelector} from "react-redux";
import Sync from "./pages/Sync/Sync";
import {withRouter} from "react-router";
import {useEffect} from "react";
import {getStorage} from "./store/idb/storage";
import {FILESTORAGE} from "./utils/constants";
import {setFiles} from "./store/slices/files";

import LargeHome from "./pages/screen1/Home/Home";
import Game from "./pages/Game/Game";
import Website from "./pages/Website/Website";

import MediumHome from "./pages/Home/Home";
import Select from "./pages/Select/Select";
import People from "./pages/People/People";
import Gallery from "./pages/Gallery/Gallery";

const App = () => {
    const dispatch = useDispatch();
    const selectedScreen = useSelector(store => store.screen.selectedScreen);

    useEffect(() => {
        AndroidFullScreen.immersiveMode(() =>{}, () => {});

        getStorage(FILESTORAGE).then(files => {
            dispatch(setFiles(files));
        });
    }, [])

    if (!selectedScreen) {
        return <ScreenSelect/>;
    }

    return (
        <IonApp>
            <IonRouterOutlet>
                <Route exact path="/select">
                    <ScreenSelect/>
                </Route>
                <Route exact path="/sync">
                    <Sync/>
                </Route>
                <Route exact path={'/home'} component={LargeHome}/>
                <Route exact path={'/website'} component={Website}/>
                <Route exact path={'/game'} component={Game}/>
                <Route exact path={'/medium-home'} component={MediumHome}/>
                <Route exact path={'/people'} component={People}/>
                <Route exact path={'/sub-select'} component={Select}/>
                <Route exact path={'/gallery'} component={Gallery}/>
                <Route exact path={'/video'} component={Gallery}/>
            </IonRouterOutlet>
        </IonApp>
    );
}

export default withRouter(App);
