import axios from "../../axios";
import {API_FULL_PATH, DEVICE_STORAGE_PATH, FILESTORAGE} from "../constants";
import {getStorage} from "../../store/idb/storage";
import {HTTP} from "@ionic-native/http";

export const getAssetsForScreen = (screen) => {
    return axios.get('', {
        'params': {
            'screen': screen
        }
    });
}

export const downloadFiles = (files) => {
    const fileURL = DEVICE_STORAGE_PATH;
    let filePromises = [];

    files.forEach(file => {
        const uri = encodeURI(API_FULL_PATH + '/' + file.apiPath);
        const promise = new Promise((resolve, reject) => {
            HTTP.downloadFile(
                uri,
                {},
                {},
                fileURL + '/' + file.name
            ).then(res => {
                file['devicePath'] = fileURL + '/' + file.name;
                return resolve(res)
            }).catch(err => reject(err));
        });

        filePromises.push(promise);
    });

    return filePromises;
}

/*export const getPermission = () => {
    var permissions = cordova.plugins.permissions;

    permissions.hasPermission(permissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
        .then(status => {
            if (status.hasPermission) {
                downloadFile();
            } else {
                permissions.requestPermission(permissions.PERMISSION.WRITE_EXTERNAL_STORAGE)
                    .then(status => {
                        if (status.hasPermission) {
                            downloadFile();
                        }
                    });
            }
        });
}*/

export const downloadNewFiles = async (config, success, error) => {
    const newFiles = await getNewFilesFromConfig(config);

    const promises = downloadFiles(newFiles);

    await Promise.all(promises).then(res => {
        console.log('ALL', JSON.stringify(res));
        console.log('DONE', JSON.stringify(newFiles));
        success(newFiles);
    }).catch(err => {
        console.log(err);
        error();
    });
}

export const getNewFilesFromConfig = async (config) => {
    let newFiles = [];
    const storedFiles = await getStorage(FILESTORAGE);

    Object.keys(config).forEach((key) => {
        const files = config[key]?.files;

        if (files && files.length > 0) {
            files.forEach(file => {
                if (!storedFiles || !storedFiles.find(sf => sf.name === file.name && sf.size === file.size)) {
                    newFiles.push({
                        'name': file.name,
                        'size': file.size,
                        'apiPath': config[key].basePath + file.name
                    });
                }
            });
        }
    });

    return newFiles;
}
