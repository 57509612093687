import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import DefaultScreen from "../../components/DefaultScreen/DefaultScreen";
import Carousel from "../../components/Carousel/Carousel";
import Video from "../../components/Video/Video";
import Image from "../../components/Image/Image";
import classes from './Gallery.module.scss';

const Gallery = (props) => {
    const files = useSelector(store => store.files.files ? store.files.files : store.files.defaultFiles);
    const person = useSelector(store => store.person.selectedPerson);
    const content = useSelector(store => {
        if (store.content.content.length > 0) {
            return store.content.content
        }
        return store.content.defaultContent;
    });

    const [filteredContent, setFilteredContent] = useState();
    const [activeSlide, updateActiveSlide] = useState(0);
    const galleryItemComponent = props.location.pathname.includes('gallery') ? 'image' : 'video';

    useEffect(() => {
        const filterForImages = props.location.pathname.includes('gallery');
        const targetContentType = filterForImages ? 'image' : 'video';
        setFilteredContent(Object.keys(content).filter(c => {
            if (((content[c].personId && +content[c].personId === +person.id) || content[c].personIds && content[c].personIds.includes(person.id)) && content[c].type === targetContentType) {
                return c;
            }
        }))
    }, []);

    useEffect(() => {
        if (filteredContent) {
            updateActiveSlide(filteredContent[0]);
        }
    }, [filteredContent])

    const getComponent = (currentContent) => {
        const file = files.find(f => f.name === currentContent && filteredContent.includes(f.name));
        if (galleryItemComponent === 'image') {
            return <Image file={file}/>;
        }

        return <Video isActive={activeSlide === currentContent} file={file}/>
    }

    const onCarouselChange = (slideIndex) => {
        updateActiveSlide(filteredContent[slideIndex])
    }

    if (!filteredContent || filteredContent.length < 1) {
        return null;
    }

    return (
        <DefaultScreen>
            <Carousel onChange={onCarouselChange} showArrows={filteredContent.length > 1} swipeable={galleryItemComponent === 'image'}>
                {filteredContent.map((c, i) => (
                    <div className={classes['text']} key={i}>
                        {getComponent(c)}
                        <p>{content[c].description}</p>
                        <h3>{content[c].title}</h3>
                    </div>))}
            </Carousel>
        </DefaultScreen>
    );
};

export default Gallery;
