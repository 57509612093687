import React from 'react';
import BgImage from "../../hoc/BgImage/BgImage";
import Padded from "../../hoc/Padded/Padded";
import Valign from "../../hoc/Valign/Valign";
import Row from "../../hoc/Row/Row";
import Button from "../../components/Button/Button";
import UltraBold from "../../hoc/UltraBold/UltraBold";
import {useHistory} from "react-router";

const Home = () => {
    const history = useHistory();

    return (
        <BgImage fullHeight={true} imagePath='/assets/main-bg.png'>
            <BgImage position={'42vw -20vh'} size={'63vw auto'} fullHeight={true} imagePath='/assets/sello-white.svg'>
                <Padded fullHeight={true}>
                    <Valign align={'space-between'}>
                        <img style={{width: '35vw'}} alt='Műhely. Titkok.' src={'/assets/logo-muhelytitkok.svg'}/>
                        <Row justify={'space-between'} align={'end'} width={'18vw 14vw 18vw'}>
                            <div/>
                            <Button fullHeight={true} type={'primary'}
                                    action={() => history.push('/people')}>
                                <Padded top={'1.75vw'} bottom={'1.75vw'} left={0} right={0}>
                                    <img src={'/assets/icon-touch.svg'}
                                         style={{width: '5vw'}} alt=""/>
                                    <UltraBold>
                                        <div className={'fz-1'}>start</div>
                                    </UltraBold>
                                    <strong className={'fz-5 font-alt d-block'}>Ismerje meg az Operaház
                                        díszlettervezőit!
                                    </strong>
                                </Padded>
                            </Button>
                            <img src={'/assets/double-logo.svg'} alt=""/>
                        </Row>
                    </Valign>
                </Padded>
            </BgImage>
        </BgImage>
    );
};

export default Home;
