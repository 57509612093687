import React from 'react';
import Row from "../../hoc/Row/Row";
import {LEFT_PANEL_WIDTH_BIG, RIGHT_PANEL_WIDTH_BIG} from "../../utils/constants";
import BgImage from "../../hoc/BgImage/BgImage";
import Padded from "../../hoc/Padded/Padded";
import WithBackButton from "../../hoc/WithBackButton/WithBackButton";
import classes from './Website.module.scss';

const Website = () => {
    const iframeScale = window.innerWidth > 1300 ? (window.innerWidth * parseInt(RIGHT_PANEL_WIDTH_BIG) / 100) / 1300 : 1;
    const iframeHeight = 100 / iframeScale;

    return (
        <Row width={[LEFT_PANEL_WIDTH_BIG, RIGHT_PANEL_WIDTH_BIG].join(' ')} align={'start'} justify={'start'}>
            <div style={{backgroundColor: '#74B943', height: '100%'}}>
                <BgImage position={'left 30vh'} size={'35vw auto'} imagePath={'/assets/sello-white.svg'}
                         fullHeight={true}>
                    <Padded fullHeight={true}>
                        <div>
                            <WithBackButton hasLabel={true}>
                                <img style={{width: '70%', marginBottom: '1rem'}} alt='Műhely. Titkok.'
                                     src={'/assets/logo-muhelytitkok.svg'}/>
                                <img style={{width: '80%', marginLeft: 'auto', display: 'block'}}
                                     src={'/assets/double-logo.svg'} alt=""/>
                            </WithBackButton>
                        </div>
                    </Padded>
                </BgImage>
            </div>
            <div style={{height: '100vh'}}>
                <iframe className={classes.website} style={{transform: `scale(${iframeScale})`}}
                        src="https://www.opera.hu/" height={iframeHeight + '%'} width='100%'
                        frameBorder="0"></iframe>
            </div>
        </Row>
    );
};

export default Website;
