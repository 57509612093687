import React from 'react';
import {LEFT_PANEL_WIDTH_MEDIUM, RIGHT_PANEL_WIDTH_MEDIUM} from "../../utils/constants";
import Row from "../../hoc/Row/Row";
import MediumSidePanel from "../../components/MediumSidePanel/MediumSidePanel";
import BgImage from "../../hoc/BgImage/BgImage";
import Valign from "../../hoc/Valign/Valign";
import SubMenuButtons from "../../components/SubMenuButtons/SubMenuButtons";
import {useSelector} from "react-redux";
import {personSubMenus} from "../../store/static/personSubmenu";

const Select = () => {
    const selectedPerson = useSelector(store => store.person.selectedPerson);
    const buttons = personSubMenus.filter(item => item.personIds.includes(selectedPerson.id));

    return (
        <BgImage fullHeight={true} imagePath='/assets/main-medium-bg.png'>
            <Row width={[LEFT_PANEL_WIDTH_MEDIUM, RIGHT_PANEL_WIDTH_MEDIUM].join(' ')} align={'start'}
                 justify={'start'}>
                <MediumSidePanel imagePath={selectedPerson.imagePath} bgColor={'unset'}>
                    <div className={'fz-1 color-white upper'}>
                        <strong className={'fz-1'}>{selectedPerson.fname}</strong> {selectedPerson.lname}
                    </div>
                </MediumSidePanel>
                <div style={{height: '100vh'}}>
                    <Valign align={'center'}>
                        <strong style={{marginBottom: '3.5rem'}} className={'fz-1 font-alt color-white text-center'}>
                            VÁLASSZON AZ ALÁBBI LEHETŐSÉGEK KÖZÜL!
                        </strong>
                        <SubMenuButtons buttons={buttons}/>
                    </Valign>
                </div>
            </Row>
        </BgImage>
    );
};

export default Select;
