import React from 'react';
import classes from './Popup.module.scss';

const Popup = ({position, title, children}) => (
    <div className={classes['popup']}
         style={{left: position.x, top: position.y}}>
        {title}
        {children}
    </div>
);

export default Popup;
